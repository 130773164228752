/* eslint-disable no-case-declarations */
import { GET_DOCUMENTS, SET_LOADING, UPDATE_DOCUMENT } from '../constants/documentConstants';
import { updateSignedDocument } from '../helpers/documentsHelper';

export const initialState = {
  documents: [],
  loading: false,
};

const documentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        loading: false,
      };
    case UPDATE_DOCUMENT:
      return {
        ...state,
        documents: [...updateSignedDocument(state.documents, action.payload)],
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default documentReducer;
