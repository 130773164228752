import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { routes } from 'domains/shared/constants/routes';
import { OldAuthorizeZoom } from 'domains/shared/containers/OldAuthorizeZoom';
import { checkIsClientDomain } from 'domains/shared/helpers/checkIsClientDomain';
import { setupI18n } from 'domains/shared/i18n';
import { makeStore } from 'domains/shared/makeStore';
import sharedTranslations from 'domains/shared/translations/en.json';
import { EventPlatformVariant } from 'domains/shared/types/eventPlatformVariant';
import { TranslationNamespace } from 'domains/shared/types/translationNamespace';
import { getFeatureFlags } from 'domains/shared/utils/featureFlags';
import translations from 'translations/en.json';

import './logrocketSetup';

const { store, persistor } = makeStore();
const App = React.lazy(() => import('./App'));
const SelfServeFlowLandingPage = React.lazy(() => import('./domains/kudoLite/SelfServeFlowLandingPage'));
const ReplayWidgetLandingPage = React.lazy(() => import('./domains/replay/ReplayWidgetLandingPage'));

setupI18n({ en: { [TranslationNamespace.LEGACY]: translations, [TranslationNamespace.SHARED]: sharedTranslations } });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Route exact path={routes.authorizeZoom} component={OldAuthorizeZoom} />
            {checkIsClientDomain() ? (
              <Route path={routes.selfServeFlowLandingPage(EventPlatformVariant.ZOOM_MEETING)}>
                <Suspense fallback={null}>
                  <SelfServeFlowLandingPage variant={EventPlatformVariant.ZOOM_MEETING} />
                </Suspense>
              </Route>
            ) : null}
            {checkIsClientDomain() ? (
              <Route path={routes.selfServeFlowLandingPage(EventPlatformVariant.ZOOM_WEBINAR)}>
                <Suspense fallback={null}>
                  <SelfServeFlowLandingPage variant={EventPlatformVariant.ZOOM_WEBINAR} />
                </Suspense>
              </Route>
            ) : null}
            {getFeatureFlags().withReplay && checkIsClientDomain() ? (
              <Route path={routes.replayWidget}>
                <Suspense fallback={null}>
                  <ReplayWidgetLandingPage />
                </Suspense>
              </Route>
            ) : null}
            <Route>
              <Suspense fallback={null}>
                <App />
              </Suspense>
            </Route>
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
