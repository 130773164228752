import * as constants from '../constants/navigationConstants';

export const initialState = {
  tab: '',
  url: '',
};

const navigationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.SET_NAVIGATION:
      return {
        ...state,
        tab: action.payload,
      };
    case constants.SET_ACTIVE_LINK:
      return {
        ...state,
        url: action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
