import moment from 'moment';

type GetAllDaysInTheWeek = (newCurrentDate: Date) => Date[];

/**
 * returns an array of all days in week
 */
export const getAllDaysInTheWeek: GetAllDaysInTheWeek = (newCurrentDate) => {
  const startOfWeek = moment(newCurrentDate).startOf('isoWeek');
  const endOfWeek = moment(newCurrentDate).endOf('isoWeek');
  const days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, 'd');
  }

  return days;
};
