export const SELF_SERVE_BOOKING_FLOW = 'Eg0rVovzDr3UnNoEv1E';
export const SELF_SERVICE_BOOKING_DATA = 'XVSwCes8mSR7EHD';
export const SELF_SERVICE_ORDER_ID = 'QshFRrJpLx8n';
export const SELF_SERVICE_MEETING_ID = '2N1GDkoV1YchB1i1flJp';
export const SELF_SERVICE_MEETING_DETAILS = '6VBRaFu7v';
export const SELF_SERVICE_TIMESTAMP = 'I1PmR6SS';
export const KSS_AUTH_CODE = 'Dpr8Mwhx4xTYG';
export const SELF_SERVICE_FORM_STEP = 'K7XydTOr';
export const SELF_SERVICE_BOOKING_DATA_TIMEZONES = `${SELF_SERVICE_BOOKING_DATA}_TZ`;
export const SELF_SERVE_NO_PAYMENT_REQUIRED = 'xfE3tpiremfwkeeg4';
