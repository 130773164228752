import moment from 'moment';

import {
  DATE_STARTING_YEAR,
  DATE_TIME_STARTING_YEAR_24_HOURS,
  TIME_24H,
} from 'domains/shared/constants/dateTimeFormats';
import { getAllDaysInTheWeek } from 'domains/shared/helpers/getAllDaysInTheWeek';

type GetNewBookedSlots = (params: {
  newSlot: Array<{
    start_time: string;
    end_time: string;
    day?: string;
  }>;
  oldSlot: Array<{
    start_time: string;
    end_time: string;
    day?: string;
  }>;
  hasStatus: boolean;
  currentDate: Date;
}) => Array<{
  start_time: string;
  end_time: string;
  day?: string;
}>;

/**
 * compares old and new slots to find out new slots
 */
export const getNewBookedSlots: GetNewBookedSlots = ({ newSlot, oldSlot, hasStatus, currentDate }) => {
  const oldSlots = JSON.parse(JSON.stringify(oldSlot)) as typeof oldSlot;
  const newSlots = JSON.parse(JSON.stringify(newSlot)) as typeof newSlot;
  const weekDays: Record<number, string> = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };
  const weekDays1: Record<number, string> = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  };

  if (hasStatus) {
    const weekDates = getAllDaysInTheWeek(currentDate);

    oldSlots.forEach((slot) => {
      const weekDay = parseInt(
        `${Object.keys(weekDays1).find((key) => weekDays1[parseInt(key, 10)] === slot.start_time.split(' ')[0])}`,
        10
      );

      if (!weekDay || isNaN(weekDay)) {
        return;
      }

      slot.start_time = `${moment(weekDates[weekDay]).format(DATE_STARTING_YEAR)} ${slot.start_time.split(' ')[1]}`;
    });
    newSlots.forEach((slot) => {
      slot.start_time = `${weekDays[moment(slot.start_time).day()]} ${moment(slot.start_time).format(TIME_24H)}`;
    });
  } else {
    // genericslots
    const weekDates = getAllDaysInTheWeek(currentDate);

    oldSlots.forEach((slot) => {
      const weekDay = parseInt(
        `${Object.keys(weekDays1).find((key) => weekDays1[parseInt(key, 10)] === slot.day)}`,
        10
      );

      if (!weekDay || isNaN(weekDay)) {
        return;
      }

      slot.start_time = `${moment(weekDates[weekDay]).format(DATE_STARTING_YEAR)} ${moment(slot.start_time).format(
        TIME_24H
      )}`;
      slot.end_time = `${moment(weekDates[weekDay]).format(DATE_STARTING_YEAR)} ${moment(slot.end_time).format(
        TIME_24H
      )}`;
    });
  }

  const results = newSlots.filter(({ start_time: id1 }) => !oldSlots.some(({ start_time: id2 }) => id2 === id1));

  if (hasStatus) {
    const weekDates = getAllDaysInTheWeek(currentDate);

    results.forEach((result) => {
      const weekDay = parseInt(
        `${Object.keys(weekDays1).find((key) => weekDays1[parseInt(key, 10)] === result.start_time.split(' ')[0])}`,
        10
      );

      if (!weekDay || isNaN(weekDay)) {
        return;
      }

      result.start_time = `${moment(weekDates[weekDay]).format(DATE_STARTING_YEAR)} ${result.start_time.split(' ')[1]}`;
    });

    results.forEach((result) => {
      result.start_time = moment(result.start_time).utc().format(DATE_TIME_STARTING_YEAR_24_HOURS);
      result.end_time = moment(result.end_time).utc().format(DATE_TIME_STARTING_YEAR_24_HOURS);
    });
  }

  return results;
};
