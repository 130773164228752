import * as constants from '../constants/EarningConstants';

export const initialState = {
  transactions: [],
  balance: { amount: 0 },
  loading: false,
  pagination: {},
};

const earningReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: action.payload.data.results,
        pagination: action.payload.data.page_info,
        loading: action.payload.loading,
      };
    case constants.GET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };
    case constants.WITHDRAW_BALANCE:
      return {
        ...state,
        balance: {
          amount: state.balance.amount - action.payload,
          can_withdraw: false,
        },
      };
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default earningReducer;
