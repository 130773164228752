import { AGNOSTIC_LANGUAGES } from 'domains/shared/constants/agnosticLanguages';

export const getLanguagesHash = (): Record<string, string> =>
  AGNOSTIC_LANGUAGES.reduce(
    (previousObject, currentObject) =>
      Object.assign(previousObject, {
        [currentObject.zoom_code]: currentObject.name,
      }),
    {}
  );
