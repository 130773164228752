/* eslint-disable no-case-declarations */
import produce from 'immer';
import { findIndex } from 'lodash-es';

import * as constants from '../constants/shiftPlanningConstants';
import { initialValues } from '../fixtures/shiftPlans';
import { addBulkShiftInterpreters, removeInterpreter } from '../helpers/ShiftPlanningHelper';

export const initialState = {
  timezone: [],
  initialValues,
  languageCode: [],
  shiftList: [],
  completedShiftList: [],
  newShiftInterpreter: {},
  onCallShiftPlan: {},
  onCallShiftPlanFee: {},
  shiftPlanRate: { earnings: 0 },
  loading: false,
  pagination: {},
};

const shiftPlanReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.CREATE_SHIFT_PLANS:
      return {
        ...state,
      };
    case constants.UPDATE_SHIFT_PLANS:
      return {
        ...state,
        initialValues: action.payload,
      };
    case constants.GET_TIME_ZONE:
      return {
        ...state,
        timezone: action.payload,
      };
    case constants.GET_FORM_DATA:
      return {
        ...state,
        initialValues: action.payload,
        loading: false,
      };
    case constants.GET_LANGUAGE_CODES:
      return {
        ...state,
        languageCode: action.payload,
      };
    case constants.CLEAR_FORM_DATA:
      return {
        ...state,
        initialValues,
      };
    case constants.GET_LANGUAGES_DETAILS:
      return {
        ...state,
        loading: false,
        initialValues: {
          ...state.initialValues,
          shift_languages: action.payload,
        },
      };
    case constants.GET_SHIFT_LIST:
      return {
        ...state,
        shiftList: action.payload.results,
        pagination: action.payload.page_info,
        loading: false,
      };
    case constants.GET_SHIFT_LIST_COUNT:
      return {
        ...state,
        shiftList: [],
        completedShiftList: [],
      };
    case constants.GET_COMPLETED_SHIFT_LIST:
      return {
        ...state,
        completedShiftList: action.payload.results,
        pagination: action.payload.page_info,
        loading: false,
      };
    case constants.REMOVE_SHIFT_INTERPRETER:
      return {
        ...state,
        initialValues: removeInterpreter(state.initialValues, action.payload),
      };
    case constants.NEW_SHIFT_INTERPRETER:
      const { created, removed, languageIndex, shiftDetailIndex } = action.payload;
      const newShiftInterpreters =
        state.initialValues.shift_languages[languageIndex].shift_details[shiftDetailIndex].shift_interpreters;
      let index = 0;
      let updatedValues;

      if (removed) {
        index = findIndex(newShiftInterpreters, { id: removed.id });
        updatedValues = produce(state.initialValues, (draft) => {
          draft.shift_languages[languageIndex].shift_details[shiftDetailIndex].shift_interpreters.splice(
            index,
            1,
            created
          );
        });
      } else {
        updatedValues = produce(state.initialValues, (draft) => {
          draft.shift_languages[languageIndex].shift_details[shiftDetailIndex].shift_interpreters.push(created);
        });
      }

      return {
        ...state,
        initialValues: updatedValues,
      };
    case constants.ADD_BULK_SHIFT_INTERPRETERS:
      return {
        ...state,
        initialValues: addBulkShiftInterpreters(action.payload, state.initialValues),
      };
    case constants.GET_ON_CALL_SHIFT_PLAN:
      return {
        ...state,
        onCallShiftPlan: {},
      };
    case constants.GET_ON_CALL_SHIFT_PLAN_SUCCESS:
      return {
        ...state,
        onCallShiftPlan: action.payload,
      };
    case constants.GET_ON_CALL_SHIFT_PLAN_FEE:
      return {
        ...state,
        onCallShiftPlanFee: {},
      };
    case constants.GET_ON_CALL_SHIFT_PLAN_FEE_SUCCESS:
      return {
        ...state,
        onCallShiftPlanFee: action.payload,
      };
    case constants.GET_ON_CALL_SHIFT_PLAN_FAILURE:
      return {
        ...state,
        onCallShiftPlan: {},
      };
    case constants.GET_SHIFT_PLAN_RATE:
      return {
        ...state,
        shiftPlanRate: { earnings: 0 },
      };
    case constants.GET_SHIFT_PLAN_RATE_SUCCESS:
      return {
        ...state,
        shiftPlanRate: action.payload,
      };
    case constants.GET_SHIFT_PLAN_RATE_FAILURE:
      return {
        ...state,
        shiftPlanRate: { earnings: 0 },
      };
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case constants.REMOVE_SHIFT_PLAN:
      return {
        ...state,
        shiftList: state.shiftList.filter((shiftPlan) => shiftPlan.id !== action.payload.id),
        completedShiftList: state.completedShiftList.filter((shiftPlan) => shiftPlan.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default shiftPlanReducer;
