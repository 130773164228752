import * as constants from '../constants/reviewerConstants';

export const initialState = {
  allReviewers: [],
  reviewerProfile: {},
};

const reviewerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_ALL_REVIEWERS:
      return {
        ...state,
        allReviewers: [],
      };
    case constants.GET_ALL_REVIEWERS_SUCCESS:
      return {
        ...state,
        allReviewers: action.payload,
      };
    case constants.GET_ALL_REVIEWERS_ERROR:
      return {
        ...state,
        allReviewers: [],
      };
    case constants.GET_REVIEWER:
      return {
        ...state,
        reviewerProfile: {},
      };
    case constants.GET_REVIEWER_SUCCESS:
      return {
        ...state,
        reviewerProfile: action.payload,
      };
    case constants.GET_REVIEWER_ERROR:
      return {
        ...state,
        reviewerProfile: {},
      };
    default:
      return state;
  }
};

export default reviewerReducer;
