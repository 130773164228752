export enum NotificationVariant {
  ON_CALL_AVAILABILITY_REQUEST = 'on_call_availability_request',
  NEW_MEETING_REQUEST = 'new_meeting_request',
  INTERPRETER_ADDED_FAST_TRACK = 'interpreter_added_fast_track',
  INTERPRETER_ACCEPTED_SLOW_TRACK = 'interpreter_accepted_slow_track',
  MEETING_REMINDER_NOTIFICATION = 'meeting_reminder_notification',
  INTERPRETER_PENDING_REVIEW = 'interpreter_pending_review',
  REVIEWER_OFFERS_DECLINED = 'reviewer_offers_declined',
  REVIEWER_BOOKING_CONFIRMED = 'reviewer_booking_confirmed',
  REVIEW_INTERPRETERS_REMINDER = 'review_interpreters_reminder',
  REVIEWER_INTERPRETERS_NOT_FOUND = 'reviewer_interpreters_not_found',
  REVIEW_INTERPRETER_REPORTED_BOOKING_EMERGENCY = 'review_interpreter_reported_booking_emergency',
  REVIEW_INTERPRETER_REPORTED_SHIFT_PLAN_EMERGENCY = 'review_interpreter_reported_shift_plan_emergency',
  REVIEW_INTERPRETER_REPORTED_BOOKING_ISSUE = 'review_interpreter_reported_booking_issue',
  REVIEW_INTERPRETER_REPORTED_SHIFT_PLAN_ISSUE = 'review_interpreter_reported_shift_plan_issue',
  REVIEWER_COMPLETED_INTERPRETER_REPORTED_BOOKING_EMERGENCY = 'reviewer_completed_interpreter_reported_booking_emergency',
  REVIEWER_COMPLETED_INTERPRETER_REPORTED_SHIFT_PLAN_EMERGENCY = 'reviewer_completed_interpreter_reported_shift_plan_emergency',
  REVIEWER_COMPLETED_INTERPRETER_REPORTED_BOOKING_ISSUE = 'reviewer_completed_interpreter_reported_booking_issue',
  REVIEWER_COMPLETED_INTERPRETER_REPORTED_SHIFT_PLAN_ISSUE = 'reviewer_completed_interpreter_reported_shift_plan_issue',
  REVIEW_BOOKING_CANCELLED = 'review_booking_cancelled',
  INTERPRETER_BOOKING_CANCELLED = 'interpreter_booking_cancelled',
  REVIEW_INTERPRETER_OVERTIME_CLAIM = 'review_interpreter_overtime_claim',
  REVIEWER_DECLINED_INTERPRETER_OVERTIME_CLAIM = 'reviewer_declined_interpreter_overtime_claim',
  REVIEWER_APPROVED_INTERPRETER_OVERTIME_CLAIM = 'reviewer_approved_interpreter_overtime_claim',
  REVIEWER_NEW_ASSIGNED_BOOKING = 'reviewer_new_assigned_booking',
  REVIEWER_SHIFT_PLAN_OFFERS_ACCEPTED = 'reviewer_shift_plan_offers_accepted',
  REVIEWER_SHIFT_PLAN_OFFERS_DECLINED = 'reviewer_shift_plan_offers_declined',
  REVIEWER_SHIFT_PLAN_COMPLETED = 'reviewer_shift_plan_completed',
  REVIEWER_CLEARED_INTERPRETER_EARNINGS = 'reviewer_cleared_interpreter_earnings',
  REVIEWER_NEW_MEETING_CREATED = 'reviewer_new_meeting_created',
  BOOKING_INTERPRETER_NOT_RESPONDED = 'booking_interpreter_not_responded',
  SHIFT_INTERPRETER_NOT_RESPONDED = 'shift_interpreter_not_responded',
  NON_ON_CALL_FAST_TRACK_OFFER_SENT = 'non_on_call_fast_track_offer_sent',
  INTERPRETER_ACCEPTED_FAST_TRACK = 'interpreter_accepted_fast_track',
  INTERPRETER_UNRESPONSIVE_FAST_TRACK = 'interpreter_unresponsive_fast_track',
  FINAL_INTERPRETER_UNRESPONSIVE_FAST_TRACK = 'final_interpreter_unresponsive_fast_track',
  INTERPRETER_REMOVED_FROM_MEETING = 'interpreter_removed_from_meeting',
  INTERPRETER_SHIFT_PLAN_REMOVED = 'interpreter_shift_plan_removed',
  INTERPRETER_PREPARATION_MATERIAL_ADDED = 'interpreter_preparation_material_added',
  INTERPRETER_WALLET_CREDIT_ADJUSTMENT = 'interpreter_wallet_credit_adjustment',
  INTERPRETER_WALLET_DEBIT_ADJUSTMENT = 'interpreter_wallet_debit_adjustment',
  CLIENT_NOTIFICATION_TO_MEET_YOUR_INTERPRETERS = 'client_notification_to_meet_your_interpreters',
  CLIENT_NOTIFICATION_TO_UPLOAD_PREPARATION_MATERIAL = 'client_notification_to_upload_preparation_material',
  CLIENT_NOTIFICATION_FOR_DELETED_ZOOM_WEBINAR = 'client_notification_for_deleted_zoom_meeting',
  CLIENT_NOTIFICATION_FOR_WEBINAR_NOT_EDITABLE = 'client_notification_for_webinar_not_editable',
  CLIENT_NOTIFICATION_TO_ADD_MEETING_LINK_STARTING_WITHIN_TWELVE_HOURS = 'client_notification_to_add_meeting_link_starting_within_twelve_hours',
}
