import { routes } from 'domains/shared/constants/routes';

export const generateLoginUrl = (): string => {
  // THINK
  const params = {
    clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
    redirectUri: `${process.env.REACT_APP_CLIENT_URL}${routes.postLogin}`,
  };
  const url = `${process.env.REACT_APP_SSO_BASE_URL}${routes.login}?${new URLSearchParams(params).toString()}`;

  return decodeURIComponent(url);
};
