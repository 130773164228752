import moment from 'moment';

import { NotificationVariant } from 'constants/NotificationVariant';
import { routes } from 'domains/shared/constants/routes';
import { checkHasProperty } from 'domains/shared/helpers/checkHasProperty';
import i18n from 'domains/shared/i18n';
import { Notification } from 'shared/types/notification';

export const calculateTimeDifference = (createTime: string): string => {
  if (moment(new Date()).diff(moment(createTime), 'days') > 1) {
    return `${moment(new Date()).diff(moment(createTime), 'days')} days ago`;
  }

  if (moment(new Date()).diff(moment(createTime), 'minutes') > 60) {
    return `${moment(new Date()).diff(moment(createTime), 'hours')} hours ago`;
  }

  return `${moment(new Date()).diff(moment(createTime), 'minutes')} mins ago`;
};

export const calculateTimeInHours = (time: number): string => {
  if (time > 1440) {
    return `${Math.floor(time / 1440)} days to accept.`;
  }

  if (time >= 60) {
    const mins = time % 60;

    return `${Math.floor(time / 60)} h ${mins > 0 ? ` and ${mins}min` : ''}  to accept.`;
  }

  return `${time}min to accept.`;
};

export const interpreterMeetingNotifications = [
  'new_meeting_request',
  'interpreter_added_fast_track',
  'interpreter_accepted_slow_track',
  'meeting_reminder_notification',
  'interpreter_pending_review',
  'interpreter_booking_cancelled',
  'interpreter_preparation_material_added',
];

export const interpreterEarningNotifications = [
  'reviewer_cleared_interpreter_earnings',
  'interpreter_wallet_credit_adjustment',
  'interpreter_wallet_debit_adjustment',
];

export const interpreterOvertimeAndEmergencyNotifications = [
  'reviewer_declined_interpreter_overtime_claim',
  'reviewer_approved_interpreter_overtime_claim',
  'reviewer_completed_interpreter_reported_booking_issue',
  'reviewer_completed_interpreter_reported_booking_emergency',
];

export const reviewerBookingNotifications = [
  'reviewer_offers_declined',
  'reviewer_booking_confirmed',
  'review_interpreters_reminder',
  'reviewer_interpreters_not_found',
  'review_booking_cancelled',
  'reviewer_new_assigned_booking',
  'reviewer_new_meeting_created',
  'booking_interpreter_not_responded',
  'interpreter_accepted_fast_track',
  'interpreter_unresponsive_fast_track',
  'final_interpreter_unresponsive_fast_track',
  'non_on_call_fast_track_offer_sent',
];

export const reviewerShiftPlanNotifications = [
  'reviewer_shift_plan_offers_accepted',
  'reviewer_shift_plan_offers_declined',
  'reviewer_shift_plan_completed',
  'shift_interpreter_not_responded',
];

export const reviewerOvertimeAndEmergencyNotifications = [
  'review_interpreter_reported_booking_emergency',
  'review_interpreter_reported_booking_issue',
  'review_interpreter_overtime_claim',
];

export const reviewerShiftPlanEmergenciesNotifications = [
  'review_interpreter_reported_shift_plan_emergency',
  'review_interpreter_reported_shift_plan_issue',
];

export const interpreterShiftPlanEmergenciesNotifications = [
  'reviewer_completed_interpreter_reported_shift_plan_emergency',
  'reviewer_completed_interpreter_reported_shift_plan_issue',
];

export const clientNotifications: string[] = [
  NotificationVariant.CLIENT_NOTIFICATION_TO_MEET_YOUR_INTERPRETERS,
  NotificationVariant.CLIENT_NOTIFICATION_TO_UPLOAD_PREPARATION_MATERIAL,
];

export const generateNotificationUrl = (notification: Notification): string => {
  if (notification.type === 'on_call_availability_request' && notification.source_id) {
    return `/oncall-requests/${notification.source_id}`;
  }

  if (notification.type === 'client_notification_to_add_meeting_link_starting_within_twelve_hours') {
    return routes.meeting.replace(':meetingId', notification.extra.meeting_id.toString());
  }

  if (reviewerShiftPlanEmergenciesNotifications.includes(notification.type)) {
    return `/shift-plan/${notification.extra.shift_plan_id}/call`;
  }

  if (interpreterShiftPlanEmergenciesNotifications.includes(notification.type)) {
    return `/oncall-requests/${notification.extra.shift_plan_id}`;
  }

  if (reviewerOvertimeAndEmergencyNotifications.includes(notification.type)) {
    return `/booking-request-details/${notification.extra.meeting_uuid}`;
  }

  if (interpreterOvertimeAndEmergencyNotifications.includes(notification.type)) {
    return `/meetings/${notification.extra.meeting_uuid}`;
  }

  if (interpreterMeetingNotifications.includes(notification.type)) {
    return `/meetings/${notification.extra.uuid}`;
  }

  if (reviewerBookingNotifications.includes(notification.type)) {
    return `/booking-request-details/${notification.extra.uuid}`;
  }

  if (reviewerShiftPlanNotifications.includes(notification.type)) {
    return `/shift-plan/${notification.source_id}/call`;
  }

  if (interpreterEarningNotifications.includes(notification.type)) {
    return '/earning';
  }

  if (clientNotifications.includes(notification.type)) {
    return routes.meeting.replace(':meetingId', notification.extra.meeting_id.toString());
  }

  return '';
};

export const getUnreadNotificationsCount = (notifications: Notification[]): number => {
  const unreadNotifications = notifications.filter((notification) => notification.read_at === null);

  return unreadNotifications.length;
};

interface ErrorResponse {
  response: {
    data: {
      base: string;
      message: string | string[];
      error: string;
    };
  };
}

const checkDefaultIsError = (error: unknown): error is Error => checkHasProperty(error, 'message');
const checkIsErrorResponse = (error: unknown): error is ErrorResponse => checkHasProperty(error, 'response');

export const parseResponseErrors = (error: string | ErrorResponse | Error): string => {
  let notificationString = i18n.t('shared.notifications.somethingWentWrong');

  if (!error) {
    return notificationString;
  }

  const isDefaultError = checkDefaultIsError(error);
  const isErrorResponse = checkIsErrorResponse(error);

  if (typeof error === 'string') {
    notificationString = error;
  } else if (isDefaultError && !isErrorResponse) {
    notificationString = error.message;
  } else if (!isDefaultError && !isErrorResponse) {
    notificationString = i18n.t('shared.notifications.serverNotResponding');
  } else if (typeof error.response.data?.base === 'string') {
    notificationString = error.response.data.base;
  } else if (typeof error.response.data?.message === 'string') {
    notificationString = error.response.data.message;
  } else if (typeof error.response.data?.error === 'string') {
    notificationString = error.response.data.error;
  } else if (typeof error.response.data?.message === 'object') {
    [notificationString] = error.response.data.message;
  }

  return notificationString;
};
