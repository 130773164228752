import { UNAUTHENTICATED_LAST_URL } from 'domains/shared/constants/cookie';

export const clearBrowserStorageAndCookies = (): void => {
  localStorage.clear();
  sessionStorage.clear();
  clearCookies();
};

const clearCookies = (): void => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // clears all cookies except the UNAUTHENTICATED_LAST_URL
    if (name !== UNAUTHENTICATED_LAST_URL) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }
  }
};
