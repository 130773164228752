import apis from 'apis';
import axios from 'axios';
import Cookies from 'js-cookie';
import Qs from 'qs';
import { createAction } from 'redux-actions';

import { SIGNOUT_SUCCESS } from 'constants/authConstants';
import { getUserOrganizations } from 'domains/client/slices/organizationSlice';
import { REPLAY_BOOKING_FLOW, REPLAY_FLOW_VARIANT } from 'domains/replay/constants';
import { LAST_VISITED_URL } from 'domains/shared/constants/common';
import {
  AUTH_TOKEN_COOKIE_NAME,
  SELF_SERVE_BOOKING_FLOW_HEADER_NAME,
  USER_ROLE_COOKIE_NAME,
} from 'domains/shared/constants/cookie';
import { SELF_SERVE_BOOKING_FLOW } from 'domains/shared/constants/selfServiceBookingFlow';
import { checkIsClientDomain } from 'domains/shared/helpers/checkIsClientDomain';
import { generateLoginUrl } from 'domains/shared/helpers/generateLoginUrl';
import { getStore } from 'domains/shared/services/store';
import { EventPlatformVariant } from 'domains/shared/types/eventPlatformVariant';

const FORBIDDEN_403_STATUS = 403;
const forbiddenStatuses = [401, 423, 409, 406];
const USER_NOT_MEMBER_OF_THE_ORG_ERROR = 'User is not a member of this organization.';

export const SignOutSuccess = createAction(SIGNOUT_SUCCESS);
export const defaultHeader = (config) => {
  config.headers.common['HTTP-X-API-TOKEN'] = process.env.REACT_APP_HTTP_X_API_TOKEN;

  if (window.localStorage.getItem(SELF_SERVE_BOOKING_FLOW)) {
    config.headers.common[SELF_SERVE_BOOKING_FLOW_HEADER_NAME] = window.localStorage.getItem(SELF_SERVE_BOOKING_FLOW);
  }

  if (Cookies.get(AUTH_TOKEN_COOKIE_NAME)) {
    config.headers.common[AUTH_TOKEN_COOKIE_NAME] = Cookies.get(AUTH_TOKEN_COOKIE_NAME);
  }

  config.headers.common[USER_ROLE_COOKIE_NAME] = Cookies.get(USER_ROLE_COOKIE_NAME)
    ? Cookies.get(USER_ROLE_COOKIE_NAME)
    : '';
};

const checkIfSessionExpired = (err) => {
  if (err.response && forbiddenStatuses.includes(err.response.status) && err.response.config.url !== apis.session) {
    const store = getStore();

    if (err.response.status === 401 && err.response.data.authorizor === 'KudoAuth') {
      Cookies.set(LAST_VISITED_URL, window.location.pathname);
      store?.dispatch(SignOutSuccess(generateLoginUrl()));
    } else {
      store?.dispatch(SignOutSuccess());
    }
  }
};
const fetchUserOrganizationsAndUpdateActiveOrg = (err) => {
  const store = getStore();

  store.dispatch(getUserOrganizations(true));
};

export const getApiUrl = () =>
  checkIsClientDomain() ? process.env.REACT_APP_API_CLIENT_URL : process.env.REACT_APP_API_URL;

const apiService = () => {
  const defaultOptions = {
    baseURL: `${getApiUrl()}/api/v1`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(
    (config) => {
      defaultHeader(config);
      config.withCredentials = true;

      return config;
    },
    (error) => {
      Cookies.remove(AUTH_TOKEN_COOKIE_NAME);
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      /**
       * When checking a user is logged in during the payment flow the endpoint might return 401, that means error.
       * In order to avoid redirecting to the homepage and then to the login/signup form I'm doing the check here.
       *
       * It's a potential candidate for improvement
       */
      const selfServeFlow = [EventPlatformVariant.ZOOM_MEETING, EventPlatformVariant.ZOOM_WEBINAR].includes(
        window.localStorage.getItem(SELF_SERVE_BOOKING_FLOW)
      );
      const replayFlow = window.localStorage.getItem(REPLAY_BOOKING_FLOW) === REPLAY_FLOW_VARIANT;

      if (!(selfServeFlow || replayFlow)) {
        checkIfSessionExpired(err);
      }

      if (
        err.response.status === FORBIDDEN_403_STATUS &&
        err.response?.data?.message === USER_NOT_MEMBER_OF_THE_ORG_ERROR
      ) {
        fetchUserOrganizationsAndUpdateActiveOrg(err);
      }

      return Promise.reject(err);
    }
  );

  return instance;
};

export const getNestedParamsRequest = (url, payload) => {
  axios.interceptors.request.use((config) => {
    // eslint-disable-next-line no-shadow
    config.paramsSerializer = (payload) =>
      Qs.stringify(payload, {
        arrayFormat: 'brackets',
        encode: false,
      });
    defaultHeader(config);
    config.withCredentials = true;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      checkIfSessionExpired(err);

      if (
        err.response.status === FORBIDDEN_403_STATUS &&
        err.response?.data?.message === USER_NOT_MEMBER_OF_THE_ORG_ERROR
      ) {
        fetchUserOrganizationsAndUpdateActiveOrg(err);
      }

      return Promise.reject(err);
    }
  );

  // TODO: why does it not work properly without process.env.REACT_APP_API_URL included?
  return axios.get(`${getApiUrl()}/api/v1${url}`, { params: payload });
};

export const baseService = apiService();

export default baseService;
