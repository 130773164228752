export enum TranslationNamespace {
  LEGACY = 'legacy',
  KUDO_LITE = 'kudoLite',
  REVIEWER = 'reviewer',
  REPLAY = 'replay',
  SHARED = 'shared',
  AI = 'ai',
}

export type TranslationNamespaces = TranslationNamespace.LEGACY &
  TranslationNamespace.KUDO_LITE &
  TranslationNamespace.REVIEWER &
  TranslationNamespace.REPLAY &
  TranslationNamespace.SHARED &
  TranslationNamespace.AI;
