import { initReactI18next } from 'react-i18next';
import i18n, { Resource } from 'i18next';

import { TranslationNamespace, TranslationNamespaces } from 'domains/shared/types/translationNamespace';

export type ResourcesParam = Record<
  string,
  Record<TranslationNamespaces, Record<string, string | Record<string, string>>>
>;

export const setupI18n = (resources: ResourcesParam): void => {
  if (!i18n.isInitialized) {
    i18n.use(initReactI18next).init({
      resources: formatResources(resources),
      fallbackNS: TranslationNamespace.SHARED,
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      debug: false,
      react: {
        bindI18nStore: 'added',
        useSuspense: true,
      },
    });
  } else {
    addAdditionalResources(resources);
  }
};

const formatResources = (resources: ResourcesParam): Resource =>
  Object.entries(resources).reduce(
    (langTranslations, [lang, namespacedTranslations]) => ({
      ...langTranslations,
      [lang]: Object.entries(namespacedTranslations).reduce(
        (namespaceTranslations, [namespace, translations]) => ({
          ...namespaceTranslations,
          [namespace]: translations,
        }),
        {}
      ),
    }),
    {}
  ) as unknown as Resource;
const addAdditionalResources = (resources: ResourcesParam): void => {
  Object.entries(resources).forEach(([lang, namespacedTranslations]) => {
    Object.entries(namespacedTranslations).forEach(([namespace, translations]) => {
      i18n.addResourceBundle(lang, namespace, translations);
    });
  });
};

export default i18n;
