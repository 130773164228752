import { NotificationVariant } from 'constants/NotificationVariant';
import { useLegacyTranslation } from 'hooks/useLegacyTranslation';
import { Notification } from 'shared/types/notification';

interface Props {
  notification: Notification;
  notificationType: string;
}

export const AdjustmentNotification = ({ notification, notificationType }: Props): JSX.Element => {
  const { t } = useLegacyTranslation();

  return (
    <div>
      <strong className="title notification-title">
        ${parseFloat(notification.extra.amount).toFixed(2)}{' '}
        {notificationType === NotificationVariant.INTERPRETER_WALLET_DEBIT_ADJUSTMENT
          ? t('general.debit')
          : t('general.credit')}
      </strong>
      <p>{notification.extra.comment}</p>
      <p>{t('notifications.clickOrTapToReview')}</p>
    </div>
  );
};
