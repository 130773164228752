import { format } from 'date-fns';
import moment from 'moment';

import { NotificationVariant } from 'constants/NotificationVariant';
import { FNS_DATE_TIME_STARTING_YEAR_24_HOURS } from 'domains/shared/constants/dateTimeFormats';
import { calculateTimeInHours } from 'helpers/notificationHelper';
import { useLegacyTranslation } from 'hooks/useLegacyTranslation';
import { Notification } from 'shared/types/notification';

import { AdjustmentNotification } from './AdjustmentNotification';
import { PreparationMaterialNotification } from './PreparationMaterialNotification';

interface Props {
  notification: Notification;
}

export const NotificationMessageBody = ({ notification }: Props): JSX.Element | null => {
  const { t } = useLegacyTranslation();

  switch (notification.type) {
    case NotificationVariant.ON_CALL_AVAILABILITY_REQUEST:
      return (
        <div>
          <strong className="title">
            {`${t('notifications.newOffer.onCallShift')} - ${calculateTimeInHours(notification.extra.remaining_time)} `}
          </strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.NEW_MEETING_REQUEST:
      return (
        <div>
          <strong className="title">
            {`${t('notifications.newMeetingRequest', {
              platform: notification.extra.zoom_event?.toLowerCase(),
            })} - ${calculateTimeInHours(notification.extra.remaining_time)} `}
          </strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_ADDED_FAST_TRACK:
      return (
        <div>
          <strong className="title">{t('interpreter.notifications.urgentActionRequired')}</strong>
          <p>{t('interpreter.notifications.newAssignment')}</p>
          <p>{t('notifications.clickOrTapToAcknowledge')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_ACCEPTED_SLOW_TRACK:
      return (
        <div>
          <strong className="title">{t('interpreter.notifications.newAssignment')}</strong>
          <span className="meeting-title">{notification.extra.title}</span>
        </div>
      );
    case NotificationVariant.MEETING_REMINDER_NOTIFICATION:
      return (
        <div>
          <strong className="title">{t('notifications.meetingReminder.assignmentStartsIn30Mins')}</strong>
          <p>{t('notifications.clickOrTapForMeetingDetails')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_PENDING_REVIEW:
      return (
        <div>
          <strong className="title">{t('notifications.actionRequired')}</strong>
          <p>{t('notifications.clickOrTapToReviewPendingItems')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_OFFERS_DECLINED:
      return (
        <div>
          <strong className="title">{t('notifications.actionRequired')}</strong>
          <p>{t('reviewer.notifications.offerDeclined')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_BOOKING_CONFIRMED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.teamComplete')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEW_INTERPRETERS_REMINDER:
      return (
        <div>
          <strong className="title">{t('interpreter.notifications.urgentActionRequired')}</strong>
          <p>{t('notifications.clickOrTapToReviewPendingItems')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_INTERPRETERS_NOT_FOUND:
      return (
        <div>
          <strong className="title">{t('notifications.actionRequired')}</strong>
          <p>
            {t('reviewer.notifications.requiredInterpretersNotFound')} {t('notifications.clickOrTapToReview')}
          </p>
        </div>
      );
    case NotificationVariant.REVIEW_INTERPRETER_REPORTED_BOOKING_EMERGENCY:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.interpreterEmergency')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEW_INTERPRETER_REPORTED_SHIFT_PLAN_EMERGENCY:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.interpreterEmergency')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEW_INTERPRETER_REPORTED_BOOKING_ISSUE:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newReportFromInterpreter')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEW_INTERPRETER_REPORTED_SHIFT_PLAN_ISSUE:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newReportFromInterpreter')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_COMPLETED_INTERPRETER_REPORTED_BOOKING_EMERGENCY:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_COMPLETED_INTERPRETER_REPORTED_SHIFT_PLAN_EMERGENCY:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_COMPLETED_INTERPRETER_REPORTED_BOOKING_ISSUE:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_COMPLETED_INTERPRETER_REPORTED_SHIFT_PLAN_ISSUE:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEW_BOOKING_CANCELLED:
      return (
        <div>
          <strong className="title">
            {t('reviewer.notifications.meetingCancelled', { zoom_event: notification.extra.zoom_event })}
          </strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_BOOKING_CANCELLED:
      return (
        <div>
          <strong className="title">
            {t('reviewer.notifications.meetingCancelled', { zoom_event: notification.extra.zoom_event })}
          </strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEW_INTERPRETER_OVERTIME_CLAIM:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newOvertimeClaim')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_DECLINED_INTERPRETER_OVERTIME_CLAIM:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_APPROVED_INTERPRETER_OVERTIME_CLAIM:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_NEW_ASSIGNED_BOOKING:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.beenAddedAsReviewer')}</strong>
          <p>{notification.extra.title}</p>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_SHIFT_PLAN_OFFERS_ACCEPTED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.onCallShift.complete')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_SHIFT_PLAN_OFFERS_DECLINED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.shiftPlan.declined')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_SHIFT_PLAN_COMPLETED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.onCallShift.ended')}</strong>
          <p>{t('notifications.clickOrTapToClearPayments')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_CLEARED_INTERPRETER_EARNINGS:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newMessageFromKudo')}</strong>
          <p>{t('interpreter.notifications.youHaveBeenPaid')}</p>
          <p>{t('notifications.clickOrTapToReviewEarnings')}</p>
        </div>
      );
    case NotificationVariant.REVIEWER_NEW_MEETING_CREATED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.newBookingRequest')}</strong>
          <p>{notification.extra.title}</p>
          <p>{`${t('general.reviewer')}: ${notification.extra.reviewer_full_name}`}</p>
        </div>
      );
    case NotificationVariant.BOOKING_INTERPRETER_NOT_RESPONDED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.offerExpired')}</strong>
          <p>{t('notifications.clickOrTapToEditAndResend')}</p>
        </div>
      );
    case NotificationVariant.SHIFT_INTERPRETER_NOT_RESPONDED:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.offerExpired')}</strong>
          <p>{t('notifications.clickOrTapToEditAndResend')}</p>
        </div>
      );
    case NotificationVariant.NON_ON_CALL_FAST_TRACK_OFFER_SENT:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.offerSent')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_ACCEPTED_FAST_TRACK:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.offerAccepted')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_UNRESPONSIVE_FAST_TRACK:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.interpreterUnresponsive')}</strong>
          <strong className="title">{t('reviewer.notifications.offerResent')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.FINAL_INTERPRETER_UNRESPONSIVE_FAST_TRACK:
      return (
        <div>
          <strong className="title">{t('reviewer.notifications.offerExpired')}</strong>
          <strong className="title">{t('reviewer.notifications.interpreterNotFound')}</strong>
          <p>{t('notifications.clickOrTapToReview')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_REMOVED_FROM_MEETING:
      return (
        <div>
          <strong className="title">{t('interpreter.notifications.assignmentNoLongerAvailable')}</strong>
          <p>{notification.extra.title}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_SHIFT_PLAN_REMOVED:
      return (
        <div>
          <strong className="title">{t('interpreter.notifications.shiftPlanNotAvailable')}</strong>
          <p>{notification.extra.name}</p>
        </div>
      );
    case NotificationVariant.CLIENT_NOTIFICATION_TO_MEET_YOUR_INTERPRETERS:
      return (
        <div>
          <strong className="title">
            {t('client.notifications.meetYourInterpreters', { platform: notification.extra.zoom_event })}
          </strong>
          <p>{t('text.MeetYourInterpreters')}</p>
        </div>
      );
    case NotificationVariant.INTERPRETER_PREPARATION_MATERIAL_ADDED:
      return <PreparationMaterialNotification notification={notification} />;
    case NotificationVariant.INTERPRETER_WALLET_CREDIT_ADJUSTMENT:
      return (
        <AdjustmentNotification
          notification={notification}
          notificationType={NotificationVariant.INTERPRETER_WALLET_CREDIT_ADJUSTMENT}
        />
      );
    case NotificationVariant.INTERPRETER_WALLET_DEBIT_ADJUSTMENT:
      return (
        <AdjustmentNotification
          notification={notification}
          notificationType={NotificationVariant.INTERPRETER_WALLET_DEBIT_ADJUSTMENT}
        />
      );
    case NotificationVariant.CLIENT_NOTIFICATION_TO_UPLOAD_PREPARATION_MATERIAL:
      return (
        <section>
          <h3 className="notification-title">{t('client.preparationMaterial.missing')}</h3>
          <p>{notification.extra.title}</p>
          <p>{moment(notification.extra.start_time).format('dddd, DD MMMM, YYYY hh:mm A')}</p>
          <p>{t('text.ClickOrTapToReview')}</p>
        </section>
      );
    case NotificationVariant.CLIENT_NOTIFICATION_FOR_DELETED_ZOOM_WEBINAR:
      return (
        <section>
          <h3 className="notification-title">{t('client.notifications.webinarCanceledOnZoom')}</h3>
          <p>{notification.extra.title}</p>
          <p>{format(new Date(notification.extra.start_time), FNS_DATE_TIME_STARTING_YEAR_24_HOURS)}</p>
        </section>
      );
    case NotificationVariant.CLIENT_NOTIFICATION_FOR_WEBINAR_NOT_EDITABLE:
      return (
        <section>
          <h3 className="notification-title">{t('client.notifications.webinarNotEditable')}</h3>
          <p>{notification.extra.title}</p>
          <p>{format(new Date(notification.extra.start_time), FNS_DATE_TIME_STARTING_YEAR_24_HOURS)}</p>
          <p>{t('text.ClickOrTapToReview')}</p>
        </section>
      );
    case NotificationVariant.CLIENT_NOTIFICATION_TO_ADD_MEETING_LINK_STARTING_WITHIN_TWELVE_HOURS:
      return (
        <section>
          <h3 className="notification-title">{t('client.notifications.addMeetingLink')}</h3>
        </section>
      );
    default:
      return null;
  }
};
