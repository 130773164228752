import { differenceInMinutes, format, getYear as dateFnsGetYear } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import {
  DATE_WITH_TIME,
  DATE_WITH_TIME_ZULU,
  FNS_AMERICAN_DATE,
  FNS_DATE_STARTING_YEAR,
  FNS_DATE_TIME_12_HOURS,
  FNS_DATE_TIME_24_HOURS,
  FNS_DAY_3_LETTER_MONTH_YEAR,
  FNS_TIME_12H,
  TIME_24H,
  TIME_FORMAT,
} from 'domains/shared/constants/dateTimeFormats';
import { DEFAULT_TIMEZONE } from 'domains/shared/constants/Timezones';
import { AmericanTimeVariant } from 'domains/shared/types/americanTimeVariant';
import { getTimezoneWithUTCFallback } from 'helpers/commonHelpers';

export const shortDateTimeWithTimezone = (date: Date, timezone: string): string => {
  if (!date || !timezone) {
    return '';
  }

  return new Intl.DateTimeFormat(window.navigator.language, {
    timeStyle: 'short',
    dateStyle: 'short',
    timeZone: timezone,
  }).format(date);
};

export const shortDateTime = (date: string): string =>
  new Intl.DateTimeFormat(window.navigator.language, {
    timeStyle: 'short',
    dateStyle: 'short',
  }).format(new Date(date));

export const formatDate = (date: Date | string, dateFormat: string): string => format(new Date(date), dateFormat);

export const zonedTimeToZuluString = (date: string, timeZone: string): string => {
  const startTimeInUTC = zonedTimeToUtc(new Date(date), timeZone);

  return formatInTimeZone(startTimeInUTC, 'UTC', DATE_WITH_TIME_ZULU);
};

export const formatMeetingTime = (date: string, timeZone: string, isAmericanTimeVariant = false): string =>
  formatInTimeZone(date, timeZone, isAmericanTimeVariant ? FNS_DATE_TIME_12_HOURS : FNS_DATE_TIME_24_HOURS);
export const formatInterpreterMeetingDate = (date: Date | string | number, timezoneCode: string): string =>
  formatInTimeZone(new Date(date), timezoneCode || DEFAULT_TIMEZONE.iana_code, FNS_DAY_3_LETTER_MONTH_YEAR);

export const formatTime = (date: Date | string | number, timezoneCode: string, isAmericanTimeVariant = false): string =>
  formatInTimeZone(
    new Date(date),
    timezoneCode || DEFAULT_TIMEZONE.iana_code,
    isAmericanTimeVariant ? FNS_TIME_12H : TIME_24H
  );

export const createDateTime = (date: string, time: string): Date =>
  new Date(`${format(new Date(date), FNS_DATE_STARTING_YEAR)}T${format(new Date(time), TIME_FORMAT)}`);

interface GetDateTimeArgument {
  isAmerican: boolean;
  startDate: string;
  startTime: string;
  americanTimeVariant: AmericanTimeVariant;
}

export const getDateTimeString = ({
  isAmerican,
  startDate,
  startTime,
  americanTimeVariant,
}: GetDateTimeArgument): string => {
  const [hours, minutes] = startTime.split(':');
  const hoursAppendix = isAmerican && americanTimeVariant === AmericanTimeVariant.AFTER_NOON ? 12 : 0;
  const hoursWithAppendix = parseInt(hours, 10) + hoursAppendix;
  const dateTime = `${format(new Date(startDate), FNS_AMERICAN_DATE)} ${
    isAmerican && hoursWithAppendix % 12 === 0 ? hoursWithAppendix - 12 : hoursWithAppendix
  }:${minutes}`;
  const startDateTime = format(new Date(dateTime), DATE_WITH_TIME);

  return startDateTime;
};

export const getDateTimeWithTimezoneFns = (datetime: Date, timezone: string): Date =>
  utcToZonedTime(datetime, getTimezoneWithUTCFallback(timezone));

export const getYear = (date?: Date): number => {
  if (!date) {
    return dateFnsGetYear(new Date());
  }

  return dateFnsGetYear(date);
};

export const getOffsetByTimezoneInHour = (timezone: string): string => {
  const offset = getTimezoneOffset(timezone);
  const offsetInHours = offset / (60 * 60 * 1000);
  let hours = Math.floor(offsetInHours);
  let minutes = Math.round((offsetInHours - hours) * 60);

  if (minutes > 0) {
    const additionalHours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    hours += additionalHours;
    minutes = remainingMinutes;
  }

  return offsetInHours >= 0
    ? `+${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    : `-${String(Math.abs(hours)).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const calculateTimeDifferenceInMinutesFns = (start: Date, end: Date): number => differenceInMinutes(end, start);
