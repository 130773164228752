/* eslint-disable guard-for-in, no-shadow, array-callback-return */
import produce from 'immer';
import { findIndex, sortBy, uniq } from 'lodash-es';
import moment from 'moment';

import { DATE_STARTING_YEAR, TIME_24H } from 'domains/shared/constants/dateTimeFormats';
import { InterpreterStatus } from 'shared/types/interpreterStatus';

import { statusConstants } from '../constants/bookingRequestConstants';
import { initialValues } from '../fixtures/shiftPlans';

export const replaceKey = (oldKey, newKey, Obj) => {
  Object.defineProperty(Obj, newKey, Object.getOwnPropertyDescriptor(Obj, oldKey));
  delete Obj[oldKey];
};

export const statusMappingClass = (status) => {
  const statuses = [
    statusConstants.new_shift,
    statusConstants.offers_declined,
    statusConstants.completed,
    statusConstants.paid,
  ];

  return statuses.includes(status) ? 'newrequest' : '';
};

const refactorData = (values) => {
  if (!('shift_languages_attributes' in values)) {
    replaceKey('shift_languages', 'shift_languages_attributes', values);
    values.shift_languages_attributes.map((shiftplan) => {
      replaceKey('shift_details', 'shift_details_attributes', shiftplan);
      shiftplan.language = shiftplan.language.name;
    });
  }

  values.shift_languages_attributes.forEach((shiftLanguage) => {
    if (shiftLanguage.id) {
      shiftLanguage.shift_details_attributes.forEach((shiftDetail) => {
        shiftDetail.start_time = new Date(moment(shiftDetail.start_time).utc().format('MMM DD, YYYY HH:mm'));
        shiftDetail.end_time = new Date(moment(shiftDetail.end_time).utc().format('MMM DD, YYYY HH:mm'));
      });
    }
  });

  return values;
};
const findLanguageInValues = (language, formValues) =>
  formValues.shift_languages_attributes.filter((item) => item.language === language);

export const prepareData = (formValues) => {
  formValues = refactorData(formValues);
  initialValues.shift_languages_attributes.forEach((shiftLanguage) => {
    if (findLanguageInValues(shiftLanguage.language.toUpperCase(), formValues).length === 0) {
      formValues.shift_languages_attributes.push(shiftLanguage);
    }
  });
  const sortedArray = sortBy(formValues.shift_languages_attributes, ['language'], ['asc']);

  formValues.shift_languages_attributes = sortedArray;

  return formValues;
};

export const shiftPlanCleanedData = (values, codes) => {
  values.shift_languages_attributes = values.shift_languages_attributes.filter(
    (lang) => lang.interpreters_count !== 0 || lang.id
  );
  values.shift_languages_attributes.forEach((item) => {
    item.language_id = codes.filter((lang) => lang.name === item.language.toUpperCase())[0].id;
    item.shift_details_attributes.forEach((shiftDetail) => {
      shiftDetail.start_time = moment(shiftDetail.start_time).format(TIME_24H);
      shiftDetail.end_time = moment(shiftDetail.end_time).format(TIME_24H);
    });
  });
  values.start_date = moment(values.start_date).format(DATE_STARTING_YEAR);
  values.end_date = moment(values.end_date).format(DATE_STARTING_YEAR);

  return values;
};

export const formValidation = (values) => {
  const errors = [];

  if (values.name?.length < 1) {
    errors.push('Name is Required');
  }

  values.shift_languages_attributes.forEach((shiftplan) => {
    // eslint-disable-next-line camelcase
    const { language, shift_details_attributes } = shiftplan;
    const totalInterpreters = parseInt(shiftplan.interpreters_count, 10);
    let totalDetailsInterpreters = 0;

    shift_details_attributes.forEach((shiftplandetail) => {
      // _detroy is a property for rails app which helps to delete an associated record
      // eslint-disable-next-line no-underscore-dangle
      if (!shiftplandetail._destroy) {
        totalDetailsInterpreters += parseInt(shiftplandetail.interpreters_count, 10);
      }
    });

    if (totalInterpreters !== totalDetailsInterpreters) {
      errors.push(`${language}: Sum of total interpreters is not equal to the total sum.`);
    }

    shift_details_attributes.forEach((current, currentIndex) => {
      shift_details_attributes.forEach((other, otherIndex) => {
        if (currentIndex !== otherIndex) {
          if (
            current.time_zone === other.time_zone &&
            moment(current.start_time).isSame(other.start_time) &&
            moment(current.end_time).isSame(other.end_time)
          ) {
            errors.push(`${language}: has duplicate timezones.`);
          }
        }
      });
    });
  });

  return errors.length > 0 ? { status: false, errors: uniq(errors) } : { status: true };
};

export const getDeclinedShiftInterpretersIds = (shiftDetail) => {
  const unInvite = [];

  shiftDetail.shift_interpreters.forEach((shiftInterpreter) => {
    if (
      [InterpreterStatus.DECLINED, InterpreterStatus.NOT_RESPONDED, InterpreterStatus.NOT_AVAILABLE].includes(
        shiftInterpreter.status
      )
    ) {
      unInvite.push(shiftInterpreter.id);
    }
  });

  return unInvite;
};

export const getPendingInviteShiftInterpretersIds = (shiftDetail) => {
  const unInvite = [];

  shiftDetail.shift_interpreters.forEach((shiftInterpreter) => {
    if (shiftInterpreter.status === InterpreterStatus.PENDING_INVITE) {
      unInvite.push(shiftInterpreter.id);
    }
  });

  return unInvite;
};

export const shiftPlanStatusColor = (status) =>
  [
    statusConstants.declined,
    statusConstants.not_responded,
    statusConstants.not_available,
    statusConstants.paid,
  ].includes(status);

export const shiftInterpreterChecked = (status) =>
  ![statusConstants.declined, statusConstants.not_responded, statusConstants.not_available].includes(status);

export const prepareFormData = (shiftPlan) => {
  const interpreters = [];

  // eslint-disable-next-line no-unused-expressions
  shiftPlan?.shift_languages?.forEach((shift_language) => {
    // eslint-disable-next-line no-unused-expressions
    shift_language?.shift_details.forEach((shift_detail) => {
      shift_detail.shift_interpreters.forEach((shift_interpreter) => {
        if (shift_interpreter.status === InterpreterStatus.ACCEPTED) {
          interpreters.push({
            shift_plan_id: shiftPlan.id,
            shift_interpreter_id: shift_interpreter.id,
            interpreter_name: shift_interpreter.interpreter.full_name,
            interpreter_id: shift_interpreter.interpreter.id,
            user_id: shift_interpreter.interpreter.user_id,
          });
        }
      });
    });
  });

  return interpreters;
};

export const sendOffersPayload = (shiftPlan, hours, minutes) => {
  const interpreters = [];

  // eslint-disable-next-line no-unused-expressions
  shiftPlan?.shift_languages?.forEach((shift_language) => {
    // eslint-disable-next-line no-unused-expressions
    shift_language?.shift_details.forEach((shift_detail) => {
      shift_detail.shift_interpreters.forEach((shift_interpreter) => {
        if (shift_interpreter.status === InterpreterStatus.PENDING_INVITE) {
          interpreters.push({
            id: shift_interpreter.id,
            status: InterpreterStatus.NEW_OFFER,
            hours,
            minutes,
          });
        }
      });
    });
  });

  return interpreters;
};

export const checkRepeatSearch = (showButtons, declined, shiftPlanStatus) => {
  // TODO: fix the name
  const a =
    (showButtons || declined.length > 0) &&
    ![InterpreterStatus.COMPLETED, InterpreterStatus.PAID].includes(shiftPlanStatus);

  return a;
};

export const checkAddManual = (shiftPlanStatus) => {
  if ([InterpreterStatus.COMPLETED, InterpreterStatus.PAID].includes(shiftPlanStatus)) {
    return false;
  }

  return true;
};

export const timezoneOptions = (timezones) =>
  timezones.map((timezone) => ({
    name: `${timezone.name} (UTC ${timezone.offset})`,
    value: timezone.name,
  }));

export const cleanShiftInterpretersData = (selectedInterpreters, shiftDetailId) =>
  selectedInterpreters
    .filter((inter) => inter.checked)
    .map((inter) => ({
      shift_detail_id: shiftDetailId,
      interpreter_id: inter.id,
    }));

export const addBulkShiftInterpreters = (payload, initialValues) => {
  const { created, languageIndex, shiftDetailIndex } = payload;
  const updatedValues = produce(initialValues, (draft) => {
    draft.shift_languages[languageIndex].shift_details[shiftDetailIndex].shift_interpreters.push(...created);
  });

  return updatedValues;
};

export const interpretersArray = (interpreters, searchBy) =>
  interpreters.map((inter) => ({
    ...inter,
    label: searchBy === 'email' ? inter.email : inter.name,
    value: inter.id,
  }));

export const getReplaceableData = (unCheckedList, cleanedData) => {
  let replacePayload = [];

  if (unCheckedList.length <= cleanedData.length) {
    for (let i = 0; i < unCheckedList.length; i += 1) replacePayload.push(cleanedData.shift());

    return [replacePayload, cleanedData];
  }

  replacePayload = [...cleanedData];

  return [replacePayload, []];
};

export const removeInterpreter = (oldData, payload) => {
  const { removed, languageIndex, shiftDetailIndex } = payload;
  const newShiftInterpreters =
    oldData.shift_languages[languageIndex].shift_details[shiftDetailIndex].shift_interpreters;
  let index = 0;

  index = findIndex(newShiftInterpreters, { id: removed.id });
  const updatedValues = produce(oldData, (draft) => {
    draft.shift_languages[languageIndex].shift_details[shiftDetailIndex].shift_interpreters.splice(index, 1);
  });

  return updatedValues;
};
