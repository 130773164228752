import { AnyAction, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage';

import { SIGNOUT_SUCCESS } from 'constants/authConstants';
import { aiBookingReducer } from 'domains/ai/slices/aiBookingSlice';
import { clientReducer } from 'domains/client/reducers/clientReducer';
import { organizationReducer } from 'domains/client/slices/organizationSlice';
import { serverFeatureFlagReducer } from 'domains/client/slices/serverFeatureFlagsSlice';
import { translationServiceReducer } from 'domains/client/slices/translationServiceSlice';
import { zeroStateFlowReducer } from 'domains/client/slices/zeroStateFlowSlice';
import { cleanUpBySignOut } from 'domains/shared/helpers/cleanUpBySignOut';
import { tabReducer } from 'domains/shared/reducers/tabReducer';

import accountReducer from './accountReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import bookingRequestReducer from './bookingRequestReducer';
import commonReducer from './commonReducer';
import customWordsReducer from './customWordsReducer';
import documentReducer from './documentReducer';
import earningReducer from './earningReducer';
import interpreterRateReducer from './interpreterRateReducer';
import interpreterReducer from './interpreterReducer';
import navigationReducer from './navigationReducer';
import notificationReducer from './notificationReducer';
import reviewerReducer from './reviewerReducer';
import shiftPlanReducer from './shiftPlanReducer';

const appReducer = combineReducers({
  interpreterReducer,
  tabReducer,
  navigationReducer,
  bookingRequestReducer,
  commonReducer,
  customWordsReducer,
  shiftPlanReducer,
  interpreterRateReducer,
  notificationReducer,
  documentReducer,
  earningReducer,
  authReducer,
  reviewerReducer,
  adminReducer,
  accountReducer,
  clientReducer,
  zeroStateFlow: zeroStateFlowReducer,
  organization: organizationReducer,
  featureFlags: serverFeatureFlagReducer,
  translationService: translationServiceReducer,
  aiBooking: aiBookingReducer,
  form: formReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: AnyAction): ReturnType<typeof appReducer> => {
  if (action.type === SIGNOUT_SUCCESS) {
    storage.removeItem('persist:root');
    cleanUpBySignOut();
  }

  return appReducer(state, action);
};
