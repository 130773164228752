import { InterpreterStatus } from 'shared/types/interpreterStatus';

export const GET_BOOKING_REQUEST_BY_ID = 'GET_BOOKING_REQUEST_BY_ID';
export const GET_BOOKING_REQUEST_BY_ID_SUCCESS = 'GET_BOOKING_REQUEST_BY_ID_SUCCESS';
export const GET_BOOKING_REQUEST_BY_ID_ERROR = 'GET_BOOKING_REQUEST_BY_ID_ERROR';

export const GET_BOOKING_RECORDINGS_BY_ID_SUCCESS = 'GET_BOOKING_RECORDINGS_BY_ID_SUCCESS';
export const GET_BOOKING_RECORDINGS_BY_ID_ERROR = 'GET_BOOKING_RECORDINGS_BY_ID_ERROR';

export const SET_SURVEY_FLAG_FROM_BOOKING_REQUEST = 'SET_SURVEY_FLAG_FROM_BOOKING_REQUEST';

export const GET_ALL_BOOKING_REQUESTS = 'GET_ALL_BOOKING_REQUESTS';
export const GET_ALL_BOOKING_REQUESTS_SUCCESS = 'GET_ALL_BOOKING_REQUESTS_SUCCESS';
export const GET_ALL_BOOKING_REQUESTS_ERROR = 'GET_ALL_BOOKING_REQUESTS_ERROR';
export const SET_TAB_COUNT_NONE = 'SET_TAB_COUNT_NONE';

export const GET_BOOKING_REQUEST_INTERPRETERS_BY_LANGUAGE_SUCCESS =
  'GET_BOOKING_REQUEST_INTERPRETERS_BY_LANGUAGE_SUCCESS';

export const GET_ALL_USER_BOOKING_REQUESTS = 'GET_ALL_USER_BOOKING_REQUESTS';
export const GET_ALL_USER_BOOKING_REQUESTS_SUCCESS = 'GET_ALL_USER_BOOKING_REQUESTS_SUCCESS';
export const GET_ALL_USER_BOOKING_REQUESTS_ERROR = 'GET_ALL_USER_BOOKING_REQUESTS_ERROR';

export const GET_BOOKING_REQUEST_INTERPRETERS = 'GET_BOOKING_REQUEST_INTERPRETERS';
export const GET_BOOKING_REQUEST_INTERPRETERS_SUCCESS = 'GET_BOOKING_REQUEST_INTERPRETERS_SUCCESS';
export const GET_BOOKING_REQUEST_INTERPRETERS_ERROR = 'GET_BOOKING_REQUEST_INTERPRETERS_ERROR';

export const GET_MEETING_FEE_SUCCESS = 'GET_MEETING_FEE_SUCCESS';

export const REMOVE_BOOKING_INTERPRETER_AFTER_ISSUE = 'REMOVE_BOOKING_INTERPRETER_AFTER_ISSUE';
export const REMOVE_PENDING_MEETING = 'REMOVE_PENDING_MEETING';

export const statusConstants = {
  new_offer: InterpreterStatus.NEW_OFFER,
  new_request: 'new_request',
  declined: 'declined',
  in_progress: 'in_progress',
  completed: 'completed',
  not_responded: 'not_responded',
  not_available: 'not_available',
  accepted: 'accepted',
  pending_invite: InterpreterStatus.PENDING_INVITE,
  suspended: 'suspended',
  active: 'active',
  new_shift: 'new_shift',
  offers_declined: 'offers_declined',
  paid: 'paid',
  cancelled: 'cancelled',
  expired: 'expired',
  unconfirmed: 'unconfirmed',
};

export const GET_NEW_OFFER_MEETING_SUCCESS = 'GET_NEW_OFFER_MEETING_SUCCESS';
export const GET_UPCOMING_MEETING_SUCCESS = 'GET_UPCOMING_MEETING_SUCCESS';
export const GET_PENDING_MEETING_SUCCESS = 'GET_PENDING_MEETING_SUCCESS';
export const GET_PREVIOUS_MEETING_SUCCESS = 'GET_PREVIOUS_MEETING_SUCCESS';

export const UPDATE_BOOKING_INTERPRETER_STATUS_SUCCESS = 'UPDATE_BOOKING_INTERPRETER_STATUS_SUCCESS';

export const GET_BOOKED_INTERPRETERS = 'GET_BOOKED_INTERPRETERS';

export const GET_ON_CALL_SHIFT_PLANS = 'GET_ON_CALL_SHIFT_PLANS';
export const GET_ON_CALL_SHIFT_PLANS_SUCCESS = 'GET_ON_CALL_SHIFT_PLANS_SUCCESS';
export const GET_ON_CALL_SHIFT_PLANS_ERROR = 'GET_ON_CALL_SHIFT_PLANS_ERROR';

export const GET_ISSUES_LIST = 'GET_ISSUES_LIST';
export const GET_ISSUES = 'GET_ISSUES';

export const MAKE_EARNING = 'MAKE_EARNING';
export const REPORT_EMERGENCY = 'REPORT_EMERGENCY';
export const REPORT_EMERGENCY_WITH_MEETING_TAB = 'REPORT_EMERGENCY_WITH_MEETING_TAB';

export const GET_BOOKING_ISSUES = 'GET_BOOKING_ISSUES';

export const ADD_ISSUE_COMMENT = 'ADD_ISSUE_COMMENT';

export const GET_BOOKING_INTERPRETER_OVERTIME_CLAIM = 'GET_BOOKING_INTERPRETER_OVERTIME_CLAIM';
export const GET_BOOKING_INTERPRETER_OVERTIME_CLAIM_SUCCESS = 'GET_BOOKING_INTERPRETER_OVERTIME_CLAIM_SUCCESS';
export const GET_BOOKING_INTERPRETER_OVERTIME_CLAIM_FAILURE = 'GET_BOOKING_INTERPRETER_OVERTIME_CLAIM_FAILURE';

export const GET_BOOKING_OVERTIME_CLAIMS = 'GET_BOOKING_OVERTIME_CLAIMS';
export const GET_BOOKING_OVERTIME_CLAIMS_SUCCESS = 'GET_BOOKING_OVERTIME_CLAIMS_SUCCESS';
export const GET_BOOKING_OVERTIME_CLAIMS_FAILURE = 'GET_BOOKING_OVERTIME_CLAIMS_FAILURE';

export const UPDATE_BOOKING_OVERTIME_CLAIM = 'UPDATE_BOOKING_OVERTIME_CLAIM';
export const SET_LOADING = 'SET_LOADING';

export const UPDATE_BOOKING_REVIEWER_SUCCESS = 'UPDATE_BOOKING_REVIEWER_SUCCESS';

export const OVERTIME_CLAIM_WITH_MEETING_TAB = 'OVERTIME_CLAIM_WITH_MEETING_TAB';

export const GET_SEARCHED_INTERPRETERS = 'GET_SEARCHED_INTERPRETERS';

export const SET_NEW_BULK_BOOKING_INTERPRETERS = 'SET_NEW_BULK_BOOKING_INTERPRETERS';

export const SET_PREPARATION_MATERIALS = 'SET_PREPARATION_MATERIALS';
export const DELETE_PREPARATION_MATERIAL = 'DELETE_PREPARATION_MATERIAL';

export const GET_MEETING_FEE = 'GET_MEETING_FEE';

export const GET_CUSTOM_RATE_REASONS = 'GET_MEETING_RATE_REASONS';

export const UPDATE_BOOKING_INTERPRETER = 'UPDATE_BOOKING_INTERPRETER';
