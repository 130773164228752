import { ZoomLanguage } from 'shared/types/zoomLanguage';

export const ZOOM_LANGUAGES: ZoomLanguage[] = [
  {
    name: 'Arabic',
    type: 'NL',
    code: 'ARA',
    zoom_code: 'D-AR',
  },
  {
    name: 'Chinese',
    type: 'NL',
    code: 'CHI',
    zoom_code: 'CN',
  },
  {
    name: 'English',
    type: 'NL',
    code: 'ENG',
    zoom_code: 'US',
  },
  {
    name: 'French',
    type: 'NL',
    code: 'FRE',
    zoom_code: 'FR',
  },
  {
    name: 'German',
    type: 'NL',
    code: 'GER',
    zoom_code: 'DE',
  },
  {
    name: 'Hindi',
    type: 'NL',
    code: 'HIN',
    zoom_code: 'D-HI',
  },
  {
    name: 'Indonesian',
    type: 'NL',
    code: 'IND',
    zoom_code: 'D-ID',
  },
  {
    name: 'Italian',
    type: 'NL',
    code: 'ITA',
    zoom_code: 'D-IT',
  },
  {
    name: 'Japanese',
    type: 'NL',
    code: 'JPN',
    zoom_code: 'JP',
  },
  {
    name: 'Korean',
    type: 'NL',
    code: 'KOR',
    zoom_code: 'KR',
  },
  {
    name: 'Malay',
    type: 'NL',
    code: 'MAY',
    zoom_code: 'D-MS',
  },
  {
    name: 'Portuguese',
    type: 'NL',
    code: 'POR',
    zoom_code: 'PT',
  },
  {
    name: 'Russian',
    type: 'NL',
    code: 'RUS',
    zoom_code: 'RU',
  },
  {
    name: 'Spanish',
    type: 'NL',
    code: 'SPA',
    zoom_code: 'ES',
  },
  {
    name: 'Thai',
    type: 'NL',
    code: 'THA',
    zoom_code: 'D-TH',
  },
  {
    name: 'Turkish',
    type: 'NL',
    code: 'TUR',
    zoom_code: 'D-TR',
  },
  {
    name: 'Vietnamese',
    type: 'NL',
    code: 'VIE',
    zoom_code: 'D-VI',
  },
];
