import * as constants from '../constants/accountConstants';

export const initialState = {
  additionalCost: [],
  additionalCostWithPagination: [],
  interpreterTransactions: [],
  interpreterTransactionWithOutPagination: [],
  pagination: {},
  loading: false,
  downloadLoading: false,
};

const accountReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_ALL_ADDITIONAL_COST:
      return {
        ...state,
        additionalCost: [],
        loading: true,
      };
    case constants.GET_ALL_ADDITIONAL_COST_SUCCESS:
      return {
        ...state,
        additionalCost: action.payload.results,
        pagination: action.payload.page_info,
        loading: false,
      };
    case constants.GET_ADDITIONAL_COST_WITH_OUT_PAGINATION_SUCCESS:
      return {
        ...state,
        additionalCostWithPagination: action.payload,
        downloadLoading: false,
      };
    case constants.GET_ADDITIONAL_COST_WITH_OUT_PAGINATION_ERROR:
      return {
        ...state,
        additionalCostWithPagination: [],
      };
    case constants.GET_ADDITIONAL_COST_WITH_OUT_PAGINATION:
      return {
        ...state,
        additionalCostWithPagination: [],
        downloadLoading: true,
      };
    case constants.GET_ALL_ADDITIONAL_COST_ERROR:
      return {
        ...state,
        additionalCost: [],
      };
    case constants.GET_ALL_INTERPRETERS_TRANSACTIONS:
      return {
        ...state,
        interpreterTransactions: [],
        loading: true,
      };
    case constants.GET_ALL_INTERPRETERS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        interpreterTransactions: action.payload.transaction_histories,
        pagination: action.payload.meta.pagy_info,
        loading: false,
      };
    case constants.GET_ALL_INTERPRETERS_TRANSACTIONS_ERROR:
      return {
        ...state,
        interpreterTransactions: [],
      };
    case constants.GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION_SUCCESS:
      return {
        ...state,
        interpreterTransactionWithOutPagination: action.payload,
        downloadLoading: false,
      };
    case constants.GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION_ERROR:
      return {
        ...state,
        interpreterTransactionWithOutPagination: [],
      };
    case constants.GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION:
      return {
        ...state,
        interpreterTransactionWithOutPagination: [],
        downloadLoading: true,
      };
    default:
      return state;
  }
};

export default accountReducer;
