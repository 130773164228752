import { EventPlatformVariant } from 'domains/shared/types/eventPlatformVariant';

export const routes = {
  // interpreter routes
  interpreterMeetingDetails: '/meetings/:id',
  signupSteps: '/edit-profile/:id',
  Earning: '/earning',
  onCallRequestDetails: '/oncall-requests/:id',
  withdrawalRequest: '/withdrawal-request',
  billing: '/billing',
  documentSuccess: '/document-success/:document_id',
  userDocuments: '/documents/:userId',
  publicAccess: '/public-access',

  // Independent Routes
  home: '/',
  selectRole: '/roles',
  postLogin: '/post-login',
  client: '/client',

  // reviewer routes
  bookingRequests: '/booking-requests',
  reviewerMeetings: '/reviewer_meetings',
  bookingRequestDetails: '/booking-request-details/:uuid',
  interpreters: '/interpreters',
  bookingShift: '/shift-plan/new',
  bookingShiftEdit: '/shift-plan/:id',
  bookingShiftList: '/shift-plan/list',
  bookingShiftCall: '/shift-plan/:id/call',

  // Admin Routes
  interpreterRates: '/interpreter-rates',
  users: '/users',
  userProfile: '/user/:id',
  languages: '/languages',
  report_exract: '/reports',

  // Routes that can be accessed by reviewer and interpreter both
  notifications: '/notifications',
  interpreterProfile: '/interpreter-profile',
  interpreterMeetings: '/meetings',
  signedDocument: '/signed-document/:document_id',

  // account Routes
  accounts: '/accounts',

  // client Routes
  meetings: '/client/meetings',
  meeting: '/client/meetings/:meetingId',
  singleZoomMeeting: '/client/meetings/',
  authorizeZoom: '/zoom/authorize',
  clientAuthorizeZoom: '/client/zoom/authorize',
  createMeeting: '/client/meetings/new',
  createWebinar: '/client/webinars/new',
  login: '/login',
  clientWallet: '/client/wallet',
  signup: '/signup',
  bundles: '/bundle-purchase',

  // client AI routes
  kudoAiPostLogin: '/ai-free-trial/post-login',
  aiCreateBookingPage: '/client/ai-free-trial',
  aiHomePage: '/client/home',
  aiConfirmBooking: '/client/ai-free-trial/confirm-booking',
  aiBookedPage: '/client/ai-free-trial/payment-completed',
  aiSubscriptionPlansOld: '/subscription-plans',
  aiSubscriptionPlans: '/bundle-plans',
  aiPaymentSuccess: '/ai/payment-success',
  aiBundlePayment: '/bundle-payment',
  aiBundlePaymentSuccess: '/bundle-payment-success',

  // self serve flow routes
  selfServeFlowLandingPage: (variant: EventPlatformVariant) => `/${variant}`,
  selfServeFlowAddMeetingDetailsPage: (variant: EventPlatformVariant) => `/${variant}/add-details`,
  selfServeFlowAuthUser: (variant: EventPlatformVariant) => `/${variant}/auth-user`,
  selfServeFlowAuthZoom: (variant: EventPlatformVariant) => `/${variant}/auth-zoom`,
  selfServeFlowPaymentPage: (variant: EventPlatformVariant) => `/${variant}/payment`,
  selfServeFlowBookedPage: (variant: EventPlatformVariant) => `/${variant}/payment-completed`,
  selfServeFlowWidget: (variant: EventPlatformVariant) => `/${variant}/widget`,
  selfServeZoomWebinarsFlowZoomSubscriptionCheck: '/zoom-webinars/subscription-check',
  selfServeZoomBasicFlowAfterWidgetPage: `/${EventPlatformVariant.ZOOM_MEETING}/storing-info`,

  // replay flow routes
  replayWidget: '/replay',
  replayAfterBookingFormWidgetPage: '/replay/storing-info',
  replayAddDetails: '/replay/add-details',
  replayPayment: '/replay/payment',
  replayPaymentProcessing: '/replay/payment-processing',
  replayPaymentCompleted: '/replay/payment-completed',
  replayAuthUser: '/replay/auth-user',
} as const;
