import Cookies from 'js-cookie';
import moment from 'moment';
import { createAction } from 'redux-actions';

import { TOGGLE_SIGN_DOCUMENT_MODAL } from 'constants/authConstants';
import {
  GET_BOOKING_REQUEST_INTERPRETERS,
  GET_BOOKING_REQUEST_INTERPRETERS_ERROR,
  GET_BOOKING_REQUEST_INTERPRETERS_SUCCESS,
} from 'constants/bookingRequestConstants';
import * as constants from 'constants/interpreterConstants';
import { USER_ROLE_COOKIE_NAME } from 'domains/shared/constants/cookie';
import { prepareResponsePayload } from 'helpers/CalendarHelper';
import { parseResponseErrors } from 'helpers/notificationHelper';
import { NotificationType, showNotification } from 'helpers/showNotification';
import * as bookingRequestService from 'services/bookingRequestService';
import * as interpreterService from 'services/InterpreterService';
import { InterpreterStatus } from 'shared/types/interpreterStatus';

export const GetBookingInterpreters = createAction(GET_BOOKING_REQUEST_INTERPRETERS);
export const GetBookingInterpretersSuccess = createAction(GET_BOOKING_REQUEST_INTERPRETERS_SUCCESS);
export const GetBookingInterpretersError = createAction(GET_BOOKING_REQUEST_INTERPRETERS_ERROR);

export const GetInterpreterProfile = createAction(constants.GET_INTERPRETER_PROFILE);
export const GetInterpreterProfileSuccess = createAction(constants.GET_INTERPRETER_PROFILE_SUCCESS);
export const GetInterpreterProfileError = createAction(constants.GET_INTERPRETER_PROFILE_ERROR);
export const GetInterpreterDetail = createAction(constants.GET_INTERPRETER_DETAILS);
export const GetInterpreterDetailSuccess = createAction(constants.GET_INTERPRETER_DETAILS_SUCCESS);
export const GetInterpreterDetailError = createAction(constants.GET_INTERPRETER_DETAILS_ERROR);
export const GetEditInterpreterDetailSuccess = createAction(constants.EDIT_INTERPRETER_DETAIL_SUCCESS);
export const SetAvailabilitySlotsSuccess = createAction(constants.SET_AVAILABILITY_SLOTS_SUCCESS);
export const AddAvailabilitySlotsSuccess = createAction(constants.ADD_AVAILABILITY_SLOTS_SUCCESS);
export const GetInterpreterStatisticsSuccess = createAction(constants.GET_INTERPRETER_STATISTICS_SUCCESS);
export const GetInterpreterByEmailSuccess = createAction(constants.GET_INTERPRETER_BY_EMAIL_SUCCESS);
export const CreateBookedInterpreterSuccess = createAction(constants.CREATE_BOOKED_INTERPRETER_SUCCESS);
export const GetEventSlotsError = createAction(constants.GET_EVENT_SLOTS_ERROR);
export const RemoveBulkBookedInterpretersSuccess = createAction(constants.REMOVE_BULK_BOOKED_INTERPRETERS_SUCCESS);
export const GetInterpreterAcceptedMeetigsSuccess = createAction(constants.GET_INTERPRETER_ACCEPTED_MEETINGS_SUCCESS);
export const GetInterpreterEarningsSuccess = createAction(constants.GET_INTERPRETER_EARNINGS_SUCCESS);
export const GetInterpretersFilterSuccess = createAction(constants.GET_INTERPRETERS_FILTER_SUCCESS);
export const GetGenericAvailibilitySlots = createAction(constants.GET_GENERIC_AVAILIBILITY_SLOTS);
export const GetGenericAvailibilitySlotsError = createAction(constants.GET_GENERIC_AVAILIBILITY_SLOTS_ERROR);
export const SaveGenericAvailibilitySlots = createAction(constants.SAVE_GENERIC_AVAILIBILITY_SLOTS);
export const DeleteGenericSlots = createAction(constants.DELETE_GENERIC_SLOTS);
export const GetAvailabilitySlotsError = createAction(constants.GET_AVAILIBILITY_SLOTS_ERROR);
export const ClearShiftPlans = createAction(constants.CLEAR_SHIFT_PLANS);
export const ToggleSignDocumentModal = createAction(TOGGLE_SIGN_DOCUMENT_MODAL);
export const SetInterpreterFilterInitialState = createAction(constants.SET_INTERPRETERS_FILTER_INITIAL_STATE);
export const SetLoading = createAction(constants.SET_LOADING);
export const GetWalletBalance = createAction(constants.GET_WALLET_BALANCE);
export const UpdateWalletBalance = createAction(constants.UPDATE_WALLET_BALANCE);
export const SkipWalkThroughScreen = createAction(constants.SKIP_WALK_THROUGH_SCREENS);
export const RemoveBookingInterpreter = createAction(constants.REMOVE_BOOKING_INTERPRETER);
export const BulkUpdateInterpreters = createAction(constants.BULK_UPDATE_INTERPRETERS);
export const SetLoadingInterpreterProfile = createAction(constants.SET_LOADING_INTERPRETER_PROFILE);
export const SetCalendarData = createAction(constants.SET_CALENDAR_DATA);
export const ChangeInterpreterProfilePicture = createAction(constants.CHANGE_INTERPRETER_PROFILE_PICTURE);
export const GetAllInterpreters = createAction(constants.GET_ALL_INTERPRETERS);
export const GetAllInterpretersSuccess = createAction(constants.GET_ALL_INTERPRETERS_SUCCESS);
export const GetAllInterpretersError = createAction(constants.GET_ALL_INTERPRETERS_ERROR);

export function interpreterProfile(id, role, handlePrivateAccess) {
  return (dispatch) => {
    dispatch(GetInterpreterProfile());
    dispatch(SetLoadingInterpreterProfile(true));

    return interpreterService
      .interpreterProfile(id)
      .then((response) => {
        dispatch(GetInterpreterProfileSuccess(response.data));
        dispatch(GetInterpreterDetail());
        dispatch(SetLoadingInterpreterProfile(false));

        return interpreterService
          .interpreterProfileDetails(response.data.uuid)
          .then((resp) => {
            if (!resp.data.public_access && handlePrivateAccess && role === 'reviewer') {
              handlePrivateAccess();
            } else {
              dispatch(GetInterpreterDetailSuccess(resp.data));
              dispatch(SetLoadingInterpreterProfile(false));
            }
          })
          .catch((error) => {
            dispatch(GetInterpreterDetailError(error));
            dispatch(SetLoadingInterpreterProfile(false));
          });
      })
      .catch((error) => {
        dispatch(GetInterpreterProfileError(error));
        dispatch(SetLoadingInterpreterProfile(false));
      });
  };
}

export function getInterpreterByUserId(id, role, redirectToDashBoard) {
  return (dispatch) => {
    dispatch(GetInterpreterProfile());
    Cookies.set(USER_ROLE_COOKIE_NAME, role);

    if (['reviewer', 'interpreter'].includes(role)) {
      return interpreterService
        .getInterpreterByUserId(id)
        .then((response) => {
          dispatch(GetInterpreterProfileSuccess(response.data));
          dispatch(GetInterpreterDetail());
          dispatch(SetLoadingInterpreterProfile(false));

          return interpreterService
            .interpreterProfileDetails(response.data.uuid)
            .then((resp) => {
              dispatch(GetInterpreterDetailSuccess(resp.data));
              redirectToDashBoard(role, id);
            })
            .catch((error) => dispatch(GetInterpreterDetailError(error)));
        })
        .catch((error) => {
          showNotification({ message: parseResponseErrors(error) });
          dispatch(GetInterpreterProfileError(error));
        });
    }

    return redirectToDashBoard(role, id);
  };
}

export function saveGenericData({ id, addedData, deletedData, makeCalendarDataFetchRequest }) {
  return async (dispatch) => {
    dispatch(GetGenericAvailibilitySlotsError());
    dispatch(SetLoading(true));
    const arr = [];

    if (deletedData.length) arr.push(interpreterService.deleteGenericSlots(id, deletedData));

    if (addedData.length) arr.push(interpreterService.saveGenericAvailibilitySlots(id, addedData));

    await Promise.allSettled(arr);
    makeCalendarDataFetchRequest();
  };
}

export function interpreterProfileDetails(id) {
  return (dispatch) => {
    dispatch(GetInterpreterDetail());

    return interpreterService
      .interpreterProfileDetails(id)
      .then((response) => dispatch(GetInterpreterDetailSuccess(response.data)))
      .catch((error) => dispatch(GetInterpreterDetailError(error)));
  };
}

export function setAvailabilitySlots(slots, id) {
  return (dispatch) => {
    dispatch(SetLoading(true));

    return interpreterService
      .setAvailabilitySlots(slots, id)
      .then((response) => dispatch(AddAvailabilitySlotsSuccess(response.data)))
      .catch(() => SetLoading(false));
  };
}

export function availabilitySlotsError() {
  return (dispatch) => dispatch(GetAvailabilitySlotsError());
}

export function getGenericAvailibilitySlots(id, setEmpty = false) {
  return (dispatch) => {
    dispatch(GetGenericAvailibilitySlotsError());
    dispatch(SetLoading(true));

    return interpreterService
      .getGenericAvailibilitySlots(id)
      .then((response) => dispatch(GetGenericAvailibilitySlots(setEmpty ? [{}] : response.data)))
      .catch(() => {
        dispatch(GetGenericAvailibilitySlotsError());
        dispatch(SetLoading(false));
      });
  };
}

export function deleteAvailabilitySlots(slots, id) {
  return (dispatch) => {
    dispatch(SetLoading(true));

    return interpreterService
      .deleteAvailabilitySlots(slots, id)
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(SetAvailabilitySlotsSuccess(response.data));
        }

        dispatch(SetLoading(false));
      })
      .catch(() => dispatch(SetLoading(false)));
  };
}

export function prepareCalendarData({
  id,
  slotPayload,
  interpreterId,
  start_date,
  end_date,
  eventsPayload,
  exceptionPayload,
  shouldSetEmpty,
}) {
  return async (dispatch) => {
    dispatch(GetAvailabilitySlotsError());
    dispatch(GetGenericAvailibilitySlotsError());
    dispatch(ClearShiftPlans());
    dispatch(SetLoading(true));
    const shiftPlanStartDate = moment(start_date).subtract(1, 'day').format();

    await Promise.allSettled([
      interpreterService.getAvailabilitySlots(id, slotPayload),
      interpreterService.getGenericAvailibilitySlots(id),
      interpreterService.getShiftPlans({
        id: interpreterId,
        startDate: shiftPlanStartDate,
        endDate: end_date,
        keyName: 'start_date',
      }),
      interpreterService.getEventSlots(id, eventsPayload),
      interpreterService.getAvailabilitySlots(id, exceptionPayload),
      interpreterService.getOnCallAvailabilitySlots(id, start_date, end_date),
    ])
      .then((responses) => {
        const payload = prepareResponsePayload(responses, shouldSetEmpty);

        dispatch(SetCalendarData(payload));
        dispatch(SetLoading(false));
      })
      .catch(() => {
        dispatch(GetAvailabilitySlotsError());
        dispatch(SetLoading(false));
      });
  };
}

export function eventSlotsError() {
  return (dispatch) => dispatch(GetEventSlotsError());
}

export function editInterpreterProfile(id, data) {
  return (dispatch) =>
    interpreterService
      .editProfile(id, data)
      .then((response) => {
        dispatch(GetInterpreterProfileSuccess(response.data));
        dispatch(SetLoadingInterpreterProfile(false));
      })
      .catch(() => showNotification({ message: 'Something went wrong' }));
}

export function sendTextMessage(phone) {
  return () =>
    interpreterService
      .sendTextMessage(phone)
      .then((response) => {
        showNotification({ message: response.data.message, type: NotificationType.SUCCESS });
      })
      .catch(() => showNotification({ message: 'Something went wrong' }));
}

export function saveSignedDocument(id, data, callback) {
  return (dispatch) =>
    interpreterService
      .editProfile(id, data)
      .then((response) => {
        dispatch(GetInterpreterProfileSuccess(response.data));
        dispatch(SetLoadingInterpreterProfile(false));
        callback();
      })
      .catch(() => showNotification({ message: 'Something went wrong.' }));
}

export function toggleSignDocumentModal(value) {
  return (dispatch) => dispatch(ToggleSignDocumentModal(value));
}

export function editInterpreterDetail(interpreterId, data, callback) {
  return (dispatch) =>
    interpreterService
      .editDetails(interpreterId, data)
      .then((response) => {
        dispatch(GetInterpreterDetailSuccess(response.data));

        if (callback) callback();
      })
      .catch(() => showNotification({ message: 'Something went wrong' }));
}

export function deleteOldOneAndCreateNew({
  dispatch,
  response,
  payload,
  bookingInterpreterId,
  combinationIndex,
  setIsSearching,
}) {
  let action;

  dispatch(GetInterpreterByEmailSuccess(response.data));

  return interpreterService
    .createBookedInterpreter({ ...payload, interpreter_id: response.data[0].id })
    .then((createResponser) => {
      showNotification({ message: 'Interpreter Successfully Added', type: NotificationType.SUCCESS });

      if (bookingInterpreterId) {
        if (setIsSearching) {
          action = interpreterService
            .removeBookedInterpreter(bookingInterpreterId)
            .then(() => {
              dispatch(
                CreateBookedInterpreterSuccess({
                  created: createResponser.data,
                  combinationIndex,
                  removedId: bookingInterpreterId,
                })
              );
            })
            .catch((error) => {
              showNotification({ message: parseResponseErrors(error) });
            })
            .finally(() => {
              setIsSearching(false);
            });
        } else {
          action = dispatch(
            CreateBookedInterpreterSuccess({
              created: createResponser.data,
              combinationIndex,
              removedId: bookingInterpreterId,
            })
          );
        }

        return action;
      }

      dispatch(GetBookingInterpreters());

      return bookingRequestService
        .bookingRequestInterpretersService(combinationIndex)
        .then((res) => {
          dispatch(GetBookingInterpretersSuccess(res.data));
        })
        .catch((error) => showNotification({ message: parseResponseErrors(error) }));
    })
    .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function replaceABookingInterpreter(payload, bookingInterpreterId, combinationIndex) {
  return (dispatch) =>
    interpreterService
      .createBookedInterpreter(payload)
      .then((createResponser) => {
        interpreterService
          .removeBookedInterpreter(bookingInterpreterId)
          .then(() => {
            dispatch(
              CreateBookedInterpreterSuccess({
                created: createResponser.data,
                combinationIndex,
                removedId: bookingInterpreterId,
              })
            );
            showNotification({ message: 'Interpreter successfully replaced.', type: NotificationType.SUCCESS });
          })
          .catch((error) => showNotification({ message: parseResponseErrors(error) }));
      })
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function removeBookingInterpreter(id, combinationIndex) {
  return (dispatch) =>
    interpreterService
      .removeBookedInterpreter(id)
      .then(() => {
        dispatch(RemoveBookingInterpreter({ combinationIndex, removedId: id }));
        showNotification({ message: 'Interpreter successfully removed.', type: NotificationType.SUCCESS });
      })
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function getInterpreterByEmail({ email, payload, bookingInterpreterId, combinationIndex }) {
  return (dispatch) =>
    interpreterService
      .getInterpreterByEmail(email)
      .then((response) => {
        if (response.data.length > 0) {
          deleteOldOneAndCreateNew({ dispatch, response, payload, bookingInterpreterId, combinationIndex });
        } else showNotification({ message: 'No record found here!' });
      })
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function fetchInterpreterByRepeatSearch({
  payload,
  searchPayload,
  bookingInterpreterId,
  combinationIndex,
  setIsSearching,
}) {
  return (dispatch) =>
    interpreterService
      .fetchInterpreterByRepeatSearch(searchPayload)
      .then((response) => {
        if (response.data.length > 0) {
          deleteOldOneAndCreateNew({
            dispatch,
            response,
            payload,
            bookingInterpreterId,
            combinationIndex,
            setIsSearching,
          });
        } else {
          if (setIsSearching) setIsSearching(false);

          showNotification({ message: 'No record found!' });
        }
      })
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function getInterpreterStatistics(id, payload) {
  return (dispatch) => {
    if (payload.only_meetings_accepted) {
      return interpreterService
        .getInterpreterStatistics(id, payload)
        .then((response) => dispatch(GetInterpreterAcceptedMeetigsSuccess(response.data)));
    }

    if (payload.only_earnings) {
      return interpreterService
        .getInterpreterStatistics(id, payload)
        .then((response) => dispatch(GetInterpreterEarningsSuccess(response.data)));
    }

    return interpreterService
      .getInterpreterStatistics(id, payload)
      .then((response) => dispatch(GetInterpreterStatisticsSuccess(response.data)));
  };
}

export function createBulkBookingInterpreter(payload, redirectToBookingRequest) {
  return () =>
    interpreterService
      .createBulkBookingInterpreter(payload)
      .then((res) => {
        if (res.data.map((d) => d.status).includes(InterpreterStatus.NOT_AVAILABLE)) {
          showNotification({ message: 'Interpreter(s) unavailable', type: NotificationType.WARNING });
          redirectToBookingRequest();
        } else {
          showNotification({ message: 'Interpreter(s) Successfully Invited', type: NotificationType.SUCCESS });
          redirectToBookingRequest();
        }
      })
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function removeBulkBookingInterpreters(ids) {
  return (dispatch) =>
    interpreterService
      .removeBulkBookingInterpreters(ids)
      .then((response) => dispatch(RemoveBulkBookedInterpretersSuccess(response.data)))
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export function fetchFilteredInterpreterList(payload, setSubmitting) {
  return (dispatch) => {
    dispatch(SetInterpreterFilterInitialState(true));

    return interpreterService
      .fetchInterpreterByRepeatSearch(payload)
      .then((response) => {
        dispatch(GetInterpretersFilterSuccess(response.data));

        if (setSubmitting) setSubmitting(false);
      })
      .catch((error) => {
        dispatch(SetInterpreterFilterInitialState(true));
        showNotification({ message: parseResponseErrors(error) });

        if (setSubmitting) setSubmitting(false);
      });
  };
}

export function getWalletBalance(uuid) {
  return (dispatch) =>
    interpreterService.getWalletBalance(uuid).then((response) => dispatch(GetWalletBalance(response.data)));
}

export function updateAdjustment({ reviewerId, uuid, comment, type, amount }) {
  return (dispatch) =>
    interpreterService.updateAdjustment({ reviewerId, uuid, comment, type, amount }).then(() => {
      interpreterService.getWalletBalance(uuid).then((response) => dispatch(GetWalletBalance(response.data)));
    });
}

export function updateBalance(amount) {
  return (dispatch) => dispatch(UpdateWalletBalance({ amount }));
}

export function bulkUpdateInterpreters(payload, setSelectedInterpreters) {
  return (dispatch) =>
    interpreterService
      .bulkUpdateInterpreters(payload)
      .then((response) => {
        dispatch(BulkUpdateInterpreters(response.data));
        setSelectedInterpreters([]);
      })
      .catch(() => showNotification({ message: 'Something went wrong!' }));
}

export function getAllInterpreters(payload, setIsDataAvailable) {
  return (dispatch) => {
    dispatch(GetAllInterpreters());

    return interpreterService
      .fetchInterpreterByRepeatSearch({ ...payload, x_no_signed_docs_filter: true })
      .then((response) => {
        dispatch(GetAllInterpretersSuccess(response.data));
        setIsDataAvailable(true);
      })
      .catch(() => dispatch(GetAllInterpretersError()));
  };
}
