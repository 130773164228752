import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ZeroStateFlowSlice } from 'domains/client/types/zeroStateFlowSlice';
import { Platform } from 'domains/shared/types/platform';

export const initialState: ZeroStateFlowSlice = {
  isInZeroStateFlow: false,
  platform: '',
  hasStartedZoomAuth: false,
  zoomAuthStartTimestamp: 0,
};

export const zeroStateFlowSlice = createSlice({
  name: 'zeroStateFlow',
  initialState,
  reducers: {
    startZeroStateFlow: (state) => {
      state.isInZeroStateFlow = true;
    },
    exitZeroStateFlow: (state) => {
      state.isInZeroStateFlow = false;
    },
    setZeroStateFlowPlatform: (state, { payload }: PayloadAction<Platform>) => {
      state.platform = payload;
    },
    startZeroStateZoomAuth: (state) => {
      state.hasStartedZoomAuth = true;
      state.zoomAuthStartTimestamp = Date.now();
    },
    exitZeroStateZoomAuth: (state) => {
      state.hasStartedZoomAuth = false;
      state.zoomAuthStartTimestamp = 0;
    },
  },
});

export const {
  startZeroStateFlow: startZeroStateFlowAction,
  exitZeroStateFlow: exitZeroStateFlowAction,
  setZeroStateFlowPlatform: setZeroStateFlowPlatformAction,
  startZeroStateZoomAuth: startZeroStateZoomAuthAction,
  exitZeroStateZoomAuth: exitZeroStateZoomAuthAction,
} = zeroStateFlowSlice.actions;
export const { reducer: zeroStateFlowReducer } = zeroStateFlowSlice;
