/* eslint-disable max-len */
import * as constants from '../constants/interpreterConstants';
import { adjustInterpreterSlots, adjustWeeklyExceptions } from '../helpers/CalendarHelper';
import { updateBulkInterpretersActiveStatus } from '../helpers/interpreterHelper';

export const initialState = {
  interpreterProfile: {},
  interpreterDetails: {},
  interpreterStatistics: {},
  acceptedMeetings: {},
  interpreterBookedSlots: [],
  eventSlots: [],
  bookingInterpreter: {},
  interpreters: [],
  pagination: {},
  genericSlots: [],
  emptyGenericSlots: false,
  shiftPlans: [],
  shiftPlanLoading: false,
  exceptionSlots: [],
  walletBalance: {},
  loading: false,
  skippedWalkthrough: false,
  isLoadingProfile: false,
  onCallSlots: [],
  interpreterEarnings: null,
  allInterpreters: [],
  downloadLoading: false,
};

const interpreterReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.CHANGE_INTERPRETER_PROFILE_PICTURE:
      return {
        ...state,
        interpreterProfile: {
          ...state.interpreterProfile,
          profile_photo: action.payload,
        },
      };
    case constants.SET_INTERPRETERS_FILTER_INITIAL_STATE:
      return {
        ...state,
        loading: action.payload,
        interpreters: [],
      };
    case constants.GET_INTERPRETER_PROFILE:
      return {
        ...state,
        interpreterProfile: {},
      };
    case constants.GET_INTERPRETER_PROFILE_SUCCESS:
      return {
        ...state,
        interpreterProfile: action.payload,
      };
    case constants.GET_INTERPRETER_PROFILE_ERROR:
      return {
        ...state,
        interpreterProfile: {},
      };
    case constants.SET_LOADING_INTERPRETER_PROFILE:
      return {
        ...state,
        isLoadingProfile: action.payload,
      };
    case constants.GET_INTERPRETER_DETAILS:
      return {
        ...state,
        interpreterDetails: {},
      };
    case constants.GET_INTERPRETER_DETAILS_SUCCESS:
      return {
        ...state,
        interpreterDetails: action.payload,
      };
    case constants.GET_INTERPRETER_DETAILS_ERROR:
      return {
        ...state,
        interpreterDetails: {},
      };
    case constants.GET_EVENT_SLOTS_ERROR:
      return {
        ...state,
        eventSlots: [],
        loading: false,
      };
    case constants.GET_ON_CALL_AVAILABILITY_SLOTS_ERROR:
      return {
        ...state,
        onCallSlots: [],
      };
    case constants.GET_AVAILIBILITY_SLOTS_ERROR:
      return {
        ...state,
        interpreterBookedSlots: [],
      };
    case constants.ADD_AVAILABILITY_SLOTS_SUCCESS:
      return {
        ...state,
        interpreterBookedSlots: adjustInterpreterSlots(state.interpreterBookedSlots, action.payload),
        exceptionSlots: adjustWeeklyExceptions(state.exceptionSlots, action.payload),
        loading: false,
      };
    case constants.SET_AVAILABILITY_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        exceptionSlots: [...state.exceptionSlots, ...action.payload],
        interpreterBookedSlots: state.interpreterBookedSlots.filter(
          (item) => !action.payload.find((other) => item.id === other.id)
        ),
      };
    case constants.GET_INTERPRETER_STATISTICS_SUCCESS:
      return {
        ...state,
        interpreterStatistics: action.payload,
        acceptedMeetings: action.payload.accepted_meetings,
        interpreterEarnings: action.payload.interpreter_earnings,
      };
    case constants.GET_INTERPRETER_ACCEPTED_MEETINGS_SUCCESS:
      return {
        ...state,
        acceptedMeetings: action.payload.accepted_meetings,
      };
    case constants.GET_INTERPRETER_EARNINGS_SUCCESS:
      return {
        ...state,
        interpreterEarnings: action.payload.interpreter_earnings,
      };
    case constants.GET_INTERPRETER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        bookingInterpreter: action.payload[0],
      };
    case constants.GET_INTERPRETERS_BY_REPEAT_SEARCH_SUCCESS:
      return {
        ...state,
        bookingInterpreter: action.payload,
      };
    case constants.GET_INTERPRETER_BY_EMAIL_ERROR:
      return {
        ...state,
        bookingInterpreter: {},
      };
    case constants.GET_INTERPRETERS_FILTER_SUCCESS:
      return {
        ...state,
        interpreters: action.payload[1],
        pagination: action.payload[0],
        loading: false,
      };
    case constants.GET_GENERIC_AVAILIBILITY_SLOTS:
      return {
        ...state,
        genericSlots: action.payload,
        emptyGenericSlots: action.payload.length === 0,
        loading: false,
      };
    case constants.GET_GENERIC_AVAILIBILITY_SLOTS_ERROR:
      return {
        ...state,
        genericSlots: [],
        exceptionSlots: [],
      };
    case constants.CLEAR_SHIFT_PLANS:
      return {
        ...state,
        shiftPlans: [],
        shiftPlanLoading: false,
        loading: false,
      };
    case constants.GET_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.payload,
      };

    case constants.UPDATE_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.payload,
      };
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case constants.SKIP_WALK_THROUGH_SCREENS:
      return {
        ...state,
        skippedWalkthrough: action.payload,
      };
    case constants.BULK_UPDATE_INTERPRETERS:
      return {
        ...state,
        interpreters: updateBulkInterpretersActiveStatus(state.interpreters, action.payload),
      };
    case constants.SET_CALENDAR_DATA:
      return {
        ...state,
        interpreterBookedSlots: action.payload.interpreterBookedSlots,
        genericSlots: action.payload.genericSlots,
        emptyGenericSlots: action.payload.genericSlots.length === 0,
        shiftPlans: action.payload.shiftPlans,
        eventSlots: action.payload.eventSlots,
        exceptionSlots: action.payload.exceptionSlots,
        onCallSlots: action.payload.onCallSlots,
      };
    case constants.GET_ALL_INTERPRETERS:
      return {
        ...state,
        downloadLoading: true,
      };
    case constants.GET_ALL_INTERPRETERS_SUCCESS:
      return {
        ...state,
        allInterpreters: action.payload,
        downloadLoading: false,
      };
    case constants.GET_ALL_INTERPRETERS_ERROR:
      return {
        ...state,
        allInterpreters: [],
        downloadLoading: false,
      };
    default:
      return state;
  }
};

export default interpreterReducer;
