import Qs from 'qs';

import { InterpreterStatus } from 'shared/types/interpreterStatus';

import baseService, { getNestedParamsRequest } from './baseService';

export function getBookingRequestById(id, params = {}) {
  return baseService.get(`/bookings/${id}`, { params });
}

export function getAllBookingRequests(payload) {
  return baseService.get('/bookings', { params: payload });
}

export function GetAllUserBookingRequests(payload) {
  return baseService.get('/bookings', { params: payload });
}

export function bookingRequestInterpretersService(id) {
  return baseService.get(`/booking_interpreters/?booking_id=${id}&include=interpreter`);
}

export function updateBookingRequest(uuid, payload) {
  return baseService.put(`/bookings/${uuid}`, payload);
}

export function getIssuesList() {
  return baseService.get('issue_types');
}

export function fetchOnCallShiftPlans(interpreterId) {
  return baseService.get('/shift_plans', {
    params: {
      interpreter_id: interpreterId,
      shift_interpreter_status: [InterpreterStatus.NEW_OFFER],
      include: 'shift_interpreters',
    },
  });
}

export function updateBookingInterpreterStatus(id, status) {
  return baseService.put(`/booking_interpreters/${id}`, { status });
}

export function reportIssue({ issues, description, sourceId, interpreterId, onCallShiftPlanId, category }) {
  const body = {
    booking_id: sourceId,
    description,
    issue_type_ids: issues,
    interpreter_id: interpreterId,
    shift_plan_id: onCallShiftPlanId,
    category,
  };

  return baseService.post('/user_issues', body);
}

export function getNewOfferMeetings(interpreterId, page, perPage) {
  return baseService.get('/bookings', {
    params: {
      interpreter_id: interpreterId,
      booking_interpreter_status: InterpreterStatus.NEW_OFFER,
      page,
      per_page: perPage,
    },
  });
}

export function getPendingMeetings(interpreterId, page, perPage) {
  return baseService.get('/bookings', {
    params: {
      interpreter_id: interpreterId,
      pending_reviews: true,
      enable_assignment: true,
      include: 'booking_interpreters,user_issues,overtime_claims',
      page,
      per_page: perPage,
    },
  });
}

export function getPreviousMeetings(interpreterId, page, perPage) {
  return baseService.get('/bookings', {
    params: {
      interpreter_id: interpreterId,
      booking_interpreter_status: InterpreterStatus.COMPLETED,
      include: 'booking_interpreters,user_issues,overtime_claims',
      page,
      per_page: perPage,
    },
  });
}

export function getUpcomingMeetings(interpreterId, page, perPage) {
  return baseService.get('/bookings', {
    params: {
      interpreter_id: interpreterId,
      booking_interpreter_status: InterpreterStatus.ACCEPTED,
      upcoming_bookings: true,
      page,
      per_page: perPage,
    },
  });
}

export function getBookingIssues(uuid) {
  const body = {
    params: {
      booking_id: uuid,
    },
  };

  return baseService.get('/user_issues', body);
}

export function addIssueComment(issueId, comment, interpreterAction) {
  const body = {
    status: 'completed',
    comment,
    interpreter_action: interpreterAction,
  };

  return baseService.put(`/user_issues/${issueId}`, body);
}

export function getMeetingFee(startsAt, endsAt, userId) {
  return baseService.get('/calculator/earning', {
    params: {
      source_type: 'booking',
      starts_at: startsAt,
      ends_at: endsAt,
      user_id: userId,
    },
  });
}

export function getIssues(sourceId, interpreterId, onCallShiftPlanId) {
  let body;

  if (sourceId) {
    body = {
      interpreter_id: interpreterId,
      booking_id: sourceId,
    };
  } else if (onCallShiftPlanId) {
    body = {
      interpreter_id: interpreterId,
      shift_plan_id: onCallShiftPlanId,
    };
  }

  return baseService.get('/user_issues', {
    params: body,
  });
}

export function makeEarning(bookingId, interpreterId) {
  const body = {
    earnings: {
      booking_id: bookingId,
    },
  };

  return baseService.post(`/interpreters/${interpreterId}/earnings`, body);
}

export function getReviewerMeetings(payload) {
  return baseService.get('/bookings', { params: payload });
}

export function claimOverTime(payload) {
  return baseService.post('/overtime_claims', payload);
}

export function getClaimedOvertime(payload) {
  return baseService.get('/overtime_claims', { params: payload });
}

export function getBookingOvertimeClaims(payload) {
  return baseService.get('/overtime_claims', { params: payload });
}

export function updateOvertimeClaim(id, payload) {
  return baseService.put(`/overtime_claims/${id}`, payload);
}

export function searchInterpreters(params) {
  params.public_profile = true;
  params.kudo_certification = {
    kudo_pro: true,
  };
  params.kudo_junior = true;
  params.kudo_trainee = true;

  return getNestedParamsRequest('/search/interpreters', params);
}

export function createNewBulkBookingInterpreter(payload) {
  return baseService.post('/bulk/booking_interpreters', { booking_interpreters: payload });
}

export function getRateCustomizationReasons() {
  return baseService.get('/rate_customization_reasons');
}

export function updateBookingInterpreter(id, payload) {
  return baseService.put(`/booking_interpreters/${id}`, payload);
}

export function getBookingRecordingsById(uuid) {
  return baseService.get(`/bookings/${uuid}/recordings`);
}

export function addAgnosticInterpreter(payload) {
  return baseService.post('/booking_in_house_interpreters', payload);
}

export function removeAgnosticInterpreter(booking_id, payload) {
  return baseService.delete(`/booking_in_house_interpreters/${booking_id}`, {
    params: payload,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: true }),
  });
}

export function bookingRequestInterpretersByLanguage(bookingId, languageCombinationIds, userId) {
  const queryParams = new URLSearchParams({
    booking_id: bookingId,
    include: 'interpreter',
    user_id: userId,
    boothmate: true,
  });
  const uniqueIds = new Set(languageCombinationIds);

  uniqueIds.forEach((id) => {
    queryParams.append('language_combination_ids[]', id);
  });
  const url = `/booking_interpreters?${queryParams.toString()}`;

  return baseService.get(url);
}
