import produce from 'immer';
import { difference, findIndex, groupBy, isEqual, mapValues, omit } from 'lodash-es';
import moment from 'moment';

import { statusConstants } from 'constants/bookingRequestConstants';
import { BookingRequestStatus } from 'constants/BookingRequestStatus';
import { MeetingStatus } from 'domains/shared/types/meetingStatus';
import { showNotification } from 'helpers/showNotification';
import { InterpreterStatus } from 'shared/types/interpreterStatus';
import { UserIssueStatus } from 'shared/types/userIssueStatus';

export const getIssuesString = (issuesList, issueIds) => {
  const items = issuesList?.filter((item) => issueIds.includes(item.id)).map((item) => item.name);

  return items.join(', ');
};

export const checkBookingStatus = (status) =>
  [statusConstants.new_request, statusConstants.declined, statusConstants.cancelled, statusConstants.expired].includes(
    status
  )
    ? 'pass-needed newrequest'
    : 'pass-needed';
// TODO: Is it needed?
const bookingStatusMap = {
  [BookingRequestStatus.NEW_REQUEST]: 'NEW REQUEST',
  [BookingRequestStatus.IN_PROGRESS]: 'OFFER(S) SENT',
  [BookingRequestStatus.CONFIRMED]: 'COMPLETED',
  [BookingRequestStatus.PENDING_REVIEW]: 'PENDING REVIEW',
  [BookingRequestStatus.COMPLETED]: 'COMPLETED',
  [BookingRequestStatus.DECLINED]: 'OFFER(S) DECLINED',
  [BookingRequestStatus.CANCELLED]: 'CANCELLED',
  [BookingRequestStatus.EXPIRED]: 'EXPIRED',
};

export const getHumanizedStatusName = (bookingRequestStatus) => bookingStatusMap[bookingRequestStatus] || '';

export const calculateDeclinedCount = (combinations) => {
  let sum = 0;

  combinations.forEach((combination) => {
    combination.booking_interpreters.forEach((bookingInter) => {
      if (bookingInter.status === InterpreterStatus.DECLINED) sum += 1;
    });
  });

  return sum;
};

export const getExcludedIds = (combinations) => {
  const ids = [];

  combinations.forEach((combination) => {
    combination.booking_interpreters.forEach((bookingInter) => {
      ids.push(bookingInter.interpreter_id);
    });
  });

  return ids;
};

export const getIssuesProcessedData = (data) => {
  const groupedData = mapValues(groupBy(data, 'interpreter_name'), (clist) =>
    clist.map((data) => omit(data, 'interpreter_name'))
  );
  const issueInterpreters = Object.keys(groupedData);
  const issueInterpreterIssues = Object.values(groupedData);
  const issueInterpreterCollapseStatus = [];

  issueInterpreters.forEach((interpreter) => {
    issueInterpreterCollapseStatus.push({ name: interpreter, status: false });
  });

  return [issueInterpreters, issueInterpreterIssues, issueInterpreterCollapseStatus];
};

export const checkPendingIssues = (issues) =>
  issues?.filter((issue) => issue.category === 'Issue' && issue.status !== UserIssueStatus.COMPLETED).length === 0;

export const cleanBookingInterpreters = (bookingInterpreters) => {
  const finalData = [];

  bookingInterpreters.forEach((bookingInter) => {
    const { language_combinations } = bookingInter;
    let present = false;
    let index = null;

    finalData.forEach((data, i) => {
      if (isEqual(language_combinations, data.language_combinations)) {
        present = true;
        index = i;
      }
    });

    if (present) {
      finalData[index].booking_interpreters.push({
        id: bookingInter.id,
        interpreter_name: `${bookingInter.interpreter.first_name} ${bookingInter.interpreter.last_name}`,
        status: bookingInter.status,
        interpreter_id: bookingInter.interpreter.id,
        user_id: bookingInter.interpreter.user_id,
        interpreter_email: bookingInter.interpreter.email,
        customized_price: bookingInter.interpretation_rate,
      });
    } else {
      finalData.push({
        language_combinations,
        booking_interpreters: [
          {
            id: bookingInter.id,
            interpreter_name: `${bookingInter.interpreter.first_name} ${bookingInter.interpreter.last_name}`,
            status: bookingInter.status,
            interpreter_id: bookingInter.interpreter.id,
            user_id: bookingInter.interpreter.user_id,
            interpreter_email: bookingInter.interpreter.email,
            customized_price: bookingInter.interpretation_rate,
          },
        ],
      });
    }
  });

  return finalData;
};

export const insertNewBookingInterpreter = (oldRecords, payload) => {
  const { created, combinationIndex, removedId } = payload;
  const index = findIndex(oldRecords[combinationIndex].booking_interpreters, { id: removedId });
  const newRecord = {
    id: created.id,
    interpreter_name: `${created.interpreter.first_name} ${created.interpreter.last_name}`,
    status: created.status,
    interpreter_id: created.interpreter.id,
    user_id: created.interpreter.user_id,
  };

  return produce(oldRecords, (draft) => {
    draft[combinationIndex].booking_interpreters.splice(index, 1, newRecord);
  });
};

export const removeBookingInterpreter = (oldRecords, payload) => {
  const { combinationIndex, removedId } = payload;
  const index = findIndex(oldRecords[combinationIndex].booking_interpreters, { id: removedId });

  return produce(oldRecords, (draft) => {
    draft[combinationIndex].booking_interpreters.splice(index, 1);
  });
};

export const getBookingInterpretersToInvite = (combinations, hours, minutes) => {
  const invite = [];

  combinations.forEach((combination) =>
    combination.booking_interpreters.forEach((bookingInter) => {
      if (bookingInter.status === InterpreterStatus.PENDING_INVITE) {
        invite.push({
          id: bookingInter.id,
          status: InterpreterStatus.NEW_OFFER,
          hours,
          minutes,
        });
      }
    })
  );

  return invite;
};

export const findMeetingInterpreter = (meetingInterpreters, interpreterId) => {
  const bookingInterpreter = meetingInterpreters?.find((item) => item?.interpreter?.id === interpreterId);

  return bookingInterpreter;
};

export const getAnyUnInvited = (combinations) => {
  let anyUninvited = false;

  combinations.forEach((combination) => {
    combination.booking_interpreters.forEach((bookingInter) => {
      if (bookingInter.status === statusConstants.pending_invite) {
        anyUninvited = true;
      }
    });
  });

  return anyUninvited;
};

export const isUpcomming = (interpreterStatus, startsAt, meetingStatus) =>
  interpreterStatus === InterpreterStatus.ACCEPTED &&
  moment(startsAt).isAfter(moment()) &&
  meetingStatus !== MeetingStatus.CANCELLED;

export const showIssuesAndClaims = (interpreterStatus, meetingStatus, isAccepted) =>
  meetingStatus !== MeetingStatus.CANCELLED && (interpreterStatus !== InterpreterStatus.NEW_OFFER || isAccepted);

export const isPendingReview = (interpreterStatus, endsAt) =>
  interpreterStatus === InterpreterStatus.ACCEPTED && moment(endsAt).isBefore(moment());

export const bookingInterpreterChecked = (status) =>
  ![statusConstants.declined, statusConstants.not_responded, statusConstants.not_available].includes(status);

export const bookingInterpreterStatusColor = (status) =>
  [
    statusConstants.declined,
    statusConstants.not_responded,
    statusConstants.not_available,
    statusConstants.paid,
  ].includes(status);

export const getIdsOfUncheckedInterpreters = (combination) =>
  combination.booking_interpreters
    .filter((b) =>
      [statusConstants.declined, statusConstants.not_responded, statusConstants.not_available].includes(b.status)
    )
    .map(({ id }) => id);

export const filterLanguageCombinations = (bookingRequest, bookingInterpretersList) => {
  const possibleLanguageCombinations = [];

  bookingRequest?.language_combinations?.forEach((combination) => {
    bookingRequest?.language_combinations?.forEach((obj) => {
      const sameLanguagesCombination =
        combination.to_language === obj.from_language && combination.from_language === obj.to_language;
      const isNotLanguageCombinationPresent = !possibleLanguageCombinations.some(
        (lang) =>
          (lang[0].id === combination.id || lang[0].id === obj.id) &&
          (lang[1].id === combination.id || lang[1].id === obj.id)
      );

      if (sameLanguagesCombination && isNotLanguageCombinationPresent) {
        possibleLanguageCombinations.push([combination, obj]);
      }
    });
  });
  const unmatchedLanguageCombinations = [];

  possibleLanguageCombinations.forEach((langComb) => {
    const ids = langComb.map((comb) => comb.id);
    let present = false;

    bookingInterpretersList.map((bi) => {
      if (
        difference(
          ids,
          bi.language_combinations.map((com) => com.id)
        ).length === 0
      ) {
        present = true;
      }
    });

    if (!present) unmatchedLanguageCombinations.push(langComb);
  });

  return unmatchedLanguageCombinations;
};

export const isValidInterpreter = (current, selectedInterpreters, t) => {
  let present = false;

  if (current) {
    selectedInterpreters.forEach((inter) => {
      if (inter.id === current.id) present = true;
    });

    if (present) {
      showNotification({ message: t('reviewer.interpreterIsAlreadyAddedToTheList') });
    } else {
      return true;
    }
  } else {
    showNotification({ message: t('reviewer.selectAValidInterpreter') });
  }

  return false;
};

export const cleanedCheckedData = (selectedInterpreters, id) =>
  selectedInterpreters.map((inter) => {
    if (inter.id === id) return { ...inter, checked: !inter.checked };

    return inter;
  });

export const cleanBookingInterpretersData = (selectedInterpreters, bookingId, combinations) =>
  selectedInterpreters
    .filter((inter) => inter.checked)
    .map((inter) => ({
      booking_id: bookingId,
      language_combination_ids: combinations,
      interpreter_id: inter.id,
    }));

export const reviewersArray = (reviewers) =>
  reviewers.map((reviewer) => ({
    ...reviewer,
    label: reviewer.full_name,
    value: reviewer.id,
  }));

export const findLanguageCombinationAndIndex = (bookingRequestInterpreters, bookingInterpreterId) => {
  let combinationIndex;
  const bookingInterpreterObj = bookingRequestInterpreters.find((bookingInterpreter, index) =>
    bookingInterpreter.booking_interpreters.find((interpreterObj) => {
      if (interpreterObj.id === bookingInterpreterId) {
        combinationIndex = index;

        return interpreterObj;
      }

      return false;
    })
  );

  return { bookingInterpreterObj, combinationIndex };
};

export const generateReviewerMeetingsPayload = ({ tab, pageNumber, perPage, searchQuery = '' }) => {
  const order = tab === MeetingStatus.UPCOMING ? 'asc' : 'desc';
  let filterParams = { status: [MeetingStatus.CANCELLED] };

  if (tab === MeetingStatus.COMPLETED) {
    filterParams = { past_bookings: true };
  } else if (tab === MeetingStatus.UPCOMING) {
    filterParams = { upcoming_bookings: true };
  }

  const payload = {
    ...filterParams,
    page: pageNumber || 1,
    per_page: perPage,
    order_by: 'starts_at',
    order,
  };

  if (searchQuery.length > 0) {
    payload.search_param = searchQuery;
  }

  return payload;
};

export const generateGetBookingRequestsPayload = ({ currentTab, page, perPage, searchQuery = '' }) => {
  let statusArray;

  if (currentTab === 'pending') statusArray = ['in_progress', 'new_request', 'declined', 'expired'];
  else if (currentTab === 'cancelled') statusArray = ['cancelled'];
  else statusArray = [currentTab, 'pending_review', 'confirmed'];

  const payload = {
    status: statusArray,
    page,
    per_page: perPage,
  };

  if (searchQuery.length > 0) {
    payload.search_param = searchQuery;
  }

  return payload;
};

export const bookingStatusClass = (status) =>
  [statusConstants.new_request, statusConstants.declined, statusConstants.cancelled, statusConstants.expired].includes(
    status
  )
    ? 'newrequest'
    : '';
