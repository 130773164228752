export const GET_SIGNUP = 'GET_SIGNUP';
export const GET_SIGNUP_SUCCESS = 'GET_SIGNUP_SUCCESS';
export const GET_SIGNUP_ERROR = 'GET_SIGNUP_ERROR';

export const GET_INTERPRETER_PROFILE = 'GET_INTERPRETER_PROFILE';
export const GET_INTERPRETER_PROFILE_SUCCESS = 'GET_INTERPRETER_PROFILE_SUCCESS';
export const GET_INTERPRETER_PROFILE_ERROR = 'GET_INTERPRETER_PROFILE_ERROR';

export const GET_INTERPRETER_DETAILS = 'GET_INTERPRETER_DETAILS';
export const GET_INTERPRETER_DETAILS_SUCCESS = 'GET_INTERPRETER_DETAILS_SUCCESS';
export const GET_INTERPRETER_DETAILS_ERROR = 'GET_INTERPRETER_DETAILS_ERROR';
export const GET_ALL_INTERPRETERS = 'GET_ALL_INTERPRETERS';
export const GET_ALL_INTERPRETERS_SUCCESS = 'GET_ALL_INTERPRETERS_SUCCESS';
export const GET_ALL_INTERPRETERS_ERROR = 'GET_ALL_INTERPRETERS_ERROR';

export const GET_ON_CALL_AVAILABILITY_SLOTS_ERROR = 'GET_ON_CALL_AVAILABILITY_SLOTS_ERROR';

export const EDIT_INTERPRETER_DETAIL_SUCCESS = 'EDIT_INTERPRETER_DETAIL_SUCCESS';

export const CREATE_BOOKED_INTERPRETER_SUCCESS = 'CREATE_BOOKED_INTERPRETER_SUCCESS';
export const GET_INTERPRETER_BY_EMAIL_SUCCESS = 'GET_INTERPRETER_BY_EMAIL_SUCCESS';
export const GET_INTERPRETER_BY_EMAIL_ERROR = 'GET_INTERPRETER_BY_EMAIL_ERROR';

export const BOOKED_SELECTED_INTERPRETERS_SUCCESS = 'BOOKED_SELECTED_INTERPRETERS_SUCCESS';
export const BOOKED_SELECTED_INTERPRETERS_ERROR = 'BOOKED_SELECTED_INTERPRETERS_ERROR';

export const GET_UPCOMING_MEETINGS_SUCCESS = 'GET_UPCOMING_MEETINGS_SUCCESS';
export const GET_INTERPRETER_STATISTICS_SUCCESS = 'GET_INTERPRETER_STATISTICS_SUCCESS';
export const GET_INTERPRETER_ACCEPTED_MEETINGS_SUCCESS = 'GET_INTERPRETER_ACCEPTED_MEETINGS_SUCCESS';
export const GET_INTERPRETER_EARNINGS_SUCCESS = 'GET_INTERPRETER_EARNINGS_SUCCESS';

export const SET_AVAILABILITY_SLOTS_SUCCESS = 'SET_AVAILABILITY_SLOTS_SUCCESS';
export const ADD_AVAILABILITY_SLOTS_SUCCESS = 'ADD_AVAILABILITY_SLOTS_SUCCESS';

export const BOOKED_SELECTED_INTERPRETER_SUCCESS = 'BOOKED_SELECTED_INTERPRETER_SUCCESS';
export const REMOVE_BULK_BOOKED_INTERPRETERS_SUCCESS = 'REMOVE_BULK_BOOKED_INTERPRETERS_SUCCESS';

export const GET_EVENT_SLOTS_ERROR = 'GET_EVENT_SLOTS_ERROR';
export const GET_INTERPRETERS_BY_REPEAT_SEARCH_SUCCESS = 'GET_INTERPRETERS_BY_REPEAT_SEARCH_SUCCESS';

export const GET_GENERIC_AVAILIBILITY_SLOTS = 'GET_GENERIC_AVAILIBILITY_SLOTS';
export const GET_GENERIC_AVAILIBILITY_SLOTS_ERROR = 'GET_GENERIC_AVAILIBILITY_SLOTS_ERROR';
export const SAVE_GENERIC_AVAILIBILITY_SLOTS = 'SAVE_GENERIC_AVAILIBILITY_SLOTS';
export const DELETE_GENERIC_SLOTS = 'DELETE_GENERIC_SLOTS';
export const GET_AVAILIBILITY_SLOTS_ERROR = 'GET_AVAILIBILITY_SLOTS_ERROR';

export const CLEAR_SHIFT_PLANS = 'CLEAR_SHIFT_PLANS';
export const GET_INTERPRETERS_FILTER_SUCCESS = 'GET_INTERPRETERS_FILTER_SUCCESS';
export const SET_INTERPRETERS_FILTER_INITIAL_STATE = 'SET_INTERPRETERS_FILTER_INITIAL_STATE';

export const GET_WALLET_BALANCE = 'GET_WALLET_BALANCE';
export const UPDATE_WALLET_BALANCE = 'UPDATE_WALLET_BALANCE';

export const SET_LOADING = 'SET_LOADING';
export const CREATE_BULK_BOOKING_INTERPRETERS_AND_REMOVE_OLDS = 'CREATE_BULK_BOOKING_INTERPRETERS_AND_REMOVE_OLDS';

export const SKIP_WALK_THROUGH_SCREENS = 'SKIP_WALK_THROUGH_SCREENS';

export const REMOVE_BOOKING_INTERPRETER = 'REMOVE_BOOKING_INTERPRETER';

export const BULK_UPDATE_INTERPRETERS = 'BULK_UPDATE_INTERPRETERS';

export const SET_LOADING_INTERPRETER_PROFILE = 'SET_LOADING_INTERPRETER_PROFILE';

export const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA';
export const CHANGE_INTERPRETER_PROFILE_PICTURE = 'CHANGE_INTERPRETER_PROFILE_PICTURE';

export const INTERPRETERS_HEADER = [
  { label: 'First Name', key: 'first_name' },
  { label: 'Last Name', key: 'last_name' },
  { label: 'Email', key: 'email' },
  { label: 'Language A', key: 'languages_a' },
  { label: 'Language B', key: 'languages_b' },
  { label: 'Language C', key: 'languages_c' },
  { label: 'Profile', key: 'profile' },
  { label: 'Status', key: 'status' },
];

export const SEARCH_STRATEGY_COOKIE = 'SEARCH-CLASS';
export const OLD_SEARCH_STRATEGY = 'old_search_engine';
export const NEW_SEARCH_STRATEGY = 'new_search_engine';
