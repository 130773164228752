import { findIndex } from 'lodash-es';

import { Document } from 'shared/types/document';

export const getUnsignedDocument = (documents: Document[]): Document[] => {
  const unsignedDocuments = documents.filter((document) => document.signed_at === null);

  return unsignedDocuments;
};
export const updateSignedDocument = (documentsArr: Document[], payload: Document): Document[] => {
  const index = findIndex(documentsArr, { id: payload.id });

  documentsArr.splice(index, 1);
  documentsArr.splice(index, 0, payload);

  return documentsArr;
};
