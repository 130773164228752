import { PreparationMaterialVariant } from 'constants/PreparationMaterialVariant';
import { useLegacyTranslation } from 'hooks/useLegacyTranslation';
import { Notification } from 'shared/types/notification';

interface Props {
  notification: Notification;
}

export const PreparationMaterialNotification = ({ notification }: Props): JSX.Element => {
  const { t } = useLegacyTranslation();

  return (
    <div>
      <strong className="title">
        {notification.extra.preparation_material_type === PreparationMaterialVariant.LINK
          ? t('interpreter.preparationMaterial.newLinkAdded')
          : t('interpreter.preparationMaterial.newMaterialAdded')}
      </strong>
      <p>{t('notifications.clickOrTapToReview')}</p>
    </div>
  );
};
