import { Timezone } from 'domains/shared/types/timezone';

export const DEFAULT_TIMEZONE: Timezone = {
  name: 'UTC',
  offset: '+00:00',
  iana_code: 'Etc/UTC',
  abbreviation: 'UTC',
  zoom_code: 'UTC',
  offset_in_seconds: 0,
  is_kcp_supported: true,
};

export const DUPLICATE_TIMEZONE = ['Calcutta', 'Kathmandu', 'Dacca'];

export const TIMEZONES: Timezone[] = [
  {
    name: 'International Date Line West',
    offset: '-12:00',
    iana_code: 'Etc/GMT+12',
    abbreviation: '-12',
    zoom_code: null,
    offset_in_seconds: -43200,
    is_kcp_supported: true,
  },
  {
    name: 'American Samoa',
    offset: '-11:00',
    iana_code: 'Pacific/Pago_Pago',
    abbreviation: 'SST',
    zoom_code: 'Pacific/Pago_Pago',
    offset_in_seconds: -39600,
    is_kcp_supported: true,
  },
  {
    name: 'Midway Island',
    offset: '-11:00',
    iana_code: 'Pacific/Midway',
    abbreviation: 'SST',
    zoom_code: 'Pacific/Midway',
    offset_in_seconds: -39600,
    is_kcp_supported: true,
  },
  {
    name: 'Hawaii',
    offset: '-10:00',
    iana_code: 'Pacific/Honolulu',
    abbreviation: 'HST',
    zoom_code: 'Pacific/Honolulu',
    offset_in_seconds: -36000,
    is_kcp_supported: true,
  },
  {
    name: 'Alaska',
    offset: '-09:00',
    iana_code: 'America/Juneau',
    abbreviation: 'AKDT',
    zoom_code: 'America/Anchorage',
    offset_in_seconds: -32400,
    is_kcp_supported: true,
  },
  {
    name: 'Pacific Time (US & Canada)',
    offset: '-08:00',
    iana_code: 'America/Los_Angeles',
    abbreviation: 'PDT',
    zoom_code: 'America/Los_Angeles',
    offset_in_seconds: -28800,
    is_kcp_supported: true,
  },
  {
    name: 'Tijuana',
    offset: '-08:00',
    iana_code: 'America/Tijuana',
    abbreviation: 'PDT',
    zoom_code: 'America/Tijuana',
    offset_in_seconds: -28800,
    is_kcp_supported: true,
  },
  {
    name: 'Vancouver',
    offset: '-08:00',
    iana_code: 'America/Vancouver',
    abbreviation: 'PDT',
    zoom_code: 'America/Vancouver',
    offset_in_seconds: -28800,
    is_kcp_supported: false,
  },
  {
    name: 'Arizona',
    offset: '-07:00',
    iana_code: 'America/Phoenix',
    abbreviation: 'MST',
    zoom_code: 'America/Phoenix',
    offset_in_seconds: -25200,
    is_kcp_supported: true,
  },
  {
    name: 'Chihuahua',
    offset: '-07:00',
    iana_code: 'America/Chihuahua',
    abbreviation: 'MDT',
    zoom_code: 'America/Chihuahua',
    offset_in_seconds: -25200,
    is_kcp_supported: true,
  },
  {
    name: 'Mazatlan',
    offset: '-07:00',
    iana_code: 'America/Mazatlan',
    abbreviation: 'MDT',
    zoom_code: 'America/Mazatlan',
    offset_in_seconds: -25200,
    is_kcp_supported: true,
  },
  {
    name: 'Mountain Time (US & Canada)',
    offset: '-07:00',
    iana_code: 'America/Denver',
    abbreviation: 'MDT',
    zoom_code: 'America/Denver',
    offset_in_seconds: -25200,
    is_kcp_supported: true,
  },
  {
    name: 'Edmonton',
    offset: '-07:00',
    iana_code: 'America/Edmonton',
    abbreviation: 'MDT',
    zoom_code: 'America/Edmonton',
    offset_in_seconds: -25200,
    is_kcp_supported: false,
  },
  {
    name: 'Whitehorse',
    offset: '-07:00',
    iana_code: 'America/Whitehorse',
    abbreviation: 'MST',
    zoom_code: 'America/Whitehorse',
    offset_in_seconds: -25200,
    is_kcp_supported: false,
  },
  {
    name: 'Central America',
    offset: '-06:00',
    iana_code: 'America/Guatemala',
    abbreviation: 'CST',
    zoom_code: 'America/Guatemala',
    offset_in_seconds: -21600,
    is_kcp_supported: true,
  },
  {
    name: 'Central Time (US & Canada)',
    offset: '-06:00',
    iana_code: 'America/Chicago',
    abbreviation: 'CDT',
    zoom_code: 'America/Chicago',
    offset_in_seconds: -21600,
    is_kcp_supported: true,
  },
  {
    name: 'Mexico City',
    offset: '-06:00',
    iana_code: 'America/Mexico_City',
    abbreviation: 'CDT',
    zoom_code: 'America/Mexico_City',
    offset_in_seconds: -21600,
    is_kcp_supported: true,
  },
  {
    name: 'Monterrey',
    offset: '-06:00',
    iana_code: 'America/Monterrey',
    abbreviation: 'CDT',
    zoom_code: 'America/Monterrey',
    offset_in_seconds: -21600,
    is_kcp_supported: true,
  },
  {
    name: 'Saskatchewan',
    offset: '-06:00',
    iana_code: 'America/Regina',
    abbreviation: 'CST',
    zoom_code: 'America/Regina',
    offset_in_seconds: -21600,
    is_kcp_supported: true,
  },
  {
    name: 'Winnipeg',
    offset: '-06:00',
    iana_code: 'America/Winnipeg',
    abbreviation: 'CDT',
    zoom_code: 'America/Winnipeg',
    offset_in_seconds: -21600,
    is_kcp_supported: false,
  },
  {
    name: 'El Salvador',
    offset: '-06:00',
    iana_code: 'America/El_Salvador',
    abbreviation: 'CST',
    zoom_code: 'America/El_Salvador',
    offset_in_seconds: -21600,
    is_kcp_supported: false,
  },
  {
    name: 'Managua',
    offset: '-06:00',
    iana_code: 'America/Managua',
    abbreviation: 'CST',
    zoom_code: 'America/Managua',
    offset_in_seconds: -21600,
    is_kcp_supported: false,
  },
  {
    name: 'Costa Rica',
    offset: '-06:00',
    iana_code: 'America/Costa_Rica',
    abbreviation: 'CST',
    zoom_code: 'America/Costa_Rica',
    offset_in_seconds: -21600,
    is_kcp_supported: false,
  },
  {
    name: 'Tegucigalpa',
    offset: '-06:00',
    iana_code: 'America/Tegucigalpa',
    abbreviation: 'CST',
    zoom_code: 'America/Tegucigalpa',
    offset_in_seconds: -21600,
    is_kcp_supported: false,
  },
  {
    name: 'Bogota',
    offset: '-05:00',
    iana_code: 'America/Bogota',
    abbreviation: '-05',
    zoom_code: 'America/Bogota',
    offset_in_seconds: -18000,
    is_kcp_supported: true,
  },
  {
    name: 'Eastern Time (US & Canada)',
    offset: '-05:00',
    iana_code: 'America/New_York',
    abbreviation: 'EDT',
    zoom_code: 'America/New_York',
    offset_in_seconds: -18000,
    is_kcp_supported: true,
  },
  {
    name: 'Indiana (East)',
    offset: '-05:00',
    iana_code: 'America/Indiana/Indianapolis',
    abbreviation: 'EDT',
    zoom_code: 'America/Indianapolis',
    offset_in_seconds: -18000,
    is_kcp_supported: true,
  },
  {
    name: 'Lima',
    offset: '-05:00',
    iana_code: 'America/Lima',
    abbreviation: '-05',
    zoom_code: 'America/Lima',
    offset_in_seconds: -18000,
    is_kcp_supported: true,
  },
  {
    name: 'Montreal',
    offset: '-05:00',
    iana_code: 'America/Montreal',
    abbreviation: 'EDT',
    zoom_code: 'America/Montreal',
    offset_in_seconds: -18000,
    is_kcp_supported: false,
  },
  {
    name: 'Panama',
    offset: '-05:00',
    iana_code: 'America/Panama',
    abbreviation: 'EST',
    zoom_code: 'America/Panama',
    offset_in_seconds: -18000,
    is_kcp_supported: false,
  },
  {
    name: 'Eirunepe',
    offset: '-05:00',
    iana_code: 'America/Eirunepe',
    abbreviation: '-05',
    zoom_code: 'America/Eirunepe',
    offset_in_seconds: -18000,
    is_kcp_supported: false,
  },
  {
    name: 'Atlantic Time (Canada)',
    offset: '-04:00',
    iana_code: 'America/Halifax',
    abbreviation: 'ADT',
    zoom_code: 'America/Halifax',
    offset_in_seconds: -14400,
    is_kcp_supported: true,
  },
  {
    name: 'Caracas',
    offset: '-04:00',
    iana_code: 'America/Caracas',
    abbreviation: '-04',
    zoom_code: 'America/Caracas',
    offset_in_seconds: -14400,
    is_kcp_supported: true,
  },
  {
    name: 'Georgetown',
    offset: '-04:00',
    iana_code: 'America/Guyana',
    abbreviation: '-04',
    zoom_code: 'America/Guyana',
    offset_in_seconds: -14400,
    is_kcp_supported: true,
  },
  {
    name: 'La Paz',
    offset: '-04:00',
    iana_code: 'America/La_Paz',
    abbreviation: '-04',
    zoom_code: 'America/La_Paz',
    offset_in_seconds: -14400,
    is_kcp_supported: true,
  },
  {
    name: 'Puerto Rico',
    offset: '-04:00',
    iana_code: 'America/Puerto_Rico',
    abbreviation: 'AST',
    zoom_code: 'America/Puerto_Rico',
    offset_in_seconds: -14400,
    is_kcp_supported: true,
  },
  {
    name: 'Santiago',
    offset: '-04:00',
    iana_code: 'America/Santiago',
    abbreviation: '-04',
    zoom_code: 'America/Santiago',
    offset_in_seconds: -14400,
    is_kcp_supported: true,
  },
  {
    name: 'Atlantic',
    offset: '-04:00',
    iana_code: 'Canada/Atlantic',
    abbreviation: 'ADT',
    zoom_code: 'Canada/Atlantic',
    offset_in_seconds: -14400,
    is_kcp_supported: false,
  },
  {
    name: 'Newfoundland',
    offset: '-03:30',
    iana_code: 'America/St_Johns',
    abbreviation: 'NDT',
    zoom_code: 'America/St_Johns',
    offset_in_seconds: -12600,
    is_kcp_supported: true,
  },
  {
    name: 'Brasilia',
    offset: '-03:00',
    iana_code: 'America/Sao_Paulo',
    abbreviation: '-03',
    zoom_code: 'America/Sao_Paulo',
    offset_in_seconds: -10800,
    is_kcp_supported: true,
  },
  {
    name: 'Buenos Aires',
    offset: '-03:00',
    iana_code: 'America/Argentina/Buenos_Aires',
    abbreviation: '-03',
    zoom_code: 'America/Argentina/Buenos_Aires',
    offset_in_seconds: -10800,
    is_kcp_supported: true,
  },
  {
    name: 'Greenland',
    offset: '-03:00',
    iana_code: 'America/Godthab',
    abbreviation: '-02',
    zoom_code: 'America/Godthab',
    offset_in_seconds: -10800,
    is_kcp_supported: true,
  },
  {
    name: 'Montevideo',
    offset: '-03:00',
    iana_code: 'America/Montevideo',
    abbreviation: '-03',
    zoom_code: 'America/Montevideo',
    offset_in_seconds: -10800,
    is_kcp_supported: true,
  },
  {
    name: 'Araguaina',
    offset: '-03:00',
    iana_code: 'America/Araguaina',
    abbreviation: '-03',
    zoom_code: 'America/Araguaina',
    offset_in_seconds: -10800,
    is_kcp_supported: false,
  },
  {
    name: 'Mid-Atlantic',
    offset: '-02:00',
    iana_code: 'Atlantic/South_Georgia',
    abbreviation: '-02',
    zoom_code: null,
    offset_in_seconds: -7200,
    is_kcp_supported: true,
  },
  {
    name: 'Noronha',
    offset: '-02:00',
    iana_code: 'America/Noronha',
    abbreviation: '-02',
    zoom_code: 'America/Noronha',
    offset_in_seconds: -7200,
    is_kcp_supported: false,
  },
  {
    name: 'Azores',
    offset: '-01:00',
    iana_code: 'Atlantic/Azores',
    abbreviation: '+00',
    zoom_code: 'Atlantic/Azores',
    offset_in_seconds: -3600,
    is_kcp_supported: true,
  },
  {
    name: 'Cape Verde Is.',
    offset: '-01:00',
    iana_code: 'Atlantic/Cape_Verde',
    abbreviation: '-01',
    zoom_code: 'Atlantic/Cape_Verde',
    offset_in_seconds: -3600,
    is_kcp_supported: true,
  },
  {
    name: 'Lisbon',
    offset: '+00:00',
    iana_code: 'Europe/Lisbon',
    abbreviation: 'WEST',
    zoom_code: 'Europe/Lisbon',
    offset_in_seconds: 0,
    is_kcp_supported: true,
  },
  {
    name: 'London',
    offset: '+00:00',
    iana_code: 'Europe/London',
    abbreviation: 'BST',
    zoom_code: 'Europe/London',
    offset_in_seconds: 0,
    is_kcp_supported: true,
  },
  {
    name: 'Monrovia',
    offset: '+00:00',
    iana_code: 'Africa/Monrovia',
    abbreviation: 'GMT',
    zoom_code: null,
    offset_in_seconds: 0,
    is_kcp_supported: true,
  },
  DEFAULT_TIMEZONE,
  {
    name: 'Greenwich',
    offset: '+00:00',
    iana_code: 'Etc/Greenwich',
    abbreviation: 'GMT',
    zoom_code: 'Etc/Greenwich',
    offset_in_seconds: 0,
    is_kcp_supported: false,
  },
  {
    name: 'Reykjavik',
    offset: '+00:00',
    iana_code: 'Atlantic/Reykjavik',
    abbreviation: 'GMT',
    zoom_code: 'Atlantic/Reykjavik',
    offset_in_seconds: 0,
    is_kcp_supported: false,
  },
  {
    name: 'Nouakchott',
    offset: '+00:00',
    iana_code: 'Africa/Nouakchott',
    abbreviation: 'GMT',
    zoom_code: 'Africa/Nouakchott',
    offset_in_seconds: 0,
    is_kcp_supported: false,
  },
  {
    name: 'Amsterdam',
    offset: '+01:00',
    iana_code: 'Europe/Amsterdam',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Amsterdam',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Belgrade',
    offset: '+01:00',
    iana_code: 'Europe/Belgrade',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Belgrade',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Berlin',
    offset: '+01:00',
    iana_code: 'Europe/Berlin',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Berlin',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Brussels',
    offset: '+01:00',
    iana_code: 'Europe/Brussels',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Brussels',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Budapest',
    offset: '+01:00',
    iana_code: 'Europe/Budapest',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Budapest',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Casablanca',
    offset: '+01:00',
    iana_code: 'Africa/Casablanca',
    abbreviation: '+01',
    zoom_code: 'Africa/Casablanca',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Copenhagen',
    offset: '+01:00',
    iana_code: 'Europe/Copenhagen',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Copenhagen',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Dublin',
    offset: '+01:00',
    iana_code: 'Europe/Dublin',
    abbreviation: 'IST',
    zoom_code: 'Europe/Dublin',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Madrid',
    offset: '+01:00',
    iana_code: 'Europe/Madrid',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Madrid',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Paris',
    offset: '+01:00',
    iana_code: 'Europe/Paris',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Paris',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Prague',
    offset: '+01:00',
    iana_code: 'Europe/Prague',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Prague',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Rome',
    offset: '+01:00',
    iana_code: 'Europe/Rome',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Rome',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Sarajevo',
    offset: '+01:00',
    iana_code: 'Europe/Sarajevo',
    abbreviation: 'CEST',
    zoom_code: 'CET',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Stockholm',
    offset: '+01:00',
    iana_code: 'Europe/Stockholm',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Stockholm',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Vienna',
    offset: '+01:00',
    iana_code: 'Europe/Vienna',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Vienna',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Warsaw',
    offset: '+01:00',
    iana_code: 'Europe/Warsaw',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Warsaw',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'West Central Africa',
    offset: '+01:00',
    iana_code: 'Africa/Algiers',
    abbreviation: 'CET',
    zoom_code: 'Africa/Algiers',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Zurich',
    offset: '+01:00',
    iana_code: 'Europe/Zurich',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Zurich',
    offset_in_seconds: 3600,
    is_kcp_supported: true,
  },
  {
    name: 'Oslo',
    offset: '+01:00',
    iana_code: 'Europe/Oslo',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Oslo',
    offset_in_seconds: 3600,
    is_kcp_supported: false,
  },
  {
    name: 'Luxembourg',
    offset: '+01:00',
    iana_code: 'Europe/Luxembourg',
    abbreviation: 'CEST',
    zoom_code: 'Europe/Luxembourg',
    offset_in_seconds: 3600,
    is_kcp_supported: false,
  },
  {
    name: 'Bangui',
    offset: '+01:00',
    iana_code: 'Africa/Bangui',
    abbreviation: 'WAT',
    zoom_code: 'Africa/Bangui',
    offset_in_seconds: 3600,
    is_kcp_supported: false,
  },
  {
    name: 'Tunis',
    offset: '+01:00',
    iana_code: 'Africa/Tunis',
    abbreviation: 'CET',
    zoom_code: 'Africa/Tunis',
    offset_in_seconds: 3600,
    is_kcp_supported: false,
  },
  {
    name: 'Athens',
    offset: '+02:00',
    iana_code: 'Europe/Athens',
    abbreviation: 'EEST',
    zoom_code: 'Europe/Athens',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Bucharest',
    offset: '+02:00',
    iana_code: 'Europe/Bucharest',
    abbreviation: 'EEST',
    zoom_code: 'Europe/Bucharest',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Cairo',
    offset: '+02:00',
    iana_code: 'Africa/Cairo',
    abbreviation: 'EET',
    zoom_code: 'Africa/Cairo',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Harare',
    offset: '+02:00',
    iana_code: 'Africa/Harare',
    abbreviation: 'CAT',
    zoom_code: 'Africa/Harare',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Helsinki',
    offset: '+02:00',
    iana_code: 'Europe/Helsinki',
    abbreviation: 'EEST',
    zoom_code: 'Europe/Helsinki',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Jerusalem',
    offset: '+02:00',
    iana_code: 'Asia/Jerusalem',
    abbreviation: 'IDT',
    zoom_code: 'Asia/Jerusalem',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Kaliningrad',
    offset: '+02:00',
    iana_code: 'Europe/Kaliningrad',
    abbreviation: 'EET',
    zoom_code: null,
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Kyiv',
    offset: '+02:00',
    iana_code: 'Europe/Kiev',
    abbreviation: 'EEST',
    zoom_code: 'Europe/Kiev',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Pretoria',
    offset: '+02:00',
    iana_code: 'Africa/Johannesburg',
    abbreviation: 'SAST',
    zoom_code: 'Africa/Johannesburg',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Riga',
    offset: '+02:00',
    iana_code: 'Europe/Riga',
    abbreviation: 'EEST',
    zoom_code: null,
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Sofia',
    offset: '+02:00',
    iana_code: 'Europe/Sofia',
    abbreviation: 'EEST',
    zoom_code: 'Europe/Sofia',
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Tallinn',
    offset: '+02:00',
    iana_code: 'Europe/Tallinn',
    abbreviation: 'EEST',
    zoom_code: null,
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Vilnius',
    offset: '+02:00',
    iana_code: 'Europe/Vilnius',
    abbreviation: 'EEST',
    zoom_code: null,
    offset_in_seconds: 7200,
    is_kcp_supported: true,
  },
  {
    name: 'Nicosia',
    offset: '+02:00',
    iana_code: 'Asia/Nicosia',
    abbreviation: 'EEST',
    zoom_code: 'Asia/Nicosia',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Beirut',
    offset: '+02:00',
    iana_code: 'Asia/Beirut',
    abbreviation: 'EEST',
    zoom_code: 'Asia/Beirut',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Damascus',
    offset: '+02:00',
    iana_code: 'Asia/Damascus',
    abbreviation: 'EEST',
    zoom_code: 'Asia/Damascus',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Amman',
    offset: '+02:00',
    iana_code: 'Asia/Amman',
    abbreviation: 'EEST',
    zoom_code: 'Asia/Amman',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Tripoli',
    offset: '+02:00',
    iana_code: 'Africa/Tripoli',
    abbreviation: 'EET',
    zoom_code: 'Africa/Tripoli',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Khartoum',
    offset: '+02:00',
    iana_code: 'Africa/Khartoum',
    abbreviation: 'CAT',
    zoom_code: 'Africa/Khartoum',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Chisinau',
    offset: '+02:00',
    iana_code: 'Europe/Chisinau',
    abbreviation: 'EEST',
    zoom_code: 'Europe/Chisinau',
    offset_in_seconds: 7200,
    is_kcp_supported: false,
  },
  {
    name: 'Baghdad',
    offset: '+03:00',
    iana_code: 'Asia/Baghdad',
    abbreviation: '+03',
    zoom_code: 'Asia/Baghdad',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Istanbul',
    offset: '+03:00',
    iana_code: 'Europe/Istanbul',
    abbreviation: '+03',
    zoom_code: 'Europe/Istanbul',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Kuwait',
    offset: '+03:00',
    iana_code: 'Asia/Kuwait',
    abbreviation: '+03',
    zoom_code: 'Asia/Kuwait',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Minsk',
    offset: '+03:00',
    iana_code: 'Europe/Minsk',
    abbreviation: '+03',
    zoom_code: 'Europe/Minsk',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Moscow',
    offset: '+03:00',
    iana_code: 'Europe/Moscow',
    abbreviation: 'MSK',
    zoom_code: 'Europe/Moscow',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Nairobi',
    offset: '+03:00',
    iana_code: 'Africa/Nairobi',
    abbreviation: 'EAT',
    zoom_code: 'Africa/Nairobi',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Riyadh',
    offset: '+03:00',
    iana_code: 'Asia/Riyadh',
    abbreviation: '+03',
    zoom_code: 'Asia/Riyadh',
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Volgograd',
    offset: '+03:00',
    iana_code: 'Europe/Volgograd',
    abbreviation: '+03',
    zoom_code: null,
    offset_in_seconds: 10800,
    is_kcp_supported: true,
  },
  {
    name: 'Bahrain',
    offset: '+03:00',
    iana_code: 'Asia/Bahrain',
    abbreviation: '+03',
    zoom_code: 'Asia/Bahrain',
    offset_in_seconds: 10800,
    is_kcp_supported: false,
  },
  {
    name: 'Qatar',
    offset: '+03:00',
    iana_code: 'Asia/Qatar',
    abbreviation: '+03',
    zoom_code: 'Asia/Qatar',
    offset_in_seconds: 10800,
    is_kcp_supported: false,
  },
  {
    name: 'Aden',
    offset: '+03:00',
    iana_code: 'Asia/Aden',
    abbreviation: '+03',
    zoom_code: 'Asia/Aden',
    offset_in_seconds: 10800,
    is_kcp_supported: false,
  },
  {
    name: 'Djibouti',
    offset: '+03:00',
    iana_code: 'Africa/Djibouti',
    abbreviation: 'EAT',
    zoom_code: 'Africa/Djibouti',
    offset_in_seconds: 10800,
    is_kcp_supported: false,
  },
  {
    name: 'Mogadishu',
    offset: '+03:00',
    iana_code: 'Africa/Mogadishu',
    abbreviation: 'EAT',
    zoom_code: 'Africa/Mogadishu',
    offset_in_seconds: 10800,
    is_kcp_supported: false,
  },
  {
    name: 'Tehran',
    offset: '+03:30',
    iana_code: 'Asia/Tehran',
    abbreviation: '+0430',
    zoom_code: 'Asia/Tehran',
    offset_in_seconds: 12600,
    is_kcp_supported: true,
  },
  {
    name: 'Baku',
    offset: '+04:00',
    iana_code: 'Asia/Baku',
    abbreviation: '+04',
    zoom_code: 'Asia/Baku',
    offset_in_seconds: 14400,
    is_kcp_supported: true,
  },
  {
    name: 'Muscat',
    offset: '+04:00',
    iana_code: 'Asia/Muscat',
    abbreviation: '+04',
    zoom_code: 'Asia/Muscat',
    offset_in_seconds: 14400,
    is_kcp_supported: true,
  },
  {
    name: 'Samara',
    offset: '+04:00',
    iana_code: 'Europe/Samara',
    abbreviation: '+04',
    zoom_code: null,
    offset_in_seconds: 14400,
    is_kcp_supported: true,
  },
  {
    name: 'Dubai',
    offset: '+04:00',
    iana_code: 'Asia/Dubai',
    abbreviation: '+04',
    zoom_code: 'Asia/Dubai',
    offset_in_seconds: 14400,
    is_kcp_supported: false,
  },
  {
    name: 'Kabul',
    offset: '+04:30',
    iana_code: 'Asia/Kabul',
    abbreviation: '+0430',
    zoom_code: 'Asia/Kabul',
    offset_in_seconds: 16200,
    is_kcp_supported: true,
  },
  {
    name: 'Ekaterinburg',
    offset: '+05:00',
    iana_code: 'Asia/Yekaterinburg',
    abbreviation: '+05',
    zoom_code: 'Asia/Yekaterinburg',
    offset_in_seconds: 18000,
    is_kcp_supported: true,
  },
  {
    name: 'Tashkent',
    offset: '+05:00',
    iana_code: 'Asia/Tashkent',
    abbreviation: '+05',
    zoom_code: 'Asia/Tashkent',
    offset_in_seconds: 18000,
    is_kcp_supported: true,
  },
  {
    name: 'Kolkata',
    offset: '+05:30',
    iana_code: 'Asia/Kolkata',
    abbreviation: 'IST',
    zoom_code: 'Asia/Kolkata',
    offset_in_seconds: 19800,
    is_kcp_supported: true,
  },
  {
    name: 'Sri Jayawardenepura',
    offset: '+05:30',
    iana_code: 'Asia/Colombo',
    abbreviation: '+0530',
    zoom_code: 'Asia/Colombo',
    offset_in_seconds: 19800,
    is_kcp_supported: true,
  },
  {
    name: 'Calcutta',
    offset: '+05:30',
    iana_code: 'Asia/Calcutta',
    abbreviation: 'IST',
    zoom_code: 'Asia/Calcutta',
    offset_in_seconds: 19800,
    is_kcp_supported: false,
  },
  {
    name: 'Kathmandu',
    offset: '+05:45',
    iana_code: 'Asia/Kathmandu',
    abbreviation: '+0545',
    zoom_code: 'Asia/Kathmandu',
    offset_in_seconds: 20700,
    is_kcp_supported: false,
  },
  {
    name: 'Katmandu',
    offset: '+05:45',
    iana_code: 'Asia/Katmandu',
    abbreviation: '+0545',
    zoom_code: 'Asia/Katmandu',
    offset_in_seconds: 20700,
    is_kcp_supported: true,
  },
  {
    name: 'Almaty',
    offset: '+06:00',
    iana_code: 'Asia/Almaty',
    abbreviation: '+06',
    zoom_code: 'Asia/Almaty',
    offset_in_seconds: 21600,
    is_kcp_supported: true,
  },
  {
    name: 'Dhaka',
    offset: '+06:00',
    iana_code: 'Asia/Dhaka',
    abbreviation: '+06',
    zoom_code: 'Asia/Dhaka',
    offset_in_seconds: 21600,
    is_kcp_supported: true,
  },
  {
    name: 'Urumqi',
    offset: '+06:00',
    iana_code: 'Asia/Urumqi',
    abbreviation: '+06',
    zoom_code: null,
    offset_in_seconds: 21600,
    is_kcp_supported: true,
  },
  {
    name: 'Dacca',
    offset: '+06:00',
    iana_code: 'Asia/Dacca',
    abbreviation: '+06',
    zoom_code: 'Asia/Dacca',
    offset_in_seconds: 21600,
    is_kcp_supported: false,
  },
  {
    name: 'Rangoon',
    offset: '+06:30',
    iana_code: 'Asia/Rangoon',
    abbreviation: '+0630',
    zoom_code: 'Asia/Rangoon',
    offset_in_seconds: 23400,
    is_kcp_supported: true,
  },
  {
    name: 'Bangkok',
    offset: '+07:00',
    iana_code: 'Asia/Bangkok',
    abbreviation: '+07',
    zoom_code: 'Asia/Bangkok',
    offset_in_seconds: 25200,
    is_kcp_supported: true,
  },
  {
    name: 'Jakarta',
    offset: '+07:00',
    iana_code: 'Asia/Jakarta',
    abbreviation: 'WIB',
    zoom_code: 'Asia/Jakarta',
    offset_in_seconds: 25200,
    is_kcp_supported: true,
  },
  {
    name: 'Krasnoyarsk',
    offset: '+07:00',
    iana_code: 'Asia/Krasnoyarsk',
    abbreviation: '+07',
    zoom_code: 'Asia/Krasnoyarsk',
    offset_in_seconds: 25200,
    is_kcp_supported: true,
  },
  {
    name: 'Novosibirsk',
    offset: '+07:00',
    iana_code: 'Asia/Novosibirsk',
    abbreviation: '+07',
    zoom_code: 'Asia/Novosibirsk',
    offset_in_seconds: 25200,
    is_kcp_supported: true,
  },
  {
    name: 'Saigon',
    offset: '+07:00',
    iana_code: 'Asia/Saigon',
    abbreviation: '+07',
    zoom_code: 'Asia/Saigon',
    offset_in_seconds: 25200,
    is_kcp_supported: false,
  },
  {
    name: 'Beijing',
    offset: '+08:00',
    iana_code: 'Asia/Shanghai',
    abbreviation: 'CST',
    zoom_code: 'Asia/Shanghai',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Chongqing',
    offset: '+08:00',
    iana_code: 'Asia/Chongqing',
    abbreviation: 'CST',
    zoom_code: null,
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Hong Kong',
    offset: '+08:00',
    iana_code: 'Asia/Hong_Kong',
    abbreviation: 'HKT',
    zoom_code: 'Asia/Hong_Kong',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Irkutsk',
    offset: '+08:00',
    iana_code: 'Asia/Irkutsk',
    abbreviation: '+08',
    zoom_code: 'Asia/Irkutsk',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Kuala Lumpur',
    offset: '+08:00',
    iana_code: 'Asia/Kuala_Lumpur',
    abbreviation: '+08',
    zoom_code: 'Asia/Kuala_Lumpur',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Perth',
    offset: '+08:00',
    iana_code: 'Australia/Perth',
    abbreviation: 'AWST',
    zoom_code: 'Australia/Perth',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Singapore',
    offset: '+08:00',
    iana_code: 'Asia/Singapore',
    abbreviation: '+08',
    zoom_code: 'Asia/Singapore',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Taipei',
    offset: '+08:00',
    iana_code: 'Asia/Taipei',
    abbreviation: 'CST',
    zoom_code: 'Asia/Taipei',
    offset_in_seconds: 28800,
    is_kcp_supported: true,
  },
  {
    name: 'Seoul',
    offset: '+09:00',
    iana_code: 'Asia/Seoul',
    abbreviation: 'KST',
    zoom_code: 'Asia/Seoul',
    offset_in_seconds: 32400,
    is_kcp_supported: true,
  },
  {
    name: 'Tokyo',
    offset: '+09:00',
    iana_code: 'Asia/Tokyo',
    abbreviation: 'JST',
    zoom_code: 'Asia/Tokyo',
    offset_in_seconds: 32400,
    is_kcp_supported: true,
  },
  {
    name: 'Yakutsk',
    offset: '+09:00',
    iana_code: 'Asia/Yakutsk',
    abbreviation: '+09',
    zoom_code: 'Asia/Yakutsk',
    offset_in_seconds: 32400,
    is_kcp_supported: true,
  },
  {
    name: 'Adelaide',
    offset: '+09:30',
    iana_code: 'Australia/Adelaide',
    abbreviation: 'ACST',
    zoom_code: 'Australia/Adelaide',
    offset_in_seconds: 34200,
    is_kcp_supported: true,
  },
  {
    name: 'Darwin',
    offset: '+09:30',
    iana_code: 'Australia/Darwin',
    abbreviation: 'ACST',
    zoom_code: 'Australia/Darwin',
    offset_in_seconds: 34200,
    is_kcp_supported: true,
  },
  {
    name: 'Brisbane',
    offset: '+10:00',
    iana_code: 'Australia/Brisbane',
    abbreviation: 'AEST',
    zoom_code: 'Australia/Brisbane',
    offset_in_seconds: 36000,
    is_kcp_supported: true,
  },
  {
    name: 'Canberra',
    offset: '+10:00',
    iana_code: 'Australia/Melbourne',
    abbreviation: 'AEST',
    zoom_code: null,
    offset_in_seconds: 36000,
    is_kcp_supported: true,
  },
  {
    name: 'Hobart',
    offset: '+10:00',
    iana_code: 'Australia/Hobart',
    abbreviation: 'AEST',
    zoom_code: 'Australia/Hobart',
    offset_in_seconds: 36000,
    is_kcp_supported: true,
  },
  {
    name: 'Port Moresby',
    offset: '+10:00',
    iana_code: 'Pacific/Port_Moresby',
    abbreviation: '+10',
    zoom_code: 'Pacific/Port_Moresby',
    offset_in_seconds: 36000,
    is_kcp_supported: true,
  },
  {
    name: 'Sydney',
    offset: '+10:00',
    iana_code: 'Australia/Sydney',
    abbreviation: 'AEST',
    zoom_code: 'Australia/Sydney',
    offset_in_seconds: 36000,
    is_kcp_supported: true,
  },
  {
    name: 'Vladivostok',
    offset: '+10:00',
    iana_code: 'Asia/Vladivostok',
    abbreviation: '+10',
    zoom_code: 'Asia/Vladivostok',
    offset_in_seconds: 36000,
    is_kcp_supported: true,
  },
  {
    name: 'Lord Howe',
    offset: '+10:30',
    iana_code: 'Australia/Lord_Howe',
    abbreviation: '+1030',
    zoom_code: 'Australia/Lord_Howe',
    offset_in_seconds: 37800,
    is_kcp_supported: false,
  },
  {
    name: 'Magadan',
    offset: '+11:00',
    iana_code: 'Asia/Magadan',
    abbreviation: '+11',
    zoom_code: 'Asia/Magadan',
    offset_in_seconds: 39600,
    is_kcp_supported: true,
  },
  {
    name: 'New Caledonia',
    offset: '+11:00',
    iana_code: 'Pacific/Noumea',
    abbreviation: '+11',
    zoom_code: 'Pacific/Noumea',
    offset_in_seconds: 39600,
    is_kcp_supported: true,
  },
  {
    name: 'Solomon Is.',
    offset: '+11:00',
    iana_code: 'Pacific/Guadalcanal',
    abbreviation: '+11',
    zoom_code: 'SST',
    offset_in_seconds: 39600,
    is_kcp_supported: true,
  },
  {
    name: 'Srednekolymsk',
    offset: '+11:00',
    iana_code: 'Asia/Srednekolymsk',
    abbreviation: '+11',
    zoom_code: null,
    offset_in_seconds: 39600,
    is_kcp_supported: true,
  },
  {
    name: 'Auckland',
    offset: '+12:00',
    iana_code: 'Pacific/Auckland',
    abbreviation: 'NZST',
    zoom_code: 'Pacific/Auckland',
    offset_in_seconds: 43200,
    is_kcp_supported: true,
  },
  {
    name: 'Fiji',
    offset: '+12:00',
    iana_code: 'Pacific/Fiji',
    abbreviation: '+12',
    zoom_code: 'Pacific/Fiji',
    offset_in_seconds: 43200,
    is_kcp_supported: true,
  },
  {
    name: 'Kamchatka',
    offset: '+12:00',
    iana_code: 'Asia/Kamchatka',
    abbreviation: '+12',
    zoom_code: 'Asia/Kamchatka',
    offset_in_seconds: 43200,
    is_kcp_supported: true,
  },
  {
    name: 'Marshall Is.',
    offset: '+12:00',
    iana_code: 'Pacific/Majuro',
    abbreviation: '+12',
    zoom_code: null,
    offset_in_seconds: 43200,
    is_kcp_supported: true,
  },
  {
    name: 'Chatham Is.',
    offset: '+12:45',
    iana_code: 'Pacific/Chatham',
    abbreviation: '+1245',
    zoom_code: null,
    offset_in_seconds: 45900,
    is_kcp_supported: true,
  },
  {
    name: "Nuku'alofa",
    offset: '+13:00',
    iana_code: 'Pacific/Tongatapu',
    abbreviation: '+13',
    zoom_code: null,
    offset_in_seconds: 46800,
    is_kcp_supported: true,
  },
  {
    name: 'Samoa',
    offset: '+13:00',
    iana_code: 'Pacific/Apia',
    abbreviation: '+13',
    zoom_code: 'Pacific/Apia',
    offset_in_seconds: 46800,
    is_kcp_supported: true,
  },
  {
    name: 'Tokelau Is.',
    offset: '+13:00',
    iana_code: 'Pacific/Fakaofo',
    abbreviation: '+13',
    zoom_code: null,
    offset_in_seconds: 46800,
    is_kcp_supported: true,
  },
  {
    name: 'Islamabad',
    offset: '+05:00',
    iana_code: 'Asia/Karachi',
    abbreviation: '+05',
    zoom_code: 'Asia/Karachi',
    offset_in_seconds: 18000,
    is_kcp_supported: true,
  },
  {
    name: 'Sarajevo',
    offset: '+01:00',
    iana_code: 'CET',
    abbreviation: 'CEST',
    zoom_code: 'CET',
    offset_in_seconds: 3600,
    is_kcp_supported: false,
  },
  {
    name: 'Indiana (East)',
    offset: '-05:00',
    iana_code: 'America/Indianapolis',
    abbreviation: 'EDT',
    zoom_code: 'America/Indianapolis',
    offset_in_seconds: -18000,
    is_kcp_supported: false,
  },
  {
    name: 'Alaska',
    offset: '-09:00',
    iana_code: 'America/Anchorage',
    abbreviation: 'AKDT',
    zoom_code: 'America/Anchorage',
    offset_in_seconds: -32400,
    is_kcp_supported: false,
  },
];
