import * as constants from '../constants/rateConstants';
import { updatedRates } from '../helpers/interpreterRateHelper';

export const initialState = {
  interpreterRates: [],
  clientRates: [],
};

const interpreterRateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_INTERPRETER_RATES:
      return {
        ...state,
        interpreterRates: action.payload,
      };
    case constants.SET_INTERPRETER_RATES:
      return {
        ...state,
        interpreterRates: action.payload,
      };
    case constants.GET_CLIENT_RATES:
      return {
        ...state,
        clientRates: action.payload,
      };
    case constants.SET_CLIENT_RATES:
      return {
        ...state,
        clientRates: action.payload,
      };
    case constants.UPDATE_INTERPRETER_RATE_SUCCESS:
      return {
        ...state,
        interpreterRates: updatedRates(state.interpreterRates, action.payload),
      };
    case constants.UPDATE_CLIENT_RATE_SUCCESS:
      return {
        ...state,
        clientRates: updatedRates(state.clientRates, action.payload),
      };
    default:
      return state;
  }
};

export default interpreterRateReducer;
