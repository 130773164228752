/* eslint-disable no-case-declarations */
import { findIndex } from 'lodash-es';

import * as constants from '../constants/AdminConstants';

export const initialState = {
  users: [],
  pagination: {},
  loading: false,
  blockedUsers: [],
  systemLanguages: [],
  extractReports: [],
};

const adminReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_ALL_USERS:
      return {
        ...state,
        users: [],
        loading: true,
      };
    case constants.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case constants.GET_ALL_USERS_ERROR:
      return {
        ...state,
        users: [],
      };
    case constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case constants.CREATE_REVIEWER_SUCCESS:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case constants.DELETE_REVIEWER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload.id),
      };
    case constants.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case constants.GET_BLOCKED_USERS:
      return {
        ...state,
        blockedUsers: action.payload,
      };
    case constants.BLOCK_USERS:
      return {
        ...state,
        blockedUsers: [action.payload, ...state.blockedUsers],
      };
    case constants.UN_BLOCK_USERS:
      return {
        ...state,
        blockedUsers: state.blockedUsers.filter((user) => user.id !== action.payload.id),
      };
    case constants.GET_SYSTEM_LANGUAGES:
      return {
        ...state,
        systemLanguages: action.payload,
        loading: false,
      };
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
        extractReports: [],
      };
    case constants.GET_EXTRACT_REPORTS:
      return {
        ...state,
        extractReports: action.payload,
        loading: false,
      };
    case constants.REGENERATE_REPORT:
      const reports = state.extractReports;
      const index = findIndex(reports, { extract: action.payload.extract });

      if (index !== -1) {
        reports.splice(index, 1);
        reports.splice(index, 0, action.payload);
      }

      return {
        ...state,
        extractReports: [...reports],
      };

    default:
      return state;
  }
};

export default adminReducer;
