import moment from 'moment';

import { DATE_STARTING_YEAR } from 'domains/shared/constants/dateTimeFormats';

import baseService, { getNestedParamsRequest } from './baseService';

export function interpreterProfile(id) {
  return baseService.get(`/interpreters/${id}`);
}

export function getInterpreterByUserId(id) {
  return baseService.get(`/interpreters/${id}`);
}

export function interpreterProfileDetails(id) {
  return baseService.get(`/interpreters/${id}/interpreter_details`);
}

export function setAvailabilitySlots(slots, id) {
  return baseService.post('/bulk/slots', { slots, interpreter_id: id });
}
export function getGenericAvailibilitySlots(id) {
  return baseService.get(`/interpreters/${id}/generic_weekly_slots`);
}

export function saveGenericAvailibilitySlots(id, slots) {
  return baseService.post('/bulk/generic_weekly_slots', { generic_weekly_slots: slots, interpreter_id: id });
}

export function deleteGenericSlots(id, slots) {
  return baseService.delete('/bulk/generic_weekly_slots', { params: { ids: slots, interpreter_id: id } });
}

export function deleteAvailabilitySlots(slot_ids, id) {
  return baseService.delete('/bulk/slots', { params: { ids: slot_ids, interpreter_id: id } });
}

export function getAvailabilitySlots(id, params) {
  return getNestedParamsRequest(`/interpreters/${id}/slots`, params);
}

export function getOnCallAvailabilitySlots(id, start_date, end_date) {
  const body = {
    start_time: { gte: new Date(moment(start_date).format('YYYY-MM-DD HH:mm:ss')) },
    end_time: { lte: new Date(moment(end_date).format('YYYY-MM-DD HH:mm:ss')) },
    on_call: true,
  };

  return getNestedParamsRequest(`/interpreters/${id}/slots`, body);
}

export function getEventSlots(id, params = {}) {
  return getNestedParamsRequest('/bookings', { ...params, interpreter_id: id });
}

export function getweeklyExceptionSlots(id, params) {
  return getNestedParamsRequest(`/interpreters/${id}/slots`, params);
}

export function getShiftPlans({ id, startDate, endDate, keyName }) {
  const body = {
    interpreter_id: id,
    include: 'shift_detail',
    [keyName]: {
      gte: moment(startDate).format(DATE_STARTING_YEAR),
      lte: moment(endDate).format(DATE_STARTING_YEAR),
    },
    shift_interpreter_status: ['accepted'],
  };

  return getNestedParamsRequest('/shift_plans', body);
}

export function signupBasic(data) {
  return baseService.post('/interpreters', data);
}

export function editProfile(interpreterId, data) {
  return baseService.put(`/interpreters/${interpreterId}`, data);
}

export function sendTextMessage(phone_num) {
  return baseService.post('/send_message', { phone: phone_num });
}

export function editDetails(interpreterId, data) {
  return baseService.put(`/interpreters/${interpreterId}/interpreter_details`, data);
}

export function getInterpreter(interpreterId) {
  return baseService.get(`/interpreters/${interpreterId}`);
}

export function removeBookedInterpreter(id) {
  return baseService.delete(`/booking_interpreters/${id}`);
}

export function createBookedInterpreter(payload) {
  return baseService.post('/booking_interpreters', payload);
}

export function getInterpreterStatistics(interpreterId, payload) {
  return baseService.get(`/interpreters/${interpreterId}/interpreter_metrics`, { params: payload });
}

export function getInterpreterByEmail(email) {
  return baseService.get('/interpreters', { params: { email } });
}

export function createBulkBookingInterpreter(payload) {
  return baseService.put('/bulk/booking_interpreters', { booking_interpreters: payload });
}

export function removeBulkBookingInterpreters(payload) {
  return baseService.delete('/bulk/booking_interpreters', { params: { ids: payload } });
}

export function fetchInterpreterByRepeatSearch(payload) {
  return getNestedParamsRequest('/search/interpreters', payload);
}

export function getWalletBalance(uuid) {
  return baseService.get(`/interpreters/${uuid}/balances`);
}

export function updateAdjustment({ reviewerId, uuid, comment, type, amount }) {
  const body = {
    reviewer_id: reviewerId,
    comment,
    transaction_type: type,
    amount,
  };

  return baseService.post(`/interpreters/${uuid}/adjustments`, body);
}

export function bulkUpdateInterpreters(payload) {
  return baseService.put('/bulk/interpreters', { interpreters: payload });
}
