import LogRocket from 'logrocket';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { rootReducer } from 'reducers/root';

import { setStore } from './services/store';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['tabReducer'],
};

export const middlewares = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);

type StoreAndPersistor = {
  store: Store<unknown>;
  persistor: ReturnType<typeof persistStore>;
};

export const makeStore = (): StoreAndPersistor => {
  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk, LogRocket.reduxMiddleware()),
      // eslint-disable-next-line
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: unknown) => f
    )
  );

  setStore(store);
  const persistor = persistStore(store);

  return { store, persistor };
};
