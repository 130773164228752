import { API } from 'apis';

import { AxiosPromise } from 'domains/shared/types/axiosPromise';
import baseService from 'services/baseService';
import { AuthUser } from 'shared/types/authUser';

export const signIn = (formDataObj: unknown): AxiosPromise => baseService.post<unknown>(API.session, formDataObj);

export const signOut = (email: string): AxiosPromise => baseService.delete<void>(API.session, { params: { email } });

interface UpdateUserRequest {
  first_name: string;
  last_name: string;
  birth_month: number;
  birth_day: number;
  native_languages: string[];
  phone: string;
  profile_photo: {
    file_name: string;
    file_type: string;
    image: string;
  };
}

export const updateUser = (data: Partial<UpdateUserRequest>): AxiosPromise<AuthUser> =>
  baseService.put<AuthUser>(API.user, data);

export const resetPassword = (email: string): AxiosPromise => baseService.post(API.resetPassword, { email });

export const getCurrentUser = (signal?: AbortSignal): AxiosPromise<AuthUser> =>
  baseService.get<AuthUser>(API.currentUser, { signal });

type UpdateLastActiveOrganizationId = (data: { lastActiveOrganizationId: string }) => AxiosPromise<AuthUser>;

export const updateLastActiveOrganizationId: UpdateLastActiveOrganizationId = ({ lastActiveOrganizationId }) =>
  baseService.put<AuthUser>(API.user, { last_active_organization_id: lastActiveOrganizationId });

interface StartKudoSessionRequest {
  code: string;
}

interface StartKudoSessionResponse {
  expiresIn: number;
  idToken: string;
  tokenType: unknown;
}

export const startKudoSession = (body: StartKudoSessionRequest): AxiosPromise<StartKudoSessionResponse> =>
  baseService.post<StartKudoSessionResponse>(API.logIn, body);
