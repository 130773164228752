export const CREATE_SHIFT_PLANS = 'CREATE_SHIFT_PLANS';
export const GET_TIME_ZONE = 'GET_TIME_ZONE';
export const GET_FORM_DATA = 'GET_FORM_DATA';
export const GET_LANGUAGE_CODES = 'GET_LANGUAGE_CODES';
export const UPDATE_SHIFT_PLANS = 'UPDATE_SHIFT_PLANS';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
export const GET_LANGUAGES_DETAILS = 'GET_LANGUAGES_DETAILS';
export const GET_SHIFT_LIST = 'GET_SHIFT_LIST';
export const GET_SHIFT_LIST_COUNT = 'GET_SHIFT_LIST_COUNT';
export const GET_COMPLETED_SHIFT_LIST = 'GET_COMPLETED_SHIFT_LIST';
export const SEND_BULK_OFFERS_TO_SHIFT_INTERPRETERS = 'SEND_BULK_OFFERS_TO_SHIFT_INTERPRETERS';
export const NEW_SHIFT_INTERPRETER = 'NEW_SHIFT_INTERPRETER';
export const GET_ON_CALL_SHIFT_PLAN = 'GET_ON_CALL_SHIFT_PLAN';
export const GET_ON_CALL_SHIFT_PLAN_SUCCESS = 'GET_ON_CALL_SHIFT_PLAN_SUCCESS';
export const GET_ON_CALL_SHIFT_PLAN_FAILURE = 'GET_ON_CALL_SHIFT_PLAN_FAILURE';
export const GET_ON_CALL_SHIFT_PLAN_FEE = 'GET_ON_CALL_SHIFT_PLAN_FEE';
export const GET_ON_CALL_SHIFT_PLAN_FEE_SUCCESS = 'GET_ON_CALL_SHIFT_PLAN_FEE_SUCCESS';
export const GET_SHIFT_PLAN_RATE = 'GET_SHIFT_PLAN_RATE';
export const GET_SHIFT_PLAN_RATE_SUCCESS = 'GET_SHIFT_PLAN_RATE_SUCCESS';
export const GET_SHIFT_PLAN_RATE_FAILURE = 'GET_SHIFT_PLAN_RATE_FAILURE';
export const SET_LOADING = 'SET_LOADING';
export const ADD_BULK_SHIFT_INTERPRETERS = 'ADD_BULK_SHIFT_INTERPRETERS';
export const REMOVE_SHIFT_INTERPRETER = 'REMOVE_SHIFT_INTERPRETER';
export const REMOVE_SHIFT_PLAN = 'REMOVE_SHIFT_PLAN';
