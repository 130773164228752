// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { createAction as createActionDeprecated } from 'redux-actions';

import * as constants from 'constants/authConstants';
import { setActiveOrganization } from 'domains/client/slices/organizationSlice';
import { ChangeInterpreterProfilePicture } from 'domains/interpreter/actions';
import { AUTH_TOKEN_COOKIE_NAME, USER_ID_COOKIE_NAME } from 'domains/shared/constants/cookie';
import { AI_MARKETING_PAGE_LINK } from 'domains/shared/constants/externalLinks';
import { UserRole } from 'domains/shared/constants/UserRole';
import * as authService from 'domains/shared/services/auth';
import { parseResponseErrors } from 'helpers/notificationHelper';
import { NotificationType, showNotification } from 'helpers/showNotification';
import { AuthUser } from 'shared/types/authUser';

import { clearBrowserStorageAndCookies } from '../helpers/browserStorage';

export const GetSignIn = createActionDeprecated(constants.GET_SIGNIN);
export const GetSignInSuccess = createActionDeprecated(constants.GET_SIGNIN_SUCCESS);
export const GetSignInError = createActionDeprecated(constants.GET_SIGNIN_ERROR);
export const SignOutSuccess = createActionDeprecated(constants.SIGNOUT_SUCCESS);

export const UpdateUserSuccess = createActionDeprecated(constants.UPDATE_USER_SUCCESS);

export const updateLastActiveOrganizationId = createAction<string>('UPDATE_LAST_ACTIVE_ORGANIZATION_ID');
export const updateCurrentUser = createAction<AuthUser>('UPDATE_CURRENT_USER');

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function setSignin(dispatch, response, onSuccess) {
  if (response.status === 200 && constants.VALID_ROLES.some((element) => response.data.roles.includes(element))) {
    Cookies.set(USER_ID_COOKIE_NAME, response.data.id);
    dispatch(GetSignInSuccess(response.data));
    const role = response.data.roles.length > 1 ? UserRole.DUAL_ROLE : response.data.roles[0];

    onSuccess(response.data.id, role);
  } else {
    showNotification({ message: 'You are not authorized' });
    Cookies.remove(AUTH_TOKEN_COOKIE_NAME);
    dispatch(GetSignInError());
  }
}

export function signIn(formDataObj, onSuccess, setErrorAndLoading) {
  return (dispatch) => {
    dispatch(GetSignIn());

    return authService
      .signIn(formDataObj)
      .then((response) => {
        setSignin(dispatch, response, onSuccess);
      })
      .catch((error) => {
        if (error.response) {
          setErrorAndLoading(false, error.response.data.message);
        } else {
          setErrorAndLoading(false, 'Server is not responding.');
        }

        Cookies.remove(AUTH_TOKEN_COOKIE_NAME);
        dispatch(GetSignInError());
      });
  };
}

export const signOut = (email, isAiClient, history) => (dispatch) =>
  authService
    .signOut(email)
    .then(() => {
      dispatch(SignOutSuccess());

      if (isAiClient) {
        window.location.href = AI_MARKETING_PAGE_LINK;
      } else {
        history.replace('/');
      }
    })
    .catch((error) => {
      showNotification({ message: parseResponseErrors(error) });
    })
    .finally(() => {
      clearBrowserStorageAndCookies();
    });

export function updateUser(data) {
  return (dispatch) => authService.updateUser(data).then((response) => dispatch(UpdateUserSuccess(response.data)));
}

export function uploadImage(data, callback, setIsUploading) {
  return (dispatch) =>
    authService
      .updateUser(data)
      .then((response) => {
        dispatch(UpdateUserSuccess(response.data));
        dispatch(ChangeInterpreterProfilePicture(response.data.profile_photo));
        callback();
        showNotification({ message: 'Picture uploaded successfully!', type: NotificationType.SUCCESS });
      })
      .catch((error) => {
        showNotification({ message: parseResponseErrors(error) });
        setIsUploading(false);
      });
}

export function resetPassword(email, setShowConfirmationMessage) {
  return () =>
    authService
      .resetPassword(email)
      .then(() => setShowConfirmationMessage(true))
      .catch((error) => showNotification({ message: parseResponseErrors(error) }));
}

export const getCurrentUser = (onSuccess, onError) => async (dispatch) => {
  dispatch(GetSignIn());

  return authService
    .getCurrentUser()
    .then((response) => {
      setSignin(dispatch, response, onSuccess);
      dispatch(
        setActiveOrganization(response.data.last_active_organization_id || response.data.external_organization_id)
      );
    })
    .catch((error) => {
      onError();
      dispatch(GetSignInError());
      showNotification({ message: parseResponseErrors(error) });
    });
};

export const startKudoSession =
  ({ payload, onSuccess, onError }) =>
  (dispatch) =>
    authService
      .startKudoSession(payload)
      .then(() => {
        dispatch(getCurrentUser(onSuccess, onError));
      })
      .catch((error) => {
        showNotification({ message: parseResponseErrors(error) });
        onError();
      });
