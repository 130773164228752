import { addMinutes } from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';
import { capitalize } from 'lodash-es';

import { BookingRequestStatus } from 'constants/BookingRequestStatus';
import { ZOOM_LANGUAGE_TYPES } from 'domains/client/constants';
import { DATE_WITH_TIME, TIME_24H } from 'domains/shared/constants/dateTimeFormats';
import { convertTimezoneToUtc } from 'domains/shared/helpers/datetime/convertTimeToUtc';
import { BookingRequest } from 'domains/shared/types/bookingRequest';
import { BookingRequestBody } from 'domains/shared/types/bookingRequestBody';
import { BookingType } from 'domains/shared/types/bookingType';
import { Combination } from 'domains/shared/types/combination';
import { MeetingDetails } from 'domains/shared/types/meetingDetails';
import { IntegratedPlatform, MeetingPlatform, NonIntegratedPlatform } from 'domains/shared/types/meetingPlatform';
import { MeetingProvider } from 'domains/shared/types/meetingProvider';
import {
  BookingLanguage,
  SelfServiceBookingWithDetails,
  SelfServiceBookingWithUpdateParams,
} from 'domains/shared/types/selfServiceBooking';
import { ZoomAccountType } from 'domains/shared/types/zoomAccountType';
import { calculateTimeDifferenceInMinutes } from 'helpers/clientHelper';
import { ZoomLanguage } from 'shared/types/zoomLanguage';

interface GetBookingDataForPaymentPage {
  booking: BookingRequest;
  meeting: MeetingDetails;
  zoomLanguages: ZoomLanguage[];
  values: {
    combinations: Combination[];
    subjectMatter: string;
  };
}

export const constructLanguagesForPaymentPage = (
  languageCombinations: Combination[],
  zoomLanguages: ZoomLanguage[]
): [BookingLanguage] => {
  const languages = languageCombinations.map((combination) => {
    const isKUDOLanguage = combination.interpreters[0].booked_via === BookingType.KUDO;

    if (isKUDOLanguage) {
      const lang = zoomLanguages.find((language) => language.zoom_code === combination.target_language);

      if (lang) {
        return { value: lang.code, label: capitalize(lang.name) };
      }
    }

    return null;
  });

  return languages.filter((language) => !!language) as [BookingLanguage];
};

export const getLanguagesForPaymentIntent = (combinations: Combination[], zoomLanguages: ZoomLanguage[]): string[] =>
  constructLanguagesForPaymentPage(combinations, zoomLanguages)
    .map((bookingLanguage) => bookingLanguage.value)
    .concat(['ENG']);

export const getValidBookingLanguages = (values: SelfServiceBookingWithDetails): string[] => {
  const languages = values.languages.reduce(
    (total, language) => {
      if (language.value) {
        total.push(language.value);
      }

      return total;
    },
    ['ENG']
  );

  // to remove potential duplicates
  return Array.from(new Set(languages));
};

export const getBookingDataForPaymentPage = ({
  booking,
  meeting,
  values,
  zoomLanguages,
}: GetBookingDataForPaymentPage): SelfServiceBookingWithDetails => {
  if (booking?.meeting_provider === MeetingProvider.NON_INTEGRATED) {
    const startZonedDateTime = utcToZonedTime(booking.meeting_actual_start_time, booking.time_zone_code);

    return {
      startDate: booking.meeting_actual_start_time,
      startTime: tzFormat(startZonedDateTime, TIME_24H, {
        timeZone: booking.time_zone_code,
      }),
      startDateTime: tzFormat(startZonedDateTime, DATE_WITH_TIME, {
        timeZone: booking.time_zone_code,
      }),
      timezone: booking.time_zone_code,
      duration: calculateTimeDifferenceInMinutes(booking.meeting_actual_start_time, booking.meeting_actual_end_time),
      languages: constructLanguagesForPaymentPage(values.combinations, zoomLanguages),
      meetingDescription: booking.meeting_description,
      meetingSubject: values.subjectMatter,
      meetingTitle: booking.meeting_title,
      zoomAccount: ZoomAccountType.BUSINESS,
      platform: booking.nonintegrated_platform as NonIntegratedPlatform,
      meetingProvider: MeetingProvider.NON_INTEGRATED,
    };
  }

  const startZonedDateTime = utcToZonedTime(meeting.start_time, meeting.timezone);
  const meetingProvider = getMeetingProvider(meeting.platform);

  return {
    startDate: meeting.start_time,
    startTime: tzFormat(startZonedDateTime, TIME_24H, {
      timeZone: meeting.timezone,
    }),
    startDateTime: tzFormat(startZonedDateTime, DATE_WITH_TIME, {
      timeZone: meeting.timezone,
    }),
    timezone: meeting.timezone,
    duration: meeting.duration,
    languages: constructLanguagesForPaymentPage(values.combinations, zoomLanguages),
    meetingDescription: meeting.agenda,
    meetingSubject: values.subjectMatter,
    meetingTitle: meeting.topic,
    zoomAccount: ZoomAccountType.BUSINESS,
    platform: IntegratedPlatform.ZOOM,
    meetingProvider,
  };
};

interface GetCreateBookingParams {
  bookingData: SelfServiceBookingWithUpdateParams;
  meetingId?: number | null;
  userId: number;
  joinUrl: string;
  meetingProvider: MeetingProvider;
  organizationId?: string;
}

export const getCreateBookingParams = ({
  bookingData,
  meetingId,
  userId,
  joinUrl,
  meetingProvider,
  organizationId,
}: GetCreateBookingParams): BookingRequestBody => ({
  ...(meetingId ? { meeting_id: meetingId } : {}),
  meeting_title: bookingData.meetingTitle,
  meeting_description: bookingData.meetingDescription,
  status: BookingRequestStatus.NEW_REQUEST,
  meeting_provider: meetingProvider,
  client_id: null,
  skip_language_validation: true,
  user_id: userId,
  meeting_urls: {
    join_url: joinUrl,
  },
  starts_at: convertTimezoneToUtc(
    bookingData.interpretationStartTime ? bookingData.interpretationStartTime : bookingData.startDateTime,
    bookingData.timezone
  ),
  ends_at: convertTimezoneToUtc(
    bookingData.interpretationEndTime
      ? bookingData.interpretationEndTime
      : addMinutes(new Date(bookingData.startDateTime), bookingData.duration).toString(),
    bookingData.timezone
  ),
  meeting_actual_start_time: convertTimezoneToUtc(bookingData.startDateTime, bookingData.timezone),
  meeting_actual_end_time: convertTimezoneToUtc(
    addMinutes(new Date(bookingData.startDateTime), bookingData.duration).toString(),
    bookingData.timezone
  ),
  time_zone_code: bookingData.timezone,
  subject_matter_id: bookingData.meetingSubject,
  languages: ['ENG', ...bookingData.languages.map((item) => item.value)],
  pin: bookingData.pin ?? '',
  ...(organizationId ? { organization_id: organizationId } : null),
  region: bookingData.region,
});

export const getMeetingPlatform = (provider: MeetingProvider): MeetingPlatform => {
  if (provider === MeetingProvider.ZOOM_BASIC) {
    return MeetingPlatform.ZOOM_BASIC;
  }

  if (provider === MeetingProvider.ZOOM_WEBINAR) {
    return MeetingPlatform.ZOOM_WEBINAR;
  }

  return MeetingPlatform.ZOOM_MEETING;
};

export const getMeetingProvider = (platform: MeetingPlatform | NonIntegratedPlatform): MeetingProvider => {
  if (platform === MeetingPlatform.ZOOM_WEBINAR) {
    return MeetingProvider.ZOOM_WEBINAR;
  }

  if (platform === MeetingPlatform.ZOOM_BASIC) {
    return MeetingProvider.ZOOM_BASIC;
  }

  if (platform === MeetingPlatform.ZOOM_MEETING) {
    return MeetingProvider.ZOOM_MEETING;
  }

  return MeetingProvider.NON_INTEGRATED;
};

export const getZoomMeetingForCalendar = (bookingData: SelfServiceBookingWithUpdateParams): MeetingDetails => ({
  topic: bookingData.meetingTitle,
  duration: bookingData.duration,
  start_time: bookingData.startDateTime,
  timezone: bookingData.timezone,
  join_url: `${bookingData.meetingLink}`,
  agenda: bookingData.meetingDescription,
  settings: bookingData?.zoomSettings?.settings,
  type: ZOOM_LANGUAGE_TYPES.scheduled,
  pin: bookingData.pin,
  platform: getMeetingPlatform(bookingData.meetingProvider),
  id: bookingData.meetingId,
});
