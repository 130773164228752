/**
 * @see - 0009, 09 May 2022
 */
export const DAY_WITH_FULL_MONTH = 'dddd, DD MMMM YYYY';
/**
 * @see - May 09, 2022
 */
export const DATE_WITH_SHORT_MONTH = 'MMM DD, YYYY';
/**
 * @see - 0009, May 09, 2022, 16:03
 */
export const DATE_TIME_24_HOURS = 'dddd, MMMM DD, YYYY, HH:mm';
/**
 * @see - 16:03:51
 */
export const TIME_FORMAT = 'HH:mm:ss';
/**
 * @see - 05-09-2022
 */
export const MONTH_DAY_YEAR_DATE_DASH_FORMAT = 'MM-dd-yyyy';
/**
 * @see - 4:03 PM
 */
export const SHIFT_TIME_SELECT_FORMAT = 'h:mm a';
/**
 * @see - 2022-05-09 16:03
 */
export const DATE_TIME_STARTING_YEAR_24_HOURS = 'YYYY-MM-DD HH:mm';
/**
 * @see - 2022-05-09
 */
export const DATE_STARTING_YEAR = 'YYYY-MM-DD';
/**
 * @see - 15:58 129th May 2022
 */
export const TIME_WITH_FULL_DATE = 'HH:mm Do MMM YYYY';
/**
 * @see - 03:58 PM 129th May 2022
 */
export const TIME_12H_WITH_FULL_DATE = 'hh:mm A Do MMM YYYY';
/**
 * @see - May 9, 2022
 */
export const DATE_STARTING_FULL_MONTH = 'LLLL d, yyyy';
/**
 * @see - 15:54
 */
export const TIME_24H = 'HH:mm';
/**
 * @see - 2022-05-09T15:54:83
 */
export const DATE_WITH_TIME = "yyyy-MM-dd'T'HH:mm:SS";
/**
 * @see - 2022-05-09T15:48:84Z
 */
export const DATE_WITH_TIME_ZULU = "yyyy-MM-dd'T'HH:mm:SS'Z'";
/**
 * @see - 04 Sep 2022
 */
export const MOMENT_DAY_3_LETTER_MONTH_YEAR = 'DD MMM YYYY';

// dateFns formats
/**
 * @see - 2022-05-09
 */
export const FNS_DATE_STARTING_YEAR = 'yyyy-MM-dd';
/**
 * @see - 09 May 2022
 */
export const FNS_DAY_3_LETTER_MONTH_YEAR = 'dd MMM yyyy';
/**
 * @see - Mon May 09 2022
 */
export const FNS_DAY_MONTH_DATE_YEAR = 'eee LLL dd yyyy';
/**
 * @see - 2022-05-09 16:03
 */
export const FNS_DATE_TIME_STARTING_YEAR_24_HOURS = 'yyyy-MM-dd HH:mm';
/**
 * @see - 09/05/2022
 */
export const FNS_INTERNATIONAL_DATE = 'dd/MM/yyyy';
/**
 * @see - 05/09/2022
 */
export const FNS_AMERICAN_DATE = 'MM/dd/yyyy';
/**
 * @see - 11:30 PM
 */
export const FNS_TIME_12H = 'hh:mm a';
/**
 * @see - Mar 09, 2022
 */
export const FNS_DATE_WITH_SHORT_MONTH = 'LLL dd, yyyy';
/**
 * @see - March 09, 2022
 */
export const FNS_DATE_WITH_FULL_MONTH = 'LLLL dd, yyyy';
/**
 * @see - 0009, May 09, 2022, 16:03
 */
export const FNS_DATE_TIME_24_HOURS = 'eeee, MMMM dd, yyyy, HH:mm';
/**
 * @see - 0009, May 09, 2022, 04:03 PM
 */
export const FNS_DATE_TIME_12_HOURS = 'eeee, MMMM dd, yyyy, hh:mm a';
/**
 * @see - Tuesday, July 5th, 2022
 */
export const FNS_DATE_FULL_SPELT = 'EEEE, MMMM do, yyyy';
/**
 * @see - Tuesday, 05 Jul 2022
 */
export const FNS_FULL_DAY_3_LETTER_MONTH_YEAR = 'EEEE, dd MMM yyyy';

// flatpicker formats
/**
 * @see - 09/05/2022
 */
export const FLATPICKER_INTERNATIONAL_DATE = 'd/m/Y';
/**
 * @see - 05/09/2022
 */
export const FLATPICKER_AMERICAN_DATE = 'm/d/Y';
