import { BookingRequestType } from 'shared/types/BookingRequestType';

export const TAB_CONSTANTS = {
  REVIEWER: 'reviewer',
  ADMIN: 'admin',
  CLIENT: 'client',
  INTERPRETER: 'interpreter',
  BLOCKED_USERS: 'blocked_users',
  RESTRICTED_CLIENTS: 'restricted_clients',
  CLIENTS: 'client',
  SLOW_TRACK: BookingRequestType.SLOW_TRACK,
  FAST_TRACK: BookingRequestType.FAST_TRACK,
};

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const CREATE_REVIEWER = 'CREATE_REVIEWER';
export const CREATE_REVIEWER_SUCCESS = 'CREATE_REVIEWER_SUCCESS';

export const DELETE_REVIEWER_SUCCESS = 'DELETE_REVIEWER_SUCCESS';
export const DELETE_REVIEWER_ERROR = 'DELETE_REVIEWER_ERROR';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';

export const GET_BLOCKED_USERS = 'GET_BLOCKED_USERS';
export const BLOCK_USERS = 'BLOCK_USERS';
export const UN_BLOCK_USERS = 'UN_BLOCK_USERS';

export const GET_SYSTEM_LANGUAGES = 'GET_SYSTEM_LANGUAGES';
export const SET_LOADING = 'SET_LOADING';

export const GET_EXTRACT_REPORTS = 'GET_EXTRACT_REPORTS';
export const REGENERATE_REPORT = 'REGENERATE_REPORT';
