export const GET_ALL_ADDITIONAL_COST = 'GET_ALL_ADDITIONAL_COST';
export const GET_ALL_ADDITIONAL_COST_SUCCESS = 'GET_ALL_ADDITIONAL_COST_SUCCESS';
export const GET_ALL_ADDITIONAL_COST_ERROR = 'GET_ALL_ADDITIONAL_COST_ERROR';

export const TAB_CONSTANTS = {
  INTERPRETER: 'INTERPRETER',
  CLIENT: 'CLIENT',
};

export const CLIENT_HEADER = [
  { label: 'ID', key: 'id' },
  { label: 'Booking ID', key: 'booking_id' },
  { label: 'Meeting ID', key: 'meeting_id' },
  { label: 'Client ID', key: 'client_id' },
  { label: 'Client Name', key: 'client_name' },
  { label: 'Amount in USD', key: 'amount' },
  { label: 'Languages', key: 'languages' },
  { label: 'Interpreter Count', key: 'interpreter_count' },
  { label: 'Total Interpreter hours', key: 'no_of_interpreter_credits' },
  { label: 'Starts at', key: 'starts_at' },
  { label: 'Ends at', key: 'ends_at' },
  { label: 'Created at', key: 'created_at' },
];

export const INTERPRETER_HEADER = [
  { label: 'ID', key: 'id' },
  { label: 'Source', key: 'source' },
  { label: 'Source ID', key: 'source_id' },
  { label: 'Meeting ID', key: 'meeting_id' },
  { label: 'Interpreter ID', key: 'interpreter_id' },
  { label: 'Interpreter Name', key: 'interpreter_name' },
  { label: 'Amount in USD', key: 'amount' },
  { label: 'Languages', key: 'languages' },
  { label: 'Interpreter Hours', key: 'no_of_interpreter_credits' },
  { label: 'Starts at', key: 'starts_at' },
  { label: 'Created at', key: 'created_at' },
];

export const GET_ADDITIONAL_COST_WITH_OUT_PAGINATION = 'GET_ADDITIONAL_COST_WITH_OUT_PAGINATION';
export const GET_ADDITIONAL_COST_WITH_OUT_PAGINATION_SUCCESS = 'GET_ADDITIONAL_COST_WITH_OUT_PAGINATION_SUCCESS';
export const GET_ADDITIONAL_COST_WITH_OUT_PAGINATION_ERROR = 'GET_ADDITIONAL_COST_WITH_OUT_PAGINATION_ERROR';

export const GET_ALL_INTERPRETERS_TRANSACTIONS = 'GET_ALL_INTERPRETERS_TRANSACTIONS';
export const GET_ALL_INTERPRETERS_TRANSACTIONS_ERROR = 'GET_ALL_INTERPRETERS_TRANSACTIONS_ERROR';
export const GET_ALL_INTERPRETERS_TRANSACTIONS_SUCCESS = 'GET_ALL_INTERPRETERS_TRANSACTIONS_SUCCESS';
export const GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION = 'GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION';
export const GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION_SUCCESS =
  'GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION_SUCCESS';
export const GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION_ERROR =
  'GET_INTERPRETERS_TRANSACTIONS_WITH_OUT_PAGINATION_ERROR';

export const ClientTableHeader = [
  'IndexID',
  'ClientID',
  'ClientName',
  'BookingID',
  'MeetingID',
  'AmountInUSD',
  'Languages',
  'InterpretersCount',
  'TotalInterpreterHours',
  'StartsAt',
  'EndsAt',
  'CreatedAt',
];

export const InterpreterTableHeader = [
  'IndexID',
  'Source',
  'SourceID',
  'MeetingID',
  'InterpreterID',
  'InterpreterName',
  'AmountInUSD',
  'Languages',
  'InterpreterHours',
  'StartsAt',
  'EndsAt',
  'CreatedAt',
];
