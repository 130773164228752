import { Link } from 'react-router-dom';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';

import { ReactComponent as DismissIcon } from 'assets/images/close_hover.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/failure.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info-in-circle.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/triangle-exclamation-solid.svg';
import { NotificationMessage } from 'components/notifications/NotificationMessage';
import { Notification } from 'shared/types/notification';

import { generateNotificationUrl } from './notificationHelper';

export enum NotificationType {
  NOTIFICATION = 'notification',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

const getIcon = (type: NotificationType): JSX.Element | undefined => {
  if (type === NotificationType.SUCCESS) {
    return <SuccessIcon />;
  }

  if (type === NotificationType.ERROR) {
    return <ErrorIcon />;
  }

  if (type === NotificationType.INFO) {
    return <InfoIcon />;
  }

  if (type === NotificationType.WARNING) {
    return <WarningIcon />;
  }
};

type ShowNotification = (params: {
  message: string | JSX.Element;
  type?: NotificationType;
  notification?: Notification;
  readNotification?: (notificationId: number) => void;
  toastOptions?: ToastOptions;
}) => void;

export const showNotification: ShowNotification = ({
  message,
  type = NotificationType.ERROR,
  notification,
  readNotification,
  toastOptions = {},
}) => {
  const Msg = (): JSX.Element | null =>
    notification ? (
      <Link
        to={{
          pathname: generateNotificationUrl(notification),
          state: {
            key: notification.id,
          },
        }}
        onClick={() => readNotification && readNotification(notification?.id)}
      >
        <NotificationMessage notification={notification} />
      </Link>
    ) : null;

  toast(type === NotificationType.NOTIFICATION ? Msg : message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    type: type as unknown as TypeOptions,
    progress: undefined,
    icon: getIcon(type),
    className: 'toast-message',
    closeButton: <DismissIcon />,
    ...toastOptions,
  });
};
