import Cookies from 'js-cookie';

import {
  AI_CLIENT_USER_COOKIE_NAME,
  AUTH_TOKEN_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
  USER_ROLE_COOKIE_NAME,
  XUSER_TOKEN_COOKIE_NAME,
} from 'domains/shared/constants/cookie';

export const cleanUpBySignOut = (): void => {
  Cookies.remove(AUTH_TOKEN_COOKIE_NAME);
  Cookies.remove(XUSER_TOKEN_COOKIE_NAME);
  Cookies.remove(USER_ROLE_COOKIE_NAME);
  Cookies.remove(USER_ID_COOKIE_NAME);
  Cookies.remove(AI_CLIENT_USER_COOKIE_NAME);
};
