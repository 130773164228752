/* eslint-disable no-case-declarations */
import produce from 'immer';
import { findIndex } from 'lodash-es';

import {
  CLEAR_ALL_UNREAD_NOTIFICATIONS,
  CLEAR_NOTIFICATION,
  DELETE_NOTIFICATION,
  EMPTY_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_PAGINATED_NOTIFICATIONS,
  SET_LOADED_NOTIFICATIONS,
  UPDATE_READ_NOTIFICATIONS,
} from '../constants/notificationConstants';
import { getUnreadNotificationsCount } from '../helpers/notificationHelper';

export const initialState = {
  notifications: [],
  dropdownNotifications: [],
  profileNotifications: [],
  paginatedNotifications: [],
  unreadNotificationsCount: 0,
  pagination: {},
};

const notificationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      let dropDownNotifications;

      if (action.payload.reset) dropDownNotifications = [...action.payload.results];
      else dropDownNotifications = [...state.dropdownNotifications, ...action.payload.results];

      return {
        ...state,
        notifications: action.payload.results,
        dropdownNotifications: dropDownNotifications,
        unreadNotificationsCount: action.payload.unread_notifications,
      };
    case SET_LOADED_NOTIFICATIONS:
      let loadedNotifications;

      if (action.payload.reset) loadedNotifications = [...action.payload.results];
      else loadedNotifications = [...state.profileNotifications, ...action.payload.results];

      return {
        ...state,
        profileNotifications: loadedNotifications,
        unreadNotificationsCount: action.payload.unread_notifications,
      };
    case GET_PAGINATED_NOTIFICATIONS:
      return {
        ...state,
        paginatedNotifications: action.payload.results,
        pagination: action.payload.page_info,
      };
    case UPDATE_READ_NOTIFICATIONS:
      const notificationsArr = state.dropdownNotifications;
      const profileNotificationsArr = state.profileNotifications;
      const index = findIndex(state.dropdownNotifications, { id: action.payload.result.id });

      if (index !== -1) {
        notificationsArr.splice(index, 1);
        notificationsArr.splice(index, 0, action.payload.result);
      }

      const pNotificationIndex = findIndex(state.profileNotifications, { id: action.payload.result.id });

      if (pNotificationIndex !== -1) {
        profileNotificationsArr.splice(pNotificationIndex, 1);
        profileNotificationsArr.splice(pNotificationIndex, 0, action.payload.result);
      }

      return {
        ...state,
        dropdownNotifications: [...notificationsArr],
        profileNotifications: [...profileNotificationsArr],
        unreadNotificationsCount: action.payload.unread_notifications,
      };
    case EMPTY_NOTIFICATIONS:
      return {
        ...initialState,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notif) => notif.id !== action.payload.id),
        profileNotifications: state.profileNotifications.filter((notif) => notif.id !== action.payload.id),
        dropdownNotifications: state.dropdownNotifications.filter((notif) => notif.id !== action.payload.id),
        paginatedNotifications: state.paginatedNotifications.filter((notif) => notif.id !== action.payload.id),
        unreadNotificationsCount: action.payload.read_at
          ? state.unreadNotificationsCount
          : state.unreadNotificationsCount - 1,
      };
    case CLEAR_ALL_UNREAD_NOTIFICATIONS:
      const paginated_notifications = produce(state.paginatedNotifications, (draft) => {
        draft.forEach((notification) => {
          for (let i = 0; i < action.payload.length; i += 1) {
            if (notification.id === action.payload[i].id) {
              notification.read_at = action.payload[i].read_at;
              notification.cleared_at = action.payload[i].cleared_at;
            }
          }
        });
      });
      const profile_notifications = produce(state.profileNotifications, (draft) => {
        draft.forEach((notification) => {
          for (let i = 0; i < action.payload.length; i += 1) {
            if (notification.id === action.payload[i].id) {
              notification.read_at = action.payload[i].read_at;
              notification.cleared_at = action.payload[i].cleared_at;
            }
          }
        });
      });

      return {
        ...state,
        notifications: action.payload,
        dropdownNotifications: action.payload.filter((notif) => notif.cleared_at == null),
        paginatedNotifications: paginated_notifications,
        profileNotifications: profile_notifications,
        unreadNotificationsCount: getUnreadNotificationsCount(action.payload),
      };
    case CLEAR_NOTIFICATION:
      const paginatedNotificationArr = state.paginatedNotifications;
      const profileNotificationArr = state.profileNotifications;
      const notificationIndex = findIndex(state.dropdownNotifications, { id: action.payload.result.id });
      const paginatedNotificationIndex = findIndex(state.paginatedNotifications, { id: action.payload.result.id });
      const profileNotificationIndex = findIndex(state.profileNotifications, { id: action.payload.result.id });

      if (paginatedNotificationIndex !== -1) {
        paginatedNotificationArr.splice(paginatedNotificationIndex, 1);
        paginatedNotificationArr.splice(paginatedNotificationIndex, 0, action.payload.result);
      }

      if (profileNotificationIndex !== -1) {
        profileNotificationArr.splice(profileNotificationIndex, 1);
        profileNotificationArr.splice(profileNotificationIndex, 0, action.payload.result);
      }

      return {
        ...state,
        dropdownNotifications: state.dropdownNotifications.filter((notif) => notif.id !== action.payload.result.id),
        paginatedNotifications: [...paginatedNotificationArr],
        profileNotifications: [...profileNotificationArr],
        unreadNotificationsCount: state.dropdownNotifications[notificationIndex].read_at
          ? state.unreadNotificationsCount
          : state.unreadNotificationsCount - 1,
      };

    default:
      return state;
  }
};

export default notificationReducer;
