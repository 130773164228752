export enum InterpreterStatus {
  PENDING_INVITE = 'pending_invite',
  NEW_OFFER = 'new_offer',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  COMPLETED = 'completed',
  UNDER_REVIEW = 'under_review',
  NOT_RESPONDED = 'not_responded',
  NOT_AVAILABLE = 'not_available',
  REMOVED = 'removed',
  CANCELLED = 'cancelled',
}
