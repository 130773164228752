import moment from 'moment';

import { DATE_TIME_STARTING_YEAR_24_HOURS } from 'domains/shared/constants/dateTimeFormats';

type BookedTimeSlot = {
  start_time: string;
  end_time: string;
  day?: string;
};

const DAY_IN_HALF_HOUR_INTERVALS: Record<number, string> = {
  1: '00:00 AM',
  2: '00:30 AM',
  3: '01:00 AM',
  4: '01:30 AM',
  5: '02:00 AM',
  6: '02:30 AM',
  7: '03:00 AM',
  8: '03:30 AM',
  9: '04:00 AM',
  10: '04:30 AM',
  11: '05:00 AM',
  12: '05:30 AM',
  13: '06:00 AM',
  14: '06:30 AM',
  15: '07:00 AM',
  16: '07:30 AM',
  17: '08:00 AM',
  18: '08:30 AM',
  19: '09:00 AM',
  20: '09:30 AM',
  21: '10:00 AM',
  22: '10:30 AM',
  23: '11:00 AM',
  24: '11:30 AM',
  25: '12:00 PM',
  26: '12:30 PM',
  27: '13:00 PM',
  28: '13:30 PM',
  29: '14:00 PM',
  30: '14:30 PM',
  31: '15:00 PM',
  32: '15:30 PM',
  33: '16:00 PM',
  34: '16:30 PM',
  35: '17:00 PM',
  36: '17:30 PM',
  37: '18:00 PM',
  38: '18:30 PM',
  39: '19:00 PM',
  40: '19:30 PM',
  41: '20:00 PM',
  42: '20:30 PM',
  43: '21:00 PM',
  44: '21:30 PM',
  45: '22:00 PM',
  46: '22:30 PM',
  47: '23:00 PM',
  48: '23:30 PM',
};

/**
 * calculates current bookedtime cells based on given timeslots
 */
export const getBookedTimeSlots = (timeSlots: boolean[][], weekDays: Date[]): BookedTimeSlot[] => {
  if (!timeSlots) {
    return [];
  }

  const bookedTimeSlots: BookedTimeSlot[] = [];

  for (let i = 0; i < timeSlots.length; i += 1) {
    for (let j = 0; j < weekDays.length; j += 1) {
      const timeSlot = DAY_IN_HALF_HOUR_INTERVALS[i + 1];

      if (!timeSlots[i][j] || !timeSlot) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const hours = timeSlot.substring(0, 2);
      const minutes = timeSlot.split(':').pop()?.substring(0, 2);
      const startTime = moment(weekDays[j]).add(hours, 'h').add(minutes, 'm');

      bookedTimeSlots.push({
        start_time: startTime.format(DATE_TIME_STARTING_YEAR_24_HOURS),
        end_time: moment(startTime).add(30, 'minutes').format(DATE_TIME_STARTING_YEAR_24_HOURS),
      });
    }
  }

  return bookedTimeSlots;
};
