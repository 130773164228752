export const RESET_MEETINGS_LIST = 'RESET_MEETINGS_LIST';
export const GET_ZOOM_MEETINGS = 'GET_ZOOM_MEETINGS';
export const CLEANUP_CLIENT_MEETING_PAGE = 'CLEANUP_CLIENT_MEETING_PAGE';
export const GET_ZOOM_MEETINGS_SUCCESS = 'GET_ZOOM_MEETINGS_SUCCESS';
export const GET_PREVIOUS_ZOOM_MEETINGS_SUCCESS = 'GET_PREVIOUS_ZOOM_MEETINGS_SUCCESS';
export const GET_CANCELLED_ZOOM_MEETINGS_SUCCESS = 'GET_CANCELLED_ZOOM_MEETINGS_SUCCESS';
export const GET_ZOOM_MEETINGS_FAILURE = 'GET_ZOOM_MEETINGS_FAILURE';

export const GET_ZOOM_MEETING_DETAILS = 'GET_ZOOM_MEETING_DETAILS';
export const GET_ZOOM_MEETING_DETAILS_SUCCESS = 'GET_ZOOM_MEETING_DETAILS_SUCCESS';
export const GET_ZOOM_MEETING_DETAILS_FAILURE = 'GET_ZOOM_MEETING_DETAILS_FAILURE';
export const GET_ZOOM_LANGUAGES = 'GET_ZOOM_LANGUAGES';
export const GET_ZOOM_TIMEZONES = 'GET_ZOOM_TIMEZONES';

export const GET_KUDO_BOOKING_INTERPRETERS = 'GET_KUDO_BOOKING_INTERPRETERS';
export const GET_KUDO_BOOKING_INTERPRETERS_SUCCESS = 'GET_KUDO_BOOKING_INTERPRETERS_SUCCESS';
export const GET_KUDO_BOOKING_INTERPRETERS_FAILURE = 'GET_KUDO_BOOKING_INTERPRETERS_FAILURE';

export const GET_ZOOM_BOOKING = 'GET_ZOOM_BOOKING';
export const GET_ZOOM_BOOKING_SUCCESS = 'GET_ZOOM_BOOKING_SUCCESS';

export const SET_BOOKING_REQUEST_PREPARATION_MATERIAL = 'SET_BOOKING_REQUEST_PREPARATION_MATERIAL';
export const DELETE_BOOKING_REQUEST_PREPARATION_MATERIAL = 'DELETE_BOOKING_REQUEST_PREPARATION_MATERIAL';

export const SET_ZOOM_AUTHORIZED = 'SET_ZOOM_AUTHORIZED';
export const SET_ZOOM_DEAUTHORIZED = 'SET_ZOOM_DEAUTHORIZED';
export const FORCE_SET_ZOOM_AUTHORIZED_STATE = 'FORCE_SET_ZOOM_AUTHORIZED_STATE';

export const DELETE_MEETING = 'DELETE_MEETING';
export const DELETE_WEBINAR = 'DELETE_WEBINAR';
export const DELETE_ZOOM_BASIC_MEETING = 'DELETE_ZOOM_BASIC_MEETING';
export const DELETE_NON_INTEGRATED_MEETING = 'DELETE_NON_INTEGRATED_MEETING';

export const GET_CLIENT_BALANCE_SUCCESS = 'GET_CLIENT_BALANCE_SUCCESS';
export const GET_CLIENT_BALANCE_REQUEST = 'GET_CLIENT_BALANCE_REQUEST';

export const UPDATE_AGNOSTIC_MEETING_INTERPRETERS = 'UPDATE_AGNOSTIC_MEETING_INTERPRETERS';
export const UPDATE_AGNOSTIC_MEETING_INTERPRETERS_SUCCESS = 'UPDATE_AGNOSTIC_MEETING_INTERPRETERS_SUCCESS';
export const UPDATE_AGNOSTIC_MEETING_INTERPRETERS_FAILURE = 'UPDATE_AGNOSTIC_MEETING_INTERPRETERS_FAILURE';

export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILURE = 'UPDATE_BOOKING_REQUEST_FAILURE';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';

export const GET_FREE_TRIAL_USER = 'GET_FREE_TRIAL_USER';
export const GET_FREE_TRIAL_USER_SUCCESS = 'GET_FREE_TRIAL_USER_SUCCESS';
export const GET_FREE_TRIAL_USER_ERROR = 'GET_FREE_TRIAL_USER_ERROR';

export const UPDATE_FREE_TRIAL_USER = 'UPDATE_FREE_TRIAL_USER';
export const UPDATE_FREE_TRIAL_USER_SUCCESS = 'UPDATE_FREE_TRIAL_USER_SUCCESS';
export const UPDATE_FREE_TRIAL_USER_ERROR = 'UPDATE_FREE_TRIAL_USER_ERROR';

export const UPDATE_FETCH_FREE_TRAIL_USER = 'UPDATE_FETCH_FREE_TRAIL_USER';
export const ZOOM_LANGUAGE_TYPES = {
  instant: 1,
  scheduled: 2,
  pmi: 4,
};

export const TIMEZONES = [
  {
    name: 'Alaska',
    offset: '-09:00',
    iana_code: 'America/Juneau',
    zoom_code: 'America/Anchorage',
    abbreviation: 'AKDT',
  },
];
export const VALID_FILE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'audio/*',
  'video/*',
].join();

export const MAX_UPLOADED_FILES = 5;
const MAX_FILE_SIZE = 20;

export const MAX_UPLOADED_FILE_SIZE = MAX_FILE_SIZE * 1024 * 1024;

export const EMAIL_VALIDATION_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
