import { TFunction } from 'react-i18next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { find } from 'lodash-es';

import * as authActions from 'domains/shared/actions/authActions';
import { routes } from 'domains/shared/constants/routes';
import { updateLastActiveOrganizationId } from 'domains/shared/services/auth';
import { getOrganizations } from 'domains/shared/services/client';
import { parseResponseErrors } from 'helpers/notificationHelper';
import { NotificationType, showNotification } from 'helpers/showNotification';
import { OrganizationSliceState } from 'shared/types/organizationSliceState';

import { Organization } from '../types/organization';

export const initialState: OrganizationSliceState = {
  isLoading: false,
  userOrganizations: [],
  activeOrganizationId: '',
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setActiveOrganization: (state, action) => {
      state.activeOrganizationId = action.payload;
    },
    setUserOrganizations: (state, action) => {
      state.userOrganizations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserOrganizations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserOrganizations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userOrganizations = action.payload ? action.payload : [];
      })
      .addCase(getUserOrganizations.rejected, (state) => {
        state.isLoading = false;
        state.userOrganizations = [];
      })
      .addCase(updateActiveOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateActiveOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeOrganizationId = action.payload ? action.payload : '';
      })
      .addCase(updateActiveOrganization.rejected, (state) => {
        state.isLoading = false;
        state.activeOrganizationId = '';
      })
      .addDefaultCase((state) => state);
  },
});

export const getUserOrganizations = createAsyncThunk(
  'organization/getUserOrganizations',
  async (shouldUpdateActiveOrganization: boolean | undefined, { dispatch }) => {
    try {
      const response = await getOrganizations();

      if (shouldUpdateActiveOrganization && response.data.organizations.length > 0) {
        await dispatch(updateActiveOrganization(response.data.organizations[0].id));

        // This flow is triggered outside the Browser Router (i.e Base Service) so we don't have access to
        // history object. Using window's location object to reinitiate the flow.
        window.location.href = routes.meetings;
      }

      return response.data.organizations;
    } catch (e) {
      showNotification({ message: parseResponseErrors(e) });

      return Promise.reject();
    }
  }
);
interface UserRemovedArguments {
  signOut: () => void;
  activeOrgId: string;
  removedOrgId: string;
  t: TFunction;
}

export const userRemovedFromOrganization = createAsyncThunk<unknown, UserRemovedArguments>(
  'organization/userRemovedFromOrganization',
  async (options: UserRemovedArguments, { dispatch, getState }) => {
    try {
      const { organization } = getState() as { organization: { userOrganizations: Organization[] } };
      const removedOrg = find(organization.userOrganizations, { id: options.removedOrgId });

      showNotification({
        message: options.t('client.notifications.clientUserRemovedFromOrg', { orgName: removedOrg?.name }),
        type: NotificationType.INFO,
      });

      if (organization.userOrganizations.length === 1) {
        options.signOut();

        return;
      }

      const response = await getOrganizations();

      if (options.removedOrgId === options.activeOrgId) {
        await dispatch(updateActiveOrganization(response.data.organizations[0].id));
      }

      dispatch(setUserOrganizations(response.data.organizations));

      return response.data.organizations;
    } catch (e) {
      showNotification({ message: parseResponseErrors(e) });

      return Promise.reject();
    }
  }
);

export const updateActiveOrganization = createAsyncThunk(
  'organization/updateActiveOrganization',
  async (id: string, { dispatch }) => {
    try {
      const { data } = await updateLastActiveOrganizationId({
        lastActiveOrganizationId: id,
      });

      dispatch(authActions.updateCurrentUser(data));

      return id;
    } catch (e) {
      showNotification({ message: parseResponseErrors(e) });

      return Promise.reject();
    }
  }
);

export const { setActiveOrganization, setUserOrganizations } = organizationSlice.actions;

export const { reducer: organizationReducer } = organizationSlice;
