import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { translationServiceApi } from 'domains/shared/services/client';

import { TranslationService } from '../types/translationService';

export const initialState: TranslationService = {
  isLoading: false,
  success: false,
  message: '',
};

export const restartTranslationService = createAsyncThunk(
  'translationService/restartTranslationService',
  async (meetingId: string) => {
    const response = await translationServiceApi(meetingId);

    return response.data;
  }
);

export const translationServiceSlice = createSlice({
  name: 'translationService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(restartTranslationService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(restartTranslationService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(restartTranslationService.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
        state.message = 'Please try again.';
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: translationServiceReducer } = translationServiceSlice;
