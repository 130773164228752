import * as constants from 'constants/commonConstants';

export const initialState = {
  countries: [],
  platforms: [],
  timezones: [],
  normalLanguages: [],
  signLanguages: [],
  publicMsgDisplay: true,
  signedMsgDisplay: true,
};

const commonReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };
    case constants.GET_PLATFORMS_SUCCESS:
      return {
        ...state,
        platforms: action.payload,
      };
    case constants.SET_PUBLIC_MESSAGE_DISPLAY_SUCCESS:
      return {
        ...state,
        publicMsgDisplay: false,
      };
    case constants.SET_SIGNED_MESSAGE_DISPLAY_SUCCESS:
      return {
        ...state,
        signedMsgDisplay: false,
      };
    case constants.GET_TIMEZONES:
      return {
        ...state,
        timezones: action.payload,
      };
    case constants.GET_NORMAL_LANGUAGES_SUCCESS:
      return {
        ...state,
        normalLanguages: action.payload,
      };
    case constants.GET_SIGN_LANGUAGES_SUCCESS:
      return {
        ...state,
        signLanguages: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
