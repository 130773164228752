/* eslint-disable no-case-declarations */
import produce from 'immer';

import * as constants from 'constants/bookingRequestConstants';
import {
  CREATE_BOOKED_INTERPRETER_SUCCESS,
  REMOVE_BOOKING_INTERPRETER,
  REMOVE_BULK_BOOKED_INTERPRETERS_SUCCESS,
} from 'constants/interpreterConstants';
import {
  cleanBookingInterpreters,
  insertNewBookingInterpreter,
  removeBookingInterpreter,
} from 'helpers/bookingRequestHelper';

export const initialState = {
  bookingRequest: {},
  allBookingRequests: [],
  loading: false,
  pagination: {},
  bookingRequestInterpreters: [],
  boothmate: [],
  meetings: {
    new_offer: [],
    upcoming: [],
    pending_review: [],
    previous: [],
  },
  meetingFee: {},
  onCallShiftPlans: [],
  issuesList: [],
  issues: [],
  earning: {},
  emergency: [],
  allBookingIssues: [],
  allBookingEmergencies: [],
  bookingInterpretersList: [],
  claimedOverTime: [],
  allBookingOvertimeClaims: [],
  searchedInterpreters: [],
  preparationMaterialUrl: '',
  rateCustomizationReasons: [],
  bookingSurveyEnabled: false,
  meetingRecordings: { recordings: [], meta: { expired_recordings: false } },
};

const bookingRequestReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_ON_CALL_SHIFT_PLANS:
      return {
        ...state,
        onCallShiftPlans: [],
      };
    case constants.GET_ON_CALL_SHIFT_PLANS_SUCCESS:
      return {
        ...state,
        onCallShiftPlans: action.payload,
      };
    case constants.GET_ON_CALL_SHIFT_PLANS_ERROR:
      return {
        ...state,
        onCallShiftPlans: [],
      };
    case constants.GET_BOOKING_REQUEST_BY_ID:
      return {
        ...state,
        bookingRequest: {},
        loading: true,
      };
    case constants.SET_SURVEY_FLAG_FROM_BOOKING_REQUEST:
      return {
        ...state,
        bookingSurveyEnabled: action.payload,
      };
    case constants.GET_BOOKING_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        bookingRequest: action.payload,
        loading: false,
      };
    case constants.GET_BOOKING_REQUEST_BY_ID_ERROR:
      return {
        ...state,
        bookingRequest: {},
        loading: false,
      };
    case constants.GET_ALL_BOOKING_REQUESTS:
      return {
        ...state,
        allBookingRequests: [],
        loading: true,
      };
    case constants.GET_ALL_BOOKING_REQUESTS_SUCCESS:
      return {
        ...state,
        pagination: action.payload.page_info,
        allBookingRequests: action.payload.results,
        loading: false,
      };
    case constants.GET_ALL_BOOKING_REQUESTS_ERROR:
      return {
        ...state,
        allBookingRequests: [],
        loading: false,
      };
    case constants.GET_ALL_USER_BOOKING_REQUESTS:
      return {
        ...state,
        allBookingRequests: [],
        loading: true,
      };
    case constants.GET_ALL_USER_BOOKING_REQUESTS_SUCCESS:
      return {
        ...state,
        pagination: action.payload.page_info,
        allBookingRequests: action.payload,
        loading: false,
      };
    case constants.GET_ALL_USER_BOOKING_REQUESTS_ERROR:
      return {
        ...state,
        allBookingRequests: [],
        loading: false,
      };
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case constants.GET_BOOKING_REQUEST_INTERPRETERS:
      return {
        ...state,
        bookingRequestInterpreters: [],
        loading: true,
      };
    case constants.GET_BOOKING_REQUEST_INTERPRETERS_SUCCESS:
      return {
        ...state,
        bookingInterpretersList: action.payload,
        bookingRequestInterpreters: cleanBookingInterpreters(action.payload),
        loading: false,
      };
    case constants.SET_NEW_BULK_BOOKING_INTERPRETERS:
      return {
        ...state,
        bookingRequestInterpreters: cleanBookingInterpreters([...state.bookingInterpretersList, ...action.payload]),
        bookingInterpretersList: [...state.bookingInterpretersList, ...action.payload],
      };
    case constants.SET_PREPARATION_MATERIALS:
      return {
        ...state,
        bookingRequest: {
          ...state.bookingRequest,
          file_links: [
            ...state.bookingRequest.file_links,
            ...action.payload.filter((item) => item.url_type === 'file'),
          ],
          links: [...state.bookingRequest.links, ...action.payload.filter((item) => item.url_type === 'link')],
        },
      };
    case constants.DELETE_PREPARATION_MATERIAL:
      return {
        ...state,
        bookingRequest: {
          ...state.bookingRequest,
          file_links: [
            ...state.bookingRequest.file_links.filter(
              (item) => item.url_type === 'file' && item.id !== action.payload.id
            ),
          ],
          links: [
            ...state.bookingRequest.links.filter((item) => item.url_type === 'link' && item.id !== action.payload.id),
          ],
        },
      };
    case constants.GET_BOOKING_REQUEST_INTERPRETERS_ERROR:
      return {
        ...state,
        bookingRequestInterpreters: [],
        loading: false,
      };

    case CREATE_BOOKED_INTERPRETER_SUCCESS:
      return {
        ...state,
        bookingRequestInterpreters: insertNewBookingInterpreter(state.bookingRequestInterpreters, action.payload),
        bookingInterpretersList: [
          ...state.bookingInterpretersList.filter((bi) => bi.id !== action.payload.removedId),
          action.payload.created,
        ],
      };

    case REMOVE_BULK_BOOKED_INTERPRETERS_SUCCESS:
      return {
        ...state,
        bookingRequestInterpreters: state.bookingRequestInterpreters.filter(
          (bookedInterpreter) =>
            !action.payload.find((removedInterpreter) => removedInterpreter.id === bookedInterpreter.id)
        ),
      };
    case REMOVE_BOOKING_INTERPRETER:
      return {
        ...state,
        bookingInterpretersList: state.bookingInterpretersList.filter((inter) => inter.id !== action.payload.removedId),
        bookingRequestInterpreters: removeBookingInterpreter(state.bookingRequestInterpreters, action.payload),
      };
    case constants.REMOVE_BOOKING_INTERPRETER_AFTER_ISSUE: {
      const newData = state.bookingInterpretersList.filter((inter) => inter.id !== action.payload.id);

      return {
        ...state,
        bookingInterpretersList: newData,
        bookingRequestInterpreters: cleanBookingInterpreters([...newData]),
      };
    }
    case constants.GET_NEW_OFFER_MEETING_SUCCESS:
      return {
        ...state,
        pagination: action.payload.page_info,
        meetings: { ...state.meetings, new_offer: action.payload.results || action.payload },
        loading: false,
      };
    case constants.GET_UPCOMING_MEETING_SUCCESS:
      return {
        ...state,
        pagination: action.payload.page_info,
        loading: false,
        meetings: { ...state.meetings, upcoming: action.payload.results || action.payload },
      };
    case constants.GET_PENDING_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        meetings: { ...state.meetings, pending_review: action.payload.results || action.payload },
        pagination: action.payload.page_info,
      };
    case constants.GET_PREVIOUS_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        meetings: { ...state.meetings, previous: action.payload.results || action.payload },
        pagination: action.payload.page_info,
      };
    case constants.UPDATE_BOOKING_INTERPRETER_STATUS_SUCCESS:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          previous: state.meetings.new_offer.filter(
            (bookingInterpreter) => bookingInterpreter.id !== action.payload.id
          ),
        },
      };
    case constants.GET_MEETING_FEE_SUCCESS:
      return {
        ...state,
        meetingFee: action.payload,
      };
    case constants.GET_MEETING_FEE:
      return {
        ...state,
        meetingFee: {},
      };
    case constants.GET_ISSUES_LIST:
      return {
        ...state,
        issuesList: action.payload,
      };
    case constants.GET_ISSUES:
      return {
        ...state,
        issues: action.payload,
      };
    case constants.GET_BOOKING_ISSUES:
      return {
        ...state,
        allBookingIssues: action.payload.filter((issue) => issue.category === 'Issue'),
        allBookingEmergencies: action.payload.filter((issue) => issue.category === 'Emergency'),
      };
    case constants.MAKE_EARNING:
      return {
        ...state,
        earning: action.payload,
      };
    case constants.REPORT_EMERGENCY:
      return {
        ...state,
        issues: [...state.issues, action.payload],
      };
    case constants.REPORT_EMERGENCY_WITH_MEETING_TAB:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          [action.payload.tab]: state.meetings[action.payload.tab].map((meeting) => {
            if (meeting.id === action.payload.result.booking_id) {
              meeting.user_issues = [...meeting.user_issues, action.payload.result];
            }

            return meeting;
          }),
        },
      };
    case constants.ADD_ISSUE_COMMENT:
      // eslint-disable-next-line no-case-declarations
      const content = { ...state };

      content.allBookingIssues = content.allBookingIssues.map((issue) => {
        const newObj = { ...issue };

        if (newObj.id === action.payload.id) {
          newObj.status = 'completed';
          newObj.comment = action.payload.comment;
        }

        return newObj;
      });
      content.allBookingEmergencies = content.allBookingEmergencies.map((issue) => {
        const newObj = { ...issue };

        if (newObj.id === action.payload.id) {
          newObj.status = 'closed';
          newObj.comment = action.payload.comment;
          newObj.interpreter_action = action.payload.interpreter_action;
        }

        return newObj;
      });

      return content;
    case constants.SET_TAB_COUNT_NONE:
      return {
        ...state,
        pagination: { ...state.pagination, count: 0 },
      };
    case constants.GET_BOOKING_INTERPRETER_OVERTIME_CLAIM:
      return {
        ...state,
        claimedOverTime: [],
      };
    case constants.GET_BOOKING_INTERPRETER_OVERTIME_CLAIM_SUCCESS:
      return {
        ...state,
        claimedOverTime: action.payload,
      };
    case constants.OVERTIME_CLAIM_WITH_MEETING_TAB:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          [action.payload.tab]: state.meetings[action.payload.tab].map((meeting) => {
            if (meeting.id === action.payload.result.booking_id) {
              meeting.overtime_claims = [...meeting.overtime_claims, action.payload.result];
            }

            return meeting;
          }),
        },
      };
    case constants.GET_BOOKING_INTERPRETER_OVERTIME_CLAIM_FAILURE:
      return {
        ...state,
        claimedOverTime: [],
      };
    case constants.GET_BOOKING_OVERTIME_CLAIMS:
      return {
        ...state,
        allBookingOvertimeClaims: [],
      };
    case constants.GET_BOOKING_OVERTIME_CLAIMS_SUCCESS:
      return {
        ...state,
        allBookingOvertimeClaims: action.payload,
      };
    case constants.GET_BOOKING_OVERTIME_CLAIMS_FAILURE:
      return {
        ...state,
        allBookingOvertimeClaims: [],
      };
    case constants.UPDATE_BOOKING_OVERTIME_CLAIM:
      return {
        ...state,
        allBookingOvertimeClaims: produce(state.allBookingOvertimeClaims, (draft) => {
          draft[action.payload.index] = action.payload.data;
        }),
      };
    case constants.UPDATE_BOOKING_REVIEWER_SUCCESS:
      return {
        ...state,
        allBookingRequests: state.allBookingRequests.map((booking) => {
          if (booking.id === action.payload.booking_id) {
            booking.reviewer_id = action.payload.reviewer_id;
          }

          return booking;
        }),
      };
    case constants.GET_SEARCHED_INTERPRETERS:
      return {
        ...state,
        searchedInterpreters: action.payload.map((interpreter) => ({
          id: interpreter.id,
          name: `${interpreter.first_name} ${interpreter.last_name}`,
          email: interpreter.email,
        })),
      };
    case constants.REMOVE_PENDING_MEETING:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          pending_review: state.meetings.pending_review.filter((booking) => booking.id !== action.payload),
        },
      };
    case constants.GET_CUSTOM_RATE_REASONS: {
      return {
        ...state,
        rateCustomizationReasons: action.payload,
      };
    }
    case constants.UPDATE_BOOKING_INTERPRETER: {
      const updatedInterpreterList = state.bookingInterpretersList.map((bookingInterpreter) => {
        if (bookingInterpreter.id === action.payload.id) {
          return action.payload;
        }

        return bookingInterpreter;
      });

      return {
        ...state,
        bookingInterpretersList: updatedInterpreterList,
        bookingRequestInterpreters: cleanBookingInterpreters(updatedInterpreterList),
      };
    }

    case constants.GET_BOOKING_REQUEST_INTERPRETERS_BY_LANGUAGE_SUCCESS:
      return {
        ...state,
        boothmate: action.payload.map((element) => ({
          email: element.interpreter.email,
          firstName: element.interpreter.first_name,
          lastName: element.interpreter.last_name,
        })),
        loading: false,
      };

    case constants.GET_BOOKING_RECORDINGS_BY_ID_SUCCESS:
      return {
        ...state,
        meetingRecordings: action.payload,
      };
    case constants.GET_BOOKING_RECORDINGS_BY_ID_ERROR:
      return {
        ...state,
        meetingRecordings: { recordings: [], meta: { expired_recordings: false } },
      };

    default:
      return state;
  }
};

export default bookingRequestReducer;
