import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serverFeatureFlagsApi } from 'domains/shared/services/client';

import { FeatureFlags } from '../types/featureFlags';

export const initialState: FeatureFlags = {
  sns_notifications: false,
  multi_user_organizations: false,
  fast_track_automatic_offer_sending: false,
};

export const getServerFeatureFlags = createAsyncThunk('/getServerFeatureFlags', async () => {
  const response = await serverFeatureFlagsApi();

  return response.data;
});

export const serverFeatureFlagsSlice = createSlice({
  name: 'serverFeatureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServerFeatureFlags.fulfilled, (state, action) => {
        state.fast_track_automatic_offer_sending = action.payload.fast_track_automatic_offer_sending;
        state.sns_notifications = action.payload.sns_notifications;
        state.multi_user_organizations = action.payload.multi_user_organizations;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: serverFeatureFlagReducer } = serverFeatureFlagsSlice;
