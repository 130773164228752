import classNames from 'classnames';
import moment from 'moment';
import momentTZ from 'moment-timezone';

import {
  DATE_STARTING_YEAR,
  DATE_TIME_STARTING_YEAR_24_HOURS,
  TIME_24H,
} from 'domains/shared/constants/dateTimeFormats';
import { zonedTimeToZuluString } from 'domains/shared/helpers/datetime';
import { getAllDaysInTheWeek } from 'domains/shared/helpers/getAllDaysInTheWeek';
import { getBookedTimeSlots } from 'domains/shared/helpers/getBookedTimeSlots';
import { getNewBookedSlots } from 'domains/shared/helpers/getNewBookedSlots';
import { InterpreterStatus } from 'shared/types/interpreterStatus';

import { formatTime, getIANATimezoneWithUTCFallback } from './commonHelpers';

const weekDaysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const prepareRequestPayloads = (startingDate, endingDate, weekday) => {
  const slotPayload = {
    start_time: { gte: startingDate, lte: endingDate },
    end_time: { gte: startingDate, lte: endingDate },
  };
  const eventsPayload = {
    starts_at: { gte: weekday, lte: endingDate },
    ends_at: { gte: weekday, lte: endingDate },
    booking_interpreter_status: InterpreterStatus.ACCEPTED,
  };
  const exceptionPayload = {
    start_time: { gte: startingDate, lte: endingDate },
    end_time: { gte: startingDate, lte: endingDate },
    only_deleted: true,
  };

  return [slotPayload, eventsPayload, exceptionPayload];
};

export const prepareResponsePayload = (responses, setEmpty) => {
  const payload = {
    interpreterBookedSlots: responses[0].value.data,
    genericSlots: setEmpty ? [{}] : responses[1].value.data,
    shiftPlans: responses[2].value.data,
    eventSlots: responses[3].value.data,
    exceptionSlots: responses[4].value.data,
    onCallSlots: prepareOnCallSlotsData(responses[2].value.data, responses[5].value.data),
  };

  return payload;
};

const prepareOnCallSlotsData = (shiftPlans, onCallSlots) => {
  const newShiftPlans = shiftPlans.map((plan) => {
    const shiftPlanStartDate = moment(plan.start_date).format(DATE_STARTING_YEAR);
    const shiftPlanEndDate = moment(new Date(plan.end_date)).format(DATE_STARTING_YEAR);
    const shiftStartTime = plan.shift_detail.start_time.split('T')[1].slice(0, 5);
    const shiftEndTime = plan.shift_detail.end_time.split('T')[1].slice(0, 5);
    const startDateStr = momentTZ.tz(
      `${shiftPlanStartDate} ${shiftStartTime}`,
      getIANATimezoneWithUTCFallback(plan.shift_detail.time_zone_code)
    );
    const endDateStr = momentTZ.tz(
      `${shiftPlanEndDate} ${shiftEndTime}`,
      getIANATimezoneWithUTCFallback(plan.shift_detail.time_zone_code)
    );

    return {
      ...plan,
      shift_detail: {
        ...plan.shift_detail,
        start_time: startDateStr.format(),
        end_time: endDateStr.format(),
      },
    };
  });

  onCallSlots.forEach((slot) => {
    newShiftPlans.forEach((plan) => {
      if (moment.utc(slot.start_time).isSame(moment(plan.shift_detail.start_time))) {
        slot.isFirst = true;
        // eslint-disable-next-line max-len
        slot.on_call_text = `${formatTime(plan.shift_detail.start_time, true)}-${formatTime(
          plan.shift_detail.end_time,
          true
        )}`;
      }
    });
  });

  return onCallSlots;
};

// TODO: fix the warning when rendering TableDragSelect component
export const calendarHoursArr = Array(48)
  .fill(null)
  .map(() => [false]);

export const calendarTimes = Array(24)
  .fill(null)
  .map((_, i) => i)
  .flatMap((h) => [`${h}:00`, `${h}:30`]);

/**
 * compares old and new slots to find out new deleted slots
 * @param  [{array}] newSlot NewSlots coming from calendar cells
 * @param  [{array}] oldSlot oldSlot/exxisting slots
 * @return [array]      an array of array with ids representing timeslots to be deleted
 */
export const newDeletedSlots = (newSlot, oldSlot) => {
  const newSlots = JSON.parse(JSON.stringify(newSlot));
  const oldSlots = JSON.parse(JSON.stringify(oldSlot));
  const weekDays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };

  newSlots.map((slot) => {
    slot.start_time = `${weekDays[moment(slot.start_time).day()]} ${moment(slot.start_time)
      .format(TIME_24H)
      .toString()}`;
  });
  oldSlots.map((slot) => {
    slot.start_time = `${slot.day} ${moment(slot.start_time).format(TIME_24H).toString()}`;
  });
  const result = oldSlots.filter(({ start_time: id1 }) => !newSlots.some(({ start_time: id2 }) => id2 === id1));
  const deletedIds = [];

  result.map((item) => deletedIds.push(item.id));

  return deletedIds;
};

/**
 * compares 2D array to find out slots and event slots to render event slots where needed
 * @param  [[array]] arr array of event slots
 * @param  [[array]] subarr current slot
 * @return [array]      return true if current slot exists in array of events
 */
export const findBookingInSlots = (arr, subarr) => arr.some((el) => el[0] === subarr[0] && el[1] === subarr[1]);

/**
 * compares old and new slots to find out new deleted slots for non generic slots
 */
export const newDeletedAvailibleSlots = ({
  newSlots,
  interpreterBookedSlots,
  genericSlots,
  weekDates,
  exceptionSlots,
}) => {
  const weekDays = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
  };
  const weekdates = weekDates.reduce((o, d) => ({ ...o, [moment(d).day()]: d }), {});
  const newDeleted = interpreterBookedSlots.filter(
    ({ start_time: id1 }) => !newSlots.some(({ start_time: id2 }) => id2 === id1)
  );
  const formattedNewSlots = newSlots.map((n) => ({
    ...n,
    day: moment(n.start_time).day(),
    start_time: moment(n.start_time).format(TIME_24H),
  }));
  const genericSlotForWeeklyException = genericSlots.filter(
    ({ start_time, day: day1 }) =>
      !formattedNewSlots.some(({ start_time: id2, day: day2 }) => start_time === id2 && weekDays[day1] === day2)
  );
  const weeklyExceptions = genericSlotForWeeklyException.map((slot) => ({
    start_time: moment(`${moment(weekdates[weekDays[slot.day]]).format(DATE_STARTING_YEAR)} ${slot.start_time}`)
      .utc()
      .format(DATE_TIME_STARTING_YEAR_24_HOURS),
    end_time: moment(`${moment(weekdates[weekDays[slot.day]]).format(DATE_STARTING_YEAR)} ${slot.end_time}`)
      .utc()
      .format(DATE_TIME_STARTING_YEAR_24_HOURS),
    deleted_at: new Date(),
  }));
  // eslint-disable-next-line max-len
  let filteredExceptions = weeklyExceptions.filter(
    ({ start_time: start1 }) =>
      !exceptionSlots.some(
        ({ start_time: start2 }) => start1 === moment(start2).utc().format(DATE_TIME_STARTING_YEAR_24_HOURS)
      )
  );

  // eslint-disable-next-line max-len
  filteredExceptions = filteredExceptions.filter(
    ({ start_time: start1 }) =>
      !interpreterBookedSlots.some(
        ({ start_time: start2 }) => start1 === moment(start2).utc().format(DATE_TIME_STARTING_YEAR_24_HOURS)
      )
  );

  return [newDeleted.map((item) => item.id), filteredExceptions];
};

/**
 * calculates object with start_time and end_time representing timeslots for non generic view
 * @param  [[array]] slot of cells numbers 2D array
 * @param  [{array}] newDate CurrentDate
 * @param  boolean shiftStatus calculate availibility for shiftplan if true
 * @return [{array}]      an array of object with start_time and end_time representing timeslots for non generic view
 */
export const calculateAvailabilitySlots = (slots, newDate, shiftStatus) => {
  const slotsArray = fillSlotTimeArray(newDate);
  const cells = [];

  for (let x = 0; x < slots.length; x += 1) {
    const date = slots[x].start_time.split(' ')[0];
    const time = slots[x].start_time.split(' ')[1];
    const slotHour = time.split(':')[0];
    const slotMin = parseInt(time.split(':')[1], 10);

    for (let i = 0; i < 48; i += 1) {
      for (let j = 0; j < 7; j += 1) {
        const splitted = slotsArray[i][j].split(' ');
        const hour = splitted[1].split(':')[0];
        const min = parseInt(splitted[1].split(':')[1], 10);

        if (
          slotsArray[i][j] === slots[x].start_time ||
          (splitted[0] === date && (splitted[1] === time || (hour === slotHour && min < slotMin && min + 30 > slotMin)))
        ) {
          cells.push([i, j, slots[x]]);
        }
      }
    }
  }

  if (shiftStatus) {
    return cells.map((slot) => slot.slice(0, 2));
  }

  return cells;
};

/**
 * calculates object with start_time and end_time representing timeslots for non generic view
 * @param  [[array]] slot of cells numbers 2D array
 * @param  [{array}] newDate CurrentDate
 * @return [{array}]      an array of object with start_time and end_time representing timeslots for non generic view
 */
export const calculateGenericAvailabilitySlots = (slots, currentDate, exceptions = []) => {
  if (moment(currentDate).startOf('isoWeek').isBefore(moment().startOf('isoWeek'))) {
    return [];
  }

  const weekDays = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };
  const slotsArray = fillSlotTimeArray();
  const cells = [];
  const weekDates = getAllDaysInTheWeek(currentDate);

  for (let x = 0; x < slots.length; x += 1) {
    for (let i = 0; i < 48; i += 1) {
      for (let j = 0; j < 7; j += 1) {
        if (weekDays[slots[x].day] === j) {
          if (moment(slotsArray[i][j]).format(TIME_24H) === slots[x].start_time) {
            const newStartTime = moment(
              `${moment(weekDates[j]).format(DATE_STARTING_YEAR)} ${slots[x].start_time}`
            ).format(DATE_TIME_STARTING_YEAR_24_HOURS);
            const newEndTime = moment(`${moment(weekDates[j]).format(DATE_STARTING_YEAR)} ${slots[x].end_time}`).format(
              DATE_TIME_STARTING_YEAR_24_HOURS
            );
            const newId = slots[x].id;

            if (!exceptions.map((exp) => exp.start_time).includes(newStartTime)) {
              cells.push([i, j, { id: newId, start_time: newStartTime, end_time: newEndTime }]);
            }
          }
        }
      }
    }
  }

  return cells;
};

/**
 *
 * @param  date slot of cells numbers 2D array
 * @param  date newDate CurrentDate
 * @return [{array}]      an array of object with start_time and end_time representing timeslots for non generic view
 */
export const formattedDate = (date, format) => moment(date).format(format);
/**
 * calculates initial timeslot cells with false
 * @param  row integer
 * @param  cols integer
 * @return [[array]]      2D array representing initial cells of calendar
 */
export const getAllTimeSlots = (rows = 48, cols = 7) =>
  Array.from({ length: rows }, () => Array.from({ length: cols }, () => false));

/**
 * calculates the number of true in 2D array, used to check number of availible slots
 * @param  [[array]]    2D array of calendar cells
 * @return integer      number of true in 2D array
 */
export const countTruthyEntries = (array) => {
  let count = 0;

  for (let i = 0; i < array.length; i += 1) {
    for (let j = 0; j < array[i].length; j += 1) {
      if (array[i][j]) {
        count += 1;
      }
    }
  }

  return count;
};

export const times = [
  '12:00 AM',
  '1:00 AM',
  '2:00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '13:00 PM',
  '14:00 PM',
  '15:00 PM',
  '16:00 PM',
  '17:00 PM',
  '18:00 PM',
  '19:00 PM',
  '20:00 PM',
  '21:00 PM',
  '22:00 PM',
  '23:00 PM',
];

/**
 * calculates time all slots with start_time and end_time in current week
 * @param  date date Current Date
 * @return [[array]]      all slots with start_time and end_time in current week
 */
export const fillSlotTimeArray = (date = moment()) => {
  let count = 0;
  const timesArray = [];

  for (let i = 0; i < 48; i += 1) {
    const startDay = moment(date).startOf('isoWeek');

    startDay.add(count, 'minutes');
    timesArray.push([0]);
    for (let j = 0; j < 7; j += 1) {
      timesArray[i][j] = startDay.format(DATE_TIME_STARTING_YEAR_24_HOURS);
      startDay.add(1, 'days');
    }
    count += 30;
  }

  return timesArray;
};
/**
 * calculates event div height based on start and end time of event
 * @param  {object} detail event details of event
 * @return integer      integer representing event div height in px
 */
export const calculateEventDivHeight = (detail, i, weekDay) => {
  const currentCell = `${moment(weekDay).format(DATE_STARTING_YEAR)} ${calendarTimes[i]}`;
  const marginTop = (moment(detail.starts_at).diff(moment(currentCell), 'seconds') / 3600) * 2 * 29;
  let divHeight = 0;

  if (moment(detail.ends_at).format('DD') === moment(detail.starts_at).format('DD')) {
    divHeight = (moment(detail.ends_at).diff(moment(detail.start_time), 'seconds') / 3600) * 2 * 29;
  } else {
    divHeight = (moment(detail.start_time).endOf('day').diff(moment(detail.start_time), 'minutes') / 60) * 2 * 29;
  }

  return [divHeight, marginTop - 2];
};

/**
 * calculates current bookedtime cells based on given timeslots for generic view
 * @param  [{objects}] genericSlots current object of genericslots
 * @return [[array]]      2D array representing all availible cells of calendar
 */
export const slotsForGeneric = (genericSlots) => {
  const weekDays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
  };

  genericSlots.map((slot) => {
    slot.day = weekDays[moment(slot.start_time).utc().day()];
    slot.start_time = moment(slot.start_time).utc().format(TIME_24H);
    slot.end_time = moment(slot.end_time).utc().format(TIME_24H);
  });

  return genericSlots;
};

/**
 * calculates deleted generic cells based on given current slots and genericslots
 * @param  [{objects}] genericSlots current object of genericslots
 * @param  date currentDate current date
 * @return [[array]]      array objects representing all availible slots of calendar
 */
export const slotsForDeletedGeneric = (
  genericSlot,
  currentDate = moment.now().format(DATE_TIME_STARTING_YEAR_24_HOURS)
) => {
  const genericSlots = JSON.parse(JSON.stringify(genericSlot));
  const weekDates = getAllDaysInTheWeek(currentDate);

  genericSlots.map((slot) => {
    slot.start_time = moment(`${moment(weekDates[slot.day]).format(DATE_STARTING_YEAR)} ${slot.start_time}`).format(
      DATE_TIME_STARTING_YEAR_24_HOURS
    );
    slot.end_time = moment(`${moment(weekDates[slot.day]).format(DATE_STARTING_YEAR)} ${slot.end_time}`).format(
      DATE_TIME_STARTING_YEAR_24_HOURS
    );
  });

  return genericSlots;
};

/**
 * converts shiftplans into slots for calendars
 * @param  [{objects}] shiftplans as from API
 * @return [{array}]      array objects representing all availible slots of calendar
 */
export const getShiftPlanSlots = (shiftPlan) => {
  if (shiftPlan && shiftPlan[0]) {
    const shiftPlanStart = `${shiftPlan[0].start_date} ${shiftPlan[0].shift_detail.start_time}`;
    const shiftPlanEnd = `${shiftPlan[0].end_date} ${shiftPlan[0].shift_detail.end_time}`;
    const shiftPlans = {
      start_time: shiftPlanStart,
      end_time: shiftPlanEnd,
    };
    const startDate = moment(shiftPlans.start_time).format(DATE_STARTING_YEAR);
    const endDate = moment(shiftPlans.end_time).format(DATE_STARTING_YEAR);
    const startTime = moment(shiftPlans.start_time).format(TIME_24H);
    const endTime = moment(shiftPlans.end_time).format(TIME_24H);
    const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
    const shiftPlanObjects = [];

    for (let i = 0; i < daysDiff; i += 1) {
      shiftPlanObjects.push({
        start_time: `${moment(moment(startDate).clone().add(i, 'days')).format(DATE_STARTING_YEAR)} ${startTime}`,
        end_time: `${moment(moment(startDate).clone().add(i, 'days')).format(DATE_STARTING_YEAR)} ${endTime}`,
      });
    }
    const shiftPlanSlots = [];

    shiftPlanObjects.forEach((obj, index) => {
      const start = shiftPlanObjects[index].start_time;
      const end = shiftPlanObjects[index].end_time;
      const hourDiff = moment(end).diff(moment(start), 'h');
      const minDiff = moment(end).diff(moment(start), 'minutes') % 60;
      const slots = hourDiff * 2 + (minDiff === 30 ? 1 : 0);

      for (let i = 0; i < slots; i += 1) {
        shiftPlanSlots.push({
          start_time: moment(moment(start).add(i * 30, 'minutes')).format(DATE_TIME_STARTING_YEAR_24_HOURS),
          end_time: moment(moment(start).add((i + 1) * 30, 'minutes')).format(DATE_TIME_STARTING_YEAR_24_HOURS),
        });
      }
    });

    return shiftPlanSlots;
  }

  return [];
};

/**
 * calculate cells to be added for non generic view
 */
export const addCellsAvailibility = ({
  cells,
  genericSlots,
  currentDate,
  interpreterBookedSlots,
  exceptionSlots,
  weekdays,
}) => {
  const addCells = JSON.parse(JSON.stringify(cells));
  const genericCells = getAllTimeSlots();

  calculateGenericAvailabilitySlots(genericSlots, currentDate).forEach((slot) => {
    genericCells[slot[0]][slot[1]] = true;
  });
  addCells.forEach((cell, parentIndex) => {
    cell.forEach((c, index) => {
      const time = `${moment(weekdays[index]).format(DATE_STARTING_YEAR)} ${calendarTimes[parentIndex]}`;
      const overlapedExceptionSlots = exceptionSlots.filter((item) => moment(item.start_time).isSame(moment(time)));

      if (addCells[parentIndex][index] && genericCells[parentIndex][index] && overlapedExceptionSlots.length === 0) {
        addCells[parentIndex][index] = false;
      }
    });
  });
  const interpreterSlots = getAllTimeSlots();

  calculateAvailabilitySlots(interpreterBookedSlots, currentDate).forEach((slot) => {
    interpreterSlots[slot[0]][slot[1]] = true;
  });

  interpreterSlots.forEach((slot, parentIndex) => {
    slot.forEach((c, index) => {
      if (
        cells[parentIndex][index] === true &&
        genericCells[parentIndex][index] === true &&
        interpreterSlots[parentIndex][index] === true
      ) {
        addCells[parentIndex][index] = true;
      }
    });
  });

  return addCells;
};

/**
 * calculate added and removed cells for generic view
 */
export const genericDataPrep = ({ cells, weekdays, genericSlots, currentDate }) => {
  const greenCells = getBookedTimeSlots(cells, weekdays);
  const filteredSlots = greenCells.filter(
    (greenCell) =>
      !genericSlots.some(
        (slot) =>
          slot.start_time === moment(greenCell.start_time).format(TIME_24H) &&
          weekDaysArray[moment(greenCell.start_time).day()] === slot.day
      )
  );
  const addedData = slotsForGeneric(filteredSlots);
  const deletedData = newDeletedSlots(greenCells, slotsForDeletedGeneric(genericSlots, currentDate));

  return [addedData, deletedData];
};

/**
 * calculate added for non generic view
 */
export const nonGenericSavedSlot = ({ addCells, weekdays, interpreterCells, currentDate, interpreterBookedSlots }) => {
  const savedSlots = getNewBookedSlots({
    newSlot: getBookedTimeSlots(addCells, weekdays),
    oldSlot: getBookedTimeSlots(interpreterCells, weekdays),
    status: true,
    currentDate,
  });

  return savedSlots;
};

/*
  convert start_time and end_time to zulu time for each slot in the input array of slots
*/
export const convertSlotsToZuluTime = (slots, timeZone) =>
  slots.map((slot) => ({
    ...slot,
    start_time: zonedTimeToZuluString(slot.start_time, timeZone),
    end_time: zonedTimeToZuluString(slot.end_time, timeZone),
  }));

/**
 * calculate deleted for non generic view
 */
export const nonGenericDeletedSlot = ({
  deleteAvailableSlots,
  cells,
  weekdays,
  interpreterBookedSlots,
  genericSlots,
  exceptionSlots,
}) => {
  const deletedSlots = newDeletedAvailibleSlots({
    newSlots: getBookedTimeSlots(cells, weekdays),
    interpreterBookedSlots,
    genericSlots,
    weekDates: weekdays,
    exceptionSlots,
  });

  if (deletedSlots[0].length) {
    deleteAvailableSlots(deletedSlots[0]);
  }

  const weeklyExceptions = deletedSlots[1];

  return weeklyExceptions;
};

/**
 * calculate cells to be shown availible
 */
export const newCalendarCells = ({
  genericSlots,
  newCurrentDate,
  interpreterBookedSlots,
  weeklyExceptionsSlots = [],
}) => {
  const newCells = getAllTimeSlots();
  const genericSlot = calculateGenericAvailabilitySlots(genericSlots, newCurrentDate, weeklyExceptionsSlots);

  genericSlot.forEach((slot) => {
    newCells[slot[0]][slot[1]] = true;
  });

  const interpreterSlots = calculateAvailabilitySlots(interpreterBookedSlots, newCurrentDate);

  interpreterSlots.forEach((slot) => {
    newCells[slot[0]][slot[1]] = true;
  });

  return newCells;
};

/**
 * calculate meeting slot and attach meeting details with slot
 * @param  [{}] eventSlots slots of events from api
 * @param  date currentDate currentDate
 * @return [array]      meeting slot and attach meeting details with slot and eventStartSlots
 */
export const meetingSlotsWithDetails = (eventSlots, newCurrentDate) => {
  const eventStartTimeSlots = calculateAvailabilitySlots(eventSlots, newCurrentDate);
  const slots = eventStartTimeSlots.map((slot) => [slot[0], slot[1]]);

  return [slots, eventStartTimeSlots];
};

export const calculateMeetingsDuration = (eventSlots) => {
  let hours = 0;

  eventSlots.forEach((meeting) => {
    const diff = moment(meeting.ends_at).diff(moment(meeting.start_time), 'minutes') / 60;

    hours += diff;
  });

  return hours;
};

export const getSlotClasses = ({ shouldDisableSlot, weekDay, exceptionSlots, timeIndex }) => {
  const cellDateTime = moment(`${moment(weekDay.toString()).format(DATE_STARTING_YEAR)} ${calendarTimes[timeIndex]}`);
  const isExceptionSlot = exceptionSlots.some((slot) => moment(slot.start_time).isSame(cellDateTime));

  return classNames({
    'exception-slots': isExceptionSlot,
    'disable-drag-select': cellDateTime < moment() && shouldDisableSlot,
  });
};

export const adjustWeeklyExceptions = (oldSlots, newSlots) => {
  let finalData = [...oldSlots];

  newSlots.forEach((newSlot) => {
    if (newSlot.deleted_at) finalData.push(newSlot);
    else finalData = finalData.filter((oldSlot) => oldSlot.id !== newSlot.id);
  });

  return finalData;
};

export const adjustInterpreterSlots = (oldSlots, newSlots) => {
  let finalData = [...oldSlots];

  newSlots.forEach((newSlot) => {
    if (newSlot.deleted_at) finalData = finalData.filter((oldSlot) => oldSlot.id !== newSlot.id);
    else finalData.push(newSlot);
  });

  return finalData;
};

export const isOnCallSlot = (slots, i, weekDay) => {
  let [onCallText, isPresent] = [null, false];

  if (slots && slots.length) {
    const cell = `${moment(weekDay).format(DATE_STARTING_YEAR)} ${calendarTimes[i]}`;

    for (let x = 0; x < slots.length; x += 1) {
      if (moment(slots[x].start_time).isSame(moment(cell))) {
        isPresent = true;

        if (slots[x].isFirst) onCallText = slots[x].on_call_text;

        break;
      }
    }
  }

  return [isPresent, onCallText];
};
