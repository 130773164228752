import * as constants from 'constants/authConstants';
import * as actions from 'domains/shared/actions/authActions';

export const initialState = {
  user: {},
  toggleSignDocumentModal: false,
};

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case constants.GET_SIGNIN:
      return {
        ...state,
        user: {},
      };
    case constants.GET_SIGNIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case constants.GET_SIGNIN_ERROR:
      return {
        ...state,
        user: {},
      };
    case constants.TOGGLE_SIGN_DOCUMENT_MODAL:
      return {
        ...state,
        toggleSignDocumentModal: action.payload,
      };
    case actions.updateCurrentUser.type:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
