import { orderBy, uniqBy } from 'lodash-es';
import moment from 'moment';

import { GET_SIGNIN_SUCCESS } from 'constants/authConstants';
import * as commonConstants from 'constants/commonConstants';
import { ZOOM_LANGUAGE_TYPES } from 'domains/client/constants';
import * as constants from 'domains/client/constants';
import { DeleteBookingRequestPreparationMaterial } from 'domains/client/types/deleteBookingRequestPreparationMaterial';
import { ForceSetZoomAuthorizedState } from 'domains/client/types/forceSetZoomAuthorizedState';
import { GetZoomMeetingDetailsSuccess } from 'domains/client/types/getZoomMeetingDetailsSuccess';
import { GetZoomMeetingsSuccess } from 'domains/client/types/getZoomMeetingsSuccess';
import { SetBookingRequestPreparationMaterial } from 'domains/client/types/setBookingRequestPreparationMaterial';
import { ActionVariant } from 'domains/client/types/storeAction';
import { ZoomMeetingsResponse } from 'domains/client/types/zoomMeetingsResponse';
import { AGNOSTIC_LANGUAGES } from 'domains/shared/constants/agnosticLanguages';
import { ZOOM_LANGUAGES } from 'domains/shared/constants/zoomLanguages';
import { checkDataHasProperty } from 'domains/shared/helpers/checkDataHasProperty';
import { getLanguagesHash } from 'domains/shared/helpers/getLanguagesHash';
import { getMeetingPlatform } from 'domains/shared/helpers/selfServiceBooking';
import { BookingRequest } from 'domains/shared/types/bookingRequest';
import { KUDOMeeting } from 'domains/shared/types/kudoMeeting';
import { MeetingDetails } from 'domains/shared/types/meetingDetails';
import { MeetingProvider } from 'domains/shared/types/meetingProvider';
import { Timezone } from 'domains/shared/types/timezone';
import { ZoomAccountType } from 'domains/shared/types/zoomAccountType';
import {
  calculateTimeDifferenceInMinutes,
  getDateTimeWithTimezone,
  getInterpretersByLanguage,
} from 'helpers/clientHelper';
import { AuthUser } from 'shared/types/authUser';
import { BookingInterpreter } from 'shared/types/bookingInterpreter';
import { ClientReducer } from 'shared/types/client';
import { FreeTrialUser } from 'shared/types/freeTrialUser';
import { InterpretersByLanguage } from 'shared/types/interpretersByLanguage';
import { ZoomLanguage } from 'shared/types/zoomLanguage';

// ISO8601 dates can be sorted like any other strings.
// Reference: https://stackoverflow.com/a/12192544/2869111
const sortByDateAscending = (a: KUDOMeeting, b: KUDOMeeting): number => {
  if (a.start_time < b.start_time) {
    return -1;
  }

  if (a.start_time > b.start_time) {
    return 1;
  }

  return 0;
};

export const initialState: ClientReducer = {
  meetingsList: { meetings: [] as KUDOMeeting[] },
  meetingDetails: {} as MeetingDetails,
  isMeetingsListLoading: false,
  isLoadingDetails: false,
  zoomAuthorized: false,
  zoomLanguages: [] as ZoomLanguage[],
  timezones: [] as Timezone[],
  kudoBookingInterpreters: [] as BookingInterpreter[],
  booking: {} as BookingRequest,
  interpretersByLanguage: {} as InterpretersByLanguage,
  currentBalance: 0,
  isCreditsLoading: false,
  freeTrialUser: {} as FreeTrialUser,
  fetchFreeTrialUser: false,
};

type Client = (state: ClientReducer, action: ActionVariant) => void;

export const clientReducer: Client = (state = initialState, action = { type: '', payload: null }) => {
  let result;

  if (action.payload && checkDataHasProperty<GetZoomMeetingsSuccess>(action.payload, 'result')) {
    result = action.payload.result;
  } else {
    result = {};
  }

  if (checkDataHasProperty<ZoomMeetingsResponse>(result, 'meetings')) {
    result.meetings = uniqBy(result.meetings, 'id');
    result.meetings = orderBy(result.meetings, ['type', (meeting) => moment(meeting.start_time)], ['asc', 'asc']);
  }

  switch (action.type) {
    case constants.RESET_MEETINGS_LIST:
      return {
        ...state,
        meetingsList: { ...initialState.meetingsList },
        isMeetingsListLoading: true,
      };
    case constants.GET_ZOOM_MEETINGS:
      return {
        ...state,
        isMeetingsListLoading: true,
      };
    case constants.SET_BOOKING_REQUEST_PREPARATION_MATERIAL:
      return action.payload && checkDataHasProperty<SetBookingRequestPreparationMaterial>(action, 'payload')
        ? {
            ...state,
            booking: {
              ...state.booking,
              file_links: [...state.booking.file_links, ...action.payload.filter((item) => item.url_type === 'file')],
              links: [...state.booking.links, ...action.payload.filter((item) => item.url_type === 'link')],
            },
          }
        : state;
    case constants.GET_ZOOM_MEETINGS_SUCCESS: {
      if (!checkDataHasProperty<ZoomMeetingsResponse>(result, 'meetings')) {
        return state;
      }

      result.meetings.sort(sortByDateAscending);

      return {
        ...state,
        meetingsList: {
          ...result,
        },
        isMeetingsListLoading: false,
      };
    }
    case constants.GET_PREVIOUS_ZOOM_MEETINGS_SUCCESS: {
      return checkDataHasProperty<ZoomMeetingsResponse>(result, 'meetings')
        ? {
            ...state,
            meetingsList: {
              meetings: result.meetings
                .filter(
                  (meeting: KUDOMeeting) =>
                    getDateTimeWithTimezone(meeting.start_time, meeting.timezone)
                      .add(meeting.duration, 'minute')
                      .isBefore(moment()) && meeting.booking_id
                )
                .reverse(),
            },
            isMeetingsListLoading: false,
          }
        : state;
    }
    case constants.GET_CANCELLED_ZOOM_MEETINGS_SUCCESS: {
      const cancelMeetings = (result as BookingRequest[]).map((meeting: BookingRequest) => ({
        id: parseInt(meeting.meeting_id, 10),
        topic: meeting.meeting_title,
        start_time: meeting.starts_at,
        duration: calculateTimeDifferenceInMinutes(meeting.starts_at, meeting.ends_at),
        timezone: meeting.time_zone_code,
        booking_id: meeting.id,
        join_url: meeting.joining_links.join_url,
        type: ZOOM_LANGUAGE_TYPES.scheduled,
        canceled_at: meeting.canceled_at,
        platform:
          meeting.meeting_provider === MeetingProvider.NON_INTEGRATED
            ? meeting.nonintegrated_platform
            : getMeetingPlatform(meeting.meeting_provider),
        booking: {
          uuid: meeting.uuid,
          languages: meeting.languages,
          meeting_provider: meeting.meeting_provider,
        },
      }));

      return {
        ...state,
        meetingsList: { meetings: cancelMeetings },
        isMeetingsListLoading: false,
      };
    }
    case constants.GET_ZOOM_LANGUAGES:
      return {
        ...state,
        zoomLanguages: action.payload,
      };
    case commonConstants.GET_TIMEZONES:
      return {
        ...state,
        timezones: action.payload,
      };
    case constants.GET_ZOOM_MEETINGS_FAILURE:
      return {
        ...state,
        meetingsList: { meetings: [] },
        isMeetingsListLoading: false,
      };
    case constants.GET_ZOOM_MEETING_DETAILS:
      return {
        ...state,
        meetingDetails: {} as MeetingDetails,
        isLoadingDetails: true,
        kudoBookingInterpreters: [],
        booking: {} as BookingRequest,
      };
    case constants.GET_ZOOM_MEETING_DETAILS_SUCCESS: {
      if (
        checkDataHasProperty<GetZoomMeetingDetailsSuccess>(action.payload, 'booking') &&
        checkDataHasProperty<GetZoomMeetingDetailsSuccess>(action.payload, 'meeting') &&
        checkDataHasProperty<GetZoomMeetingDetailsSuccess>(action.payload, 'bookingInterpreters')
      ) {
        const { meeting, booking: currentBooking, bookingInterpreters } = action.payload;

        return {
          ...state,
          meetingDetails: meeting,
          isLoadingDetails: false,
          kudoBookingInterpreters: bookingInterpreters,
          booking: currentBooking,
          interpretersByLanguage: getInterpretersByLanguage({
            bookingInterpreters,
            zoomLanguagesHash: getLanguagesHash(),
            bookingLanguages: currentBooking.languages || [],
            languages:
              currentBooking.meeting_provider === MeetingProvider.NON_INTEGRATED ? AGNOSTIC_LANGUAGES : ZOOM_LANGUAGES,
          }),
        };
      }

      return state;
    }
    case constants.GET_ZOOM_MEETING_DETAILS_FAILURE:
      return {
        ...state,
        meetingDetails: {} as MeetingDetails,
        isLoadingDetails: false,
        kudoBookingInterpreters: [],
      };
    case constants.UPDATE_AGNOSTIC_MEETING_INTERPRETERS:
      return {
        ...state,
        isLoadingDetails: true,
        kudoBookingInterpreters: [],
        interpretersByLanguage: {} as InterpretersByLanguage,
      };
    case constants.UPDATE_AGNOSTIC_MEETING_INTERPRETERS_SUCCESS: {
      if (
        checkDataHasProperty<GetZoomMeetingDetailsSuccess>(action.payload, 'booking') &&
        checkDataHasProperty<GetZoomMeetingDetailsSuccess>(action.payload, 'bookingInterpreters')
      ) {
        const { booking: currentBooking, bookingInterpreters } = action.payload;

        return {
          ...state,
          isLoadingDetails: false,
          kudoBookingInterpreters: bookingInterpreters,
          interpretersByLanguage: getInterpretersByLanguage({
            bookingInterpreters,
            zoomLanguagesHash: getLanguagesHash(),
            bookingLanguages: currentBooking.languages || [],
            languages:
              currentBooking.meeting_provider === MeetingProvider.NON_INTEGRATED ? AGNOSTIC_LANGUAGES : ZOOM_LANGUAGES,
          }),
        };
      }

      return state;
    }
    case constants.UPDATE_AGNOSTIC_MEETING_INTERPRETERS_FAILURE:
      return {
        ...state,
        isLoadingDetails: false,
      };
    case constants.GET_KUDO_BOOKING_INTERPRETERS:
    case constants.GET_KUDO_BOOKING_INTERPRETERS_FAILURE:
      return {
        ...state,
        kudoBookingInterpreters: [],
      };
    case constants.GET_KUDO_BOOKING_INTERPRETERS_SUCCESS:
      return {
        ...state,
        kudoBookingInterpreters: action.payload,
      };
    case GET_SIGNIN_SUCCESS: {
      if (checkDataHasProperty<AuthUser>(action.payload, 'zoom_account_type')) {
        const { zoom_account_type: zoomAccountType } = action.payload;

        return {
          ...state,
          zoomAuthorized: zoomAccountType === ZoomAccountType.BUSINESS,
        };
      }

      return state;
    }
    case constants.SET_ZOOM_AUTHORIZED:
      return {
        ...state,
        zoomAuthorized: true,
      };
    case constants.SET_ZOOM_DEAUTHORIZED:
      return {
        ...state,
        zoomAuthorized: false,
      };
    case constants.FORCE_SET_ZOOM_AUTHORIZED_STATE:
      return checkDataHasProperty<ForceSetZoomAuthorizedState>(action.payload, 'isZoomBusinessUserAuthorized')
        ? {
            ...state,
            zoomAuthorized: action.payload.isZoomBusinessUserAuthorized,
          }
        : state;
    case constants.GET_ZOOM_BOOKING:
      return {
        ...state,
        booking: {} as BookingRequest,
      };
    case constants.GET_ZOOM_BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.payload,
      };
    case constants.DELETE_MEETING:
    case constants.DELETE_ZOOM_BASIC_MEETING:
    case constants.DELETE_WEBINAR:
    case constants.DELETE_NON_INTEGRATED_MEETING:
      return {
        ...state,
        meetingsList: {
          ...state.meetingsList,
          meetings: state.meetingsList.meetings.filter((meeting) => meeting.id !== action.payload),
        },
      };
    case constants.GET_CLIENT_BALANCE_REQUEST:
      return {
        ...state,
        isCreditsLoading: true,
      };
    case constants.GET_CLIENT_BALANCE_SUCCESS:
      return {
        ...state,
        currentBalance: action.payload,
        isCreditsLoading: false,
      };
    case constants.DELETE_BOOKING_REQUEST_PREPARATION_MATERIAL:
      return {
        ...state,
        booking: {
          ...state.booking,
          file_links: [
            ...state.booking.file_links.filter(
              (item) =>
                item.url_type === 'file' && item.id !== (action.payload as DeleteBookingRequestPreparationMaterial).id
            ),
          ],
          links: [
            ...state.booking.links.filter(
              (item) =>
                item.url_type === 'link' && item.id !== (action.payload as DeleteBookingRequestPreparationMaterial).id
            ),
          ],
        },
      };
    case constants.UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        isLoadingDetails: true,
      };
    case constants.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.payload,
        isLoadingDetails: false,
      };
    case constants.UPDATE_BOOKING_FAILURE:
      return {
        ...state,
        booking: [],
        isLoadingDetails: false,
      };
    case constants.UPDATE_MEETING_SUCCESS:
      return {
        ...state,
        meetingDetails: action.payload,
      };
    case constants.CLEANUP_CLIENT_MEETING_PAGE: {
      return {
        ...state,
        isMeetingsListLoading: false,
        isLoadingDetails: false,
        meetingsList: {
          ...state.meetingsList,
          meetings: [],
        },
        meetingDetails: {} as MeetingDetails,
      };
    }
    case constants.GET_FREE_TRIAL_USER:
      return {
        ...state,
        isLoadingDetails: true,
      };
    case constants.GET_FREE_TRIAL_USER_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        freeTrialUser: action.payload,
      };
    case constants.GET_FREE_TRIAL_USER_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        freeTrialUser: {},
      };
    case constants.UPDATE_FREE_TRIAL_USER:
      return {
        ...state,
        isLoadingDetails: true,
      };
    case constants.UPDATE_FREE_TRIAL_USER_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        freeTrialUser: action.payload,
      };
    case constants.UPDATE_FREE_TRIAL_USER_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        freeTrialUser: {},
      };
    case constants.UPDATE_FETCH_FREE_TRAIL_USER:
      return {
        ...state,
        fetchFreeTrialUser: true,
      };

    default:
      return state;
  }
};
