import { Bundle } from 'domains/client/types/bundle';
import { FeatureFlags } from 'domains/client/types/featureFlags';
import { Organization } from 'domains/client/types/organization';
import { TranslationService } from 'domains/client/types/translationService';
import { AxiosPromise } from 'domains/shared/types/axiosPromise';
import { BookingAdditionalCost } from 'domains/shared/types/bookingAdditionalCost';
import { BookingAdditionalCostParams } from 'domains/shared/types/BookingAdditionalCostParams';
import { BookingParams } from 'domains/shared/types/bookingParams';
import { Transaction } from 'domains/shared/types/transaction';
import baseService from 'services/baseService';
import { AiBundle } from 'shared/types/AiBundle';

const API = {
  isMeetingBookable: '/bookable',
  bookingAdditionalCost: '/additional_cost',
  currentWalletBalance: '/kudo/payments/balance',
  currentWalletTransactions: '/kudo/payments/transactions',
  userOrganizations: '/kudo/payments/organizations',
  featureFlags: '/feature_flags',
  bundlePrices: '/bundle_prices',
  aiPricingBundle: '/ai_pricing_bundles',
  translations: '/translations',
} as const;

export enum GetZoomLanguagesOrder {
  ASC = 'asc',
  DESC = 'desc',
}

type CheckIsMeetingBookable = (params: BookingParams) => AxiosPromise<{ bookable: boolean }>;

export const checkIsMeetingBookable: CheckIsMeetingBookable = (params) =>
  baseService.get(API.isMeetingBookable, { params });

type CreateBookingAdditionalCost = (payload: BookingAdditionalCostParams) => AxiosPromise<BookingAdditionalCost>;

export const createBookingAdditionalCost: CreateBookingAdditionalCost = (payload) =>
  baseService.post(API.bookingAdditionalCost, payload);

export const getClientCurrentBalance = (
  organizationId?: string,
  signal?: AbortSignal
): AxiosPromise<{ balance: number }> => {
  const baseUrl = API.currentWalletBalance;
  const url = organizationId ? `${baseUrl}?organization_id=${organizationId}` : baseUrl;

  return baseService.get(url, {
    signal,
  });
};

type GetTransactions = (
  payload: { page: number; signal?: AbortSignal },
  organizationId?: string
) => AxiosPromise<Transaction[]>;

export const getTransactions: GetTransactions = (payload, organizationId) => {
  if (organizationId) {
    return baseService.get(`${API.currentWalletTransactions}?organization_id=${organizationId}`, payload);
  }

  return baseService.get(API.currentWalletTransactions, payload);
};

type GetOrganizations = () => AxiosPromise<{ organizations: Organization[] }>;

export const getOrganizations: GetOrganizations = () => baseService.get(API.userOrganizations);

type GetServerFeatureFlags = () => AxiosPromise<FeatureFlags>;

export const serverFeatureFlagsApi: GetServerFeatureFlags = (): AxiosPromise<FeatureFlags> =>
  baseService.get<FeatureFlags>(API.featureFlags);

type RestartTranslationService = (meetingId: string) => AxiosPromise<TranslationService>;

export const translationServiceApi: RestartTranslationService = (meetingId: string): AxiosPromise<TranslationService> =>
  baseService.put<TranslationService>(`${API.translations}/${meetingId}`);

type GetBundles = (signal?: AbortSignal) => AxiosPromise<Bundle[]>;

export const getBundles: GetBundles = (signal) => baseService.get(API.bundlePrices, { signal });

type GetAiPricingBundles = (signal?: AbortSignal) => AxiosPromise<AiBundle[]>;

export const getAiPricingBundles: GetAiPricingBundles = (signal) => baseService.get(API.aiPricingBundle, { signal });
