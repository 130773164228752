export enum MeetingPlatform {
  ZOOM_MEETING = 'zoom_meeting',
  ZOOM_WEBINAR = 'zoom_webinar',
  ZOOM_BASIC = 'zoom_basic',
}

export enum IntegratedPlatform {
  ZOOM = 'Zoom',
}

export enum NonIntegratedPlatform {
  MSTEAMS_MEETING = 'Teams',
  WEBEX_MEETING = 'Webex',
  BLUEJEANS_MEETING = 'Bluejeans',
  KUDO_MEETING = 'KUDO',
  INTERPREFY_MEETING = 'Interprefy',
  INTERACTIO_MEETING = 'Interactio',
  INPERSON_MEETING = 'In-Person',
  OTHER = 'Other',
}
