import * as constants from 'constants/customWordsConstants';

export const initialState = {
  customWords: [],
};

const customWordsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GET_CUSTOM_WORDS_SUCCESS:
    case constants.CREATE_CUSTOM_WORDS_SUCCESS:
      return {
        ...state,
        customWords: action.payload,
      };
    case constants.GET_CUSTOM_WORDS_ERROR:
    case constants.CREATE_CUSTOM_WORDS_ERROR:
      return {
        ...state,
        customWords: [],
      };
    case constants.DELETE_CUSTOM_WORDS_SUCCESS:
      return {
        ...state,
        customWords: state.customWords.filter((word) => word.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default customWordsReducer;
