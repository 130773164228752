import { ZOOM_LANGUAGES } from 'domains/shared/constants/zoomLanguages';
import { ZoomLanguage } from 'shared/types/zoomLanguage';

export const AGNOSTIC_LANGUAGES: ZoomLanguage[] = [
  ...ZOOM_LANGUAGES,
  {
    type: 'NL',
    name: 'Bulgarian',
    code: 'BUL',
    zoom_code: 'BUL',
  },
  {
    type: 'NL',
    name: 'Croatian',
    code: 'HRV',
    zoom_code: 'HRV',
  },
  {
    type: 'NL',
    name: 'Czech',
    code: 'CZE',
    zoom_code: 'CZE',
  },
  {
    type: 'NL',
    name: 'Danish',
    code: 'DAN',
    zoom_code: 'DAN',
  },
  {
    type: 'NL',
    name: 'Dutch',
    code: 'DUT',
    zoom_code: 'DUT',
  },
  {
    type: 'NL',
    name: 'Estonian',
    code: 'EST',
    zoom_code: 'EST',
  },
  {
    type: 'NL',
    name: 'Finnish',
    code: 'FIN',
    zoom_code: 'FIN',
  },
  {
    type: 'NL',
    name: 'Greek',
    code: 'GRK',
    zoom_code: 'GRK',
  },
  {
    type: 'NL',
    name: 'Hungarian',
    code: 'HUN',
    zoom_code: 'HUN',
  },
  {
    type: 'NL',
    name: 'Irish',
    code: 'GLE',
    zoom_code: 'GLE',
  },
  {
    type: 'NL',
    name: 'Latvian',
    code: 'LAV',
    zoom_code: 'LAV',
  },
  {
    type: 'NL',
    name: 'Lithuanian',
    code: 'LIT',
    zoom_code: 'LIT',
  },
  {
    type: 'NL',
    name: 'Maltese',
    code: 'MLT',
    zoom_code: 'MLT',
  },
  {
    type: 'NL',
    name: 'Polish',
    code: 'POL',
    zoom_code: 'POL',
  },
  {
    type: 'NL',
    name: 'Romanian',
    code: 'RUM',
    zoom_code: 'RUM',
  },
  {
    type: 'NL',
    name: 'Slovak',
    code: 'SLO',
    zoom_code: 'SLO',
  },
  {
    type: 'NL',
    name: 'Slovenian',
    code: 'SLV',
    zoom_code: 'SLV',
  },
  {
    type: 'NL',
    name: 'Swedish',
    code: 'SWE',
    zoom_code: 'SWE',
  },
];
