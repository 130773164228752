import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { routes } from 'domains/shared/constants/routes';

export const OldAuthorizeZoom = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.push(routes.clientAuthorizeZoom + location.search);
  }, [history, location]);

  return <div>Redirecting...</div>;
};
