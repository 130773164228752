import { DocumentVariant } from 'domains/shared/types/documentVariant';

export const documents = [
  {
    id: 2629,
    link: 'https://kmpdevelopment.eversign.com/document/7736cd52f6b24669a873d164664aa34f-f569c16a60a14b6592c6117aa0094670/sign',
    type: DocumentVariant.CONTRACT,
    signed_at: '2021-06-14T19:24:57.539Z',
    user_id: 304,
    completed: false,
    user_type: 'interpreter',
    extra: {
      hash: '7736cd52f6b24669a873d164664aa34f',
    },
  },
  {
    id: 2628,
    link: 'https://kmppreprod.eversign.com/document/5d9d40fb47b549a2ad727ff5868d2de7-516a8e737cee47ebb816d0045ad942ff/sign',
    type: DocumentVariant.NDA,
    signed_at: '2021-06-14T19:25:29.289Z',
    user_id: 304,
    completed: false,
    user_type: 'interpreter',
    extra: {
      hash: '5d9d40fb47b549a2ad727ff5868d2de7',
    },
  },
  {
    id: 2627,
    link: 'https://kmppreprod.eversign.com/document/5d9d40fb47b549a2ad727ff5868d2de7-516a8e737cee47ebb816d0045ad942ff/sign',
    type: DocumentVariant.GUIDELINES,
    signed_at: '2021-06-14T19:25:29.289Z',
    user_id: 304,
    completed: false,
    user_type: 'interpreter',
    extra: {
      hash: '5d9d40fb47b549a2ad727ff5868d2de7',
    },
  },
];

export const unsignedDocuments = [
  {
    id: 2629,
    link: 'https://kmpdevelopment.eversign.com/document/7736cd52f6b24669a873d164664aa34f-f569c16a60a14b6592c6117aa0094670/sign',
    type: DocumentVariant.CONTRACT,
    signed_at: null,
    user_id: 304,
    completed: false,
    user_type: 'interpreter',
    extra: {
      hash: '7736cd52f6b24669a873d164664aa34f',
    },
  },
  {
    id: 2628,
    link: 'https://kmppreprod.eversign.com/document/5d9d40fb47b549a2ad727ff5868d2de7-516a8e737cee47ebb816d0045ad942ff/sign',
    type: DocumentVariant.NDA,
    signed_at: null,
    user_id: 304,
    completed: false,
    user_type: 'interpreter',
    extra: {
      hash: '5d9d40fb47b549a2ad727ff5868d2de7',
    },
  },
  {
    id: 2627,
    link: 'https://kmppreprod.eversign.com/document/5d9d40fb47b549a2ad727ff5868d2de7-516a8e737cee47ebb816d0045ad942ff/sign',
    type: DocumentVariant.GUIDELINES,
    signed_at: null,
    user_id: 304,
    completed: false,
    user_type: 'interpreter',
    extra: {
      hash: '5d9d40fb47b549a2ad727ff5868d2de7',
    },
  },
];
