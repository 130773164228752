export const TERMS_CONDITIONS_LINK = 'https://kudo.ai/terms-of-use/';
export const COOKIE_POLICY_LINK = 'https://kudoway.com/cookies-policy/';
export const PRIVACY_POLICY_LINK = 'https://kudoway.com/privacy-policy/';
export const GETTING_STARTED_LINK = 'https://kudoway.com/zoom/book-an-interpreter/';
export const KUDO_CONTACT_US_LINK = 'https://kudoway.com/contact/';
export const MY_PROFILE_PAGE_LINK = `${process.env.REACT_APP_SSO_BASE_URL}/profile`;
export const EMBEDDED_VIDEO_LINK = 'https://www.youtube.com/embed/3U04QPoHGFs?autoplay=1';
export const EMBEDDED_VIDEO_PLACEHOLDER = 'https://i3.ytimg.com/vi/3U04QPoHGFs/maxresdefault.jpg';
export const KUDO_NEED_DIFFERENT_LANGUAGE_LINK =
  'https://kudoway.com/contact/marketplace-zoom?kmp_type_of_inquiry=I%20need%20a%20different%20language';
export const REQUEST_DEMO_LINK = 'https://kudoway.com/resources/schedule-a-demo/';
export const FAQ_PAGE_LINK = 'https://support.kudoway.com/hc/en-us/articles/12021025289623';
export const TERMS_AND_CONDITIONS_LINK = 'https://kudo.ai/terms-of-use/';
export const AI_BOOKING_SURVEY_LINK = 'https://forms.monday.com/forms/embed/4d3dc095ac8bddf07d6a2b0bba4e8030?r=use1';
export const END_OF_BOOKING_SURVEY_LINK =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://forms.monday.com/forms/embed/376b872f11ad56c7bb00e2c0384041ad?r=use1'
    : 'https://forms.monday.com/forms/embed/cde519b1e9ae5412f6aa34a32f31f8e8?r=use1';
export const AI_MARKETING_PAGE_LINK = 'https://kudoway.com/solutions/kudo-ai-speech-translator/ ';
export const AI_TERMS_CONDITIONS_LINK = 'https://kudo.ai/terms-of-use/';
export const AI_INTERACTIVE_DEMO_LINK = 'https://ai-demo.kudoway.com';
