import moment from 'moment';

import { LegacyTransactionType } from 'shared/types/legacyTransactionType';

export const convertArray = (objects) => (objects && objects.map((object) => object.label)) || [];

export const getSubjectMatterIds = (objects) => (objects && objects.map((object) => object.value)) || [];

export const getSubjectMatterNames = (objects) => (objects && objects.map((object) => object.subject)) || [];

export const mapDropdownValues = (values) => values && values.map((label) => ({ label, value: label }));

export const languagesObjects = (languages) => languages.map((lang) => ({ label: lang.name, value: lang.name }));

export const subjectMattersObjects = (matters) =>
  (matters && matters.map((matter) => ({ label: matter.subject, value: matter.id }))) || [];

export const countriesObjects = (countries) =>
  countries.map((country) => ({ label: country.name, value: country.name }));

export const getTimezoneOptions = (timezones) =>
  timezones.map((timezone) => ({
    label: `${timezone.name} (UTC ${timezone.offset})`,
    value: timezone.name,
  }));

export const setInterpreterProfileData = (data) => ({
  ...data,
  languages_a: convertArray(data.languages_a),
  languages_b: convertArray(data.languages_b),
  languages_c: convertArray(data.languages_c),
  native_languages: convertArray(data.native_languages),
  sign_languages: convertArray(data.sign_languages),
});

export const PastDateRanges = {
  Yesterday: [moment().utc().subtract(1, 'days'), moment().utc().subtract(1, 'days')],
  'Last 7 Days': [moment().utc().subtract(6, 'days'), moment().utc()],
  'Last 30 Days': [moment().utc().subtract(29, 'days'), moment().utc()],
  'This Month': [moment().utc().startOf('month'), moment().utc()],
  'Last Month': [
    moment().utc().subtract(1, 'month').startOf('month'),
    moment().utc().subtract(1, 'month').endOf('month'),
  ],
};

export const FutureDateRanges = {
  'Next Week': [moment().add(1, 'week').startOf('week'), moment().add(1, 'week').endOf('week')],
  'Next 30 Days': [moment(), moment().add(29, 'days')],
  'Rest of This Month': [moment(), moment().endOf('month')],
  'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
};

export const range = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);

export const degrees = ['Matric', 'Inter', 'Graduation', 'Masters'];

export const prepareFilterLanguagesData = (values, options = {}) => {
  const wayType = values.way === 'two_way';
  const languageCombinations = (values.language_combinations || [])
    .filter((i) => i.from.length)
    .map((comb) => ({
      from: comb.from,
      to: comb.to,
      both_ways: wayType,
    }));

  return { ...options, ...values, language_combinations: languageCombinations };
};

export const languageByArray = (objects) => objects.map((object) => ({ label: object.name, value: object.code }));
export const combLanguageByArray = (objects) => objects.map((object) => ({ name: object.name, value: object.code }));
export const subjectMatterByArray = (objects) => objects.map((object) => ({ label: object.subject, value: object.id }));
export const subjectMatterByArrayName = (subjectMatters) =>
  subjectMatters.map((subjectMatter) => ({ name: subjectMatter.subject, value: subjectMatter.id }));
export const getCountriesOptions = (objects) => objects.map((object) => ({ label: object.name, value: object.name }));
export const getPlatformsOptions = (platforms) =>
  platforms.map((platform) => ({ label: platform.name, value: platform.id }));

export const validateHoursAndMinutes = (hours, minutes) => Boolean(hours > 0 || (hours === 0 && minutes > 0));

export const parsedNaN = (val) => (Number.isNaN(val) ? 0 : val);

export const getRemainingHours = (startTime) => {
  const rem = moment(startTime).diff(moment(), 'hours');
  const arr = [0, 3, 6, 12, 24].filter((item) => item < rem);

  if (arr.length === 0) {
    return [0];
  }

  return arr;
};

export const handleRemainingBalance = (balance, amount, type) => {
  let remainingBalance;

  switch (type) {
    case LegacyTransactionType.CREDIT:
      remainingBalance = parseFloat(balance.amount) + parseFloat(amount);
      remainingBalance = Object.is(remainingBalance, NaN)
        ? `$ ${balance.amount} USD`
        : `$ ${remainingBalance.toFixed(1)} USD`;
      break;
    case LegacyTransactionType.DEBIT:
      remainingBalance = parseFloat(balance.amount) - parseFloat(amount);
      remainingBalance = Object.is(remainingBalance, NaN)
        ? `$ ${balance.amount} USD`
        : `$ ${remainingBalance.toFixed(1)} USD`;
      break;
    default:
      remainingBalance = `$ ${balance.amount} USD`;
  }

  return remainingBalance;
};

export const updateBulkInterpretersActiveStatus = (oldRecords, newRecords) => {
  let finalData = oldRecords;

  newRecords.forEach((newInterpreter) => {
    finalData = finalData.map((oldInterpreter) => {
      if (newInterpreter.id !== oldInterpreter.id) return oldInterpreter;

      return { ...oldInterpreter, active: newInterpreter.active };
    });
  });

  return finalData;
};

export const getLanguageCodes = (commonLanguages, languageNames) => {
  const finalData = [];

  languageNames.forEach((languageName) => {
    const language = commonLanguages.find((lang) => lang.name === languageName);

    if (language) finalData.push(language.code);
    else finalData.push(languageName);
  });

  return finalData;
};

export const prepareCSVData = (interpreters) => {
  const csvData = [];

  interpreters.forEach((interpreter) => {
    const obj = {
      first_name: interpreter.first_name,
      last_name: interpreter.last_name,
      email: interpreter.email,
      languages_a: interpreter.languages_a,
      languages_b: interpreter.languages_b,
      languages_c: interpreter.languages_c,
      profile: interpreter.public_access ? 'Public' : 'Private',
      status: interpreter.active ? 'Active' : 'InActive',
    };

    csvData.push(obj);
  });

  return csvData;
};
