export enum BookingRequestStatus {
  NEW_REQUEST = 'new_request',
  IN_PROGRESS = 'in_progress',
  CONFIRMED = 'confirmed',
  PENDING_REVIEW = 'pending_review',
  COMPLETED = 'completed',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}
