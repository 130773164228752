import { Notification } from 'shared/types/notification';

import { NotificationMessageBody } from './NotificationMessageBody';

interface Props {
  notification: Notification;
}

const NotificationMessage = ({ notification }: Props): JSX.Element => (
  <div className="notification-content">
    <NotificationMessageBody notification={notification} />
  </div>
);

export { NotificationMessage };
