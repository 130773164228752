import { documents } from 'shared/fixtures/documents';
import { InterpreterStatus } from 'shared/types/interpreterStatus';
import { OnCallShiftPlanInitialValues } from 'shared/types/onCallShiftPlanInitialValues';
import { OnCallShiftStatus } from 'shared/types/onCallShiftStatus';

export const initialValues: OnCallShiftPlanInitialValues = {
  id: 0,
  start_date: '02-09-2022',
  end_date: '03-09-2022',
  shift_languages_attributes: [
    {
      language: 'Chinese',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'French',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'German',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'Italian',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'Portuguese',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'Spanish',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
  ],
  status: OnCallShiftStatus.COMPLETED,
  shift_languages: [],
  shift_interpreter: {
    id: 100,
    first_name: 'John',
    last_name: 'Doe',
    full_name: 'John Doe',
    kudo_experience: '',
    languages_a: [],
    languages_b: [],
    languages_c: [],
    public_access: '',
    booking_count: 1,
    profile_access: true,
    deleted_at: null,
    status: InterpreterStatus.ACCEPTED,
    interpretation_rate: null,
    interpreter: {
      id: 100,
      uuid: 'uuid',
      first_name: 'John',
      last_name: 'Doe',
      full_name: 'John Doe',
      city: '',
      country: '',
      state: '',
      zipcode: '',
      email: '',
      phone: '',
      age_group: '',
      gender: 'male',
      badges: {
        is_kudo_pro: true,
        is_kudo_certified: true,
      },
      birth_month: 1,
      birth_day: 12,
      is_available: true,
      kudo_certified: true,
      kudo_experience: null,
      kudo_pro: true,
      kudo_trainee: false,
      kudo_junior: false,
      languages_a: [],
      languages_b: [],
      languages_c: [],
      native_languages: [],
      sign_interpreter: false,
      sign_languages: [],
      time_zone: 'Asia/Tashkent',
      nda_signed: true,
      nda_document_link: '',
      subject_matters: [],
      secondary_subject_matters: [],
      user_id: 10,
      profile_photo: '',
      active: true,
      public_access: true,
      signed_documents: true,
      interpreter_documents: documents,
    },
    language_combinations: [
      {
        id: 0,
        from_code: '',
        from_language: '',
        to_code: '',
        to_language: '',
      },
    ],
  },
};

export const createShiftPlan = (
  shiftStatus: OnCallShiftStatus,
  shiftInterpreterStatus = InterpreterStatus.NEW_OFFER
): OnCallShiftPlanInitialValues => ({
  start_date: new Date().toString(),
  end_date: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toString(),
  id: 1,
  status: shiftStatus,
  shift_interpreter: {
    id: 1262,
    status: InterpreterStatus.NEW_OFFER,
    response_due_by: '2021-06-10T10:40:12.670Z',
    first_name: 'John',
    last_name: 'Doe',
    full_name: 'John Doe',
    kudo_experience: '',
    languages_a: [],
    languages_b: [],
    languages_c: [],
    public_access: '',
    booking_count: 1,
    profile_access: true,
    deleted_at: null,
    interpretation_rate: null,
    interpreter: {
      id: 100,
      uuid: 'uuid',
      first_name: 'John',
      last_name: 'Doe',
      full_name: 'John Doe',
      city: '',
      country: '',
      state: '',
      zipcode: '',
      email: '',
      phone: '',
      age_group: '',
      gender: 'male',
      badges: {
        is_kudo_pro: true,
        is_kudo_certified: true,
      },
      birth_month: 1,
      birth_day: 12,
      is_available: true,
      kudo_certified: true,
      kudo_experience: null,
      kudo_pro: true,
      kudo_trainee: false,
      kudo_junior: false,
      languages_a: [],
      languages_b: [],
      languages_c: [],
      native_languages: [],
      sign_interpreter: false,
      sign_languages: [],
      time_zone: 'Asia/Tashkent',
      nda_signed: true,
      nda_document_link: '',
      subject_matters: [],
      secondary_subject_matters: [],
      user_id: 10,
      profile_photo: '',
      active: true,
      public_access: true,
      signed_documents: true,
      interpreter_documents: documents,
    },
    language_combinations: [
      {
        id: 0,
        from_code: '',
        from_language: '',
        to_code: '',
        to_language: '',
      },
    ],
  },
  shift_languages: [
    {
      language: 'French',
      interpreters_count: 2,
      id: 1,
      shift_details: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          converted_end_time: new Date('August 19, 1975 17:00'),
          converted_start_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
          id: 2,
          shift_interpreters: [
            {
              id: 0,
              shift_detail_id: 1,
              status: shiftInterpreterStatus,
              interpreter_id: 3,
              response_due_by: new Date('August 19, 1975 09:00'),
              interpreter: {
                id: 100,
                first_name: 'John',
                last_name: 'Doe',
                full_name: 'John Doe',
                kudo_experience: '',
                languages_a: [],
                languages_b: [],
                languages_c: [],
                public_access: '',
                booking_count: 1,
                profile_access: true,
                deleted_at: null,
                status: InterpreterStatus.ACCEPTED,
                interpretation_rate: null,
                interpreter: {
                  id: 100,
                  uuid: 'uuid',
                  first_name: 'John',
                  last_name: 'Doe',
                  full_name: 'John Doe',
                  city: '',
                  country: '',
                  state: '',
                  zipcode: '',
                  email: '',
                  phone: '',
                  age_group: '',
                  gender: 'male',
                  badges: {
                    is_kudo_pro: true,
                    is_kudo_certified: true,
                  },
                  birth_month: 1,
                  birth_day: 12,
                  is_available: true,
                  kudo_certified: true,
                  kudo_experience: null,
                  kudo_pro: true,
                  kudo_trainee: false,
                  kudo_junior: false,
                  languages_a: [],
                  languages_b: [],
                  languages_c: [],
                  native_languages: [],
                  sign_interpreter: false,
                  sign_languages: [],
                  time_zone: 'Asia/Tashkent',
                  nda_signed: true,
                  nda_document_link: '',
                  subject_matters: [],
                  secondary_subject_matters: [],
                  user_id: 10,
                  profile_photo: '',
                  active: true,
                  public_access: true,
                  signed_documents: true,
                  interpreter_documents: documents,
                },
                language_combinations: [
                  {
                    id: 0,
                    from_code: '',
                    from_language: '',
                    to_code: '',
                    to_language: '',
                  },
                ],
              },
            },
            {
              id: 0,
              shift_detail_id: 1,
              status: shiftInterpreterStatus,
              interpreter_id: 3,
              response_due_by: new Date('August 19, 1975 09:00'),
              interpreter: {
                id: 100,
                first_name: 'John',
                last_name: 'Doe',
                full_name: 'John Doe',
                kudo_experience: '',
                languages_a: [],
                languages_b: [],
                languages_c: [],
                public_access: '',
                booking_count: 1,
                profile_access: true,
                deleted_at: null,
                status: InterpreterStatus.ACCEPTED,
                interpretation_rate: null,
                interpreter: {
                  id: 100,
                  uuid: 'uuid',
                  first_name: 'John',
                  last_name: 'Doe',
                  full_name: 'John Doe',
                  city: '',
                  country: '',
                  state: '',
                  zipcode: '',
                  email: '',
                  phone: '',
                  age_group: '',
                  gender: 'male',
                  badges: {
                    is_kudo_pro: true,
                    is_kudo_certified: true,
                  },
                  birth_month: 1,
                  birth_day: 12,
                  is_available: true,
                  kudo_certified: true,
                  kudo_experience: null,
                  kudo_pro: true,
                  kudo_trainee: false,
                  kudo_junior: false,
                  languages_a: [],
                  languages_b: [],
                  languages_c: [],
                  native_languages: [],
                  sign_interpreter: false,
                  sign_languages: [],
                  time_zone: 'Asia/Tashkent',
                  nda_signed: true,
                  nda_document_link: '',
                  subject_matters: [],
                  secondary_subject_matters: [],
                  user_id: 10,
                  profile_photo: '',
                  active: true,
                  public_access: true,
                  signed_documents: true,
                  interpreter_documents: documents,
                },
                language_combinations: [
                  {
                    id: 0,
                    from_code: '',
                    from_language: '',
                    to_code: '',
                    to_language: '',
                  },
                ],
              },
            },
            {
              id: 0,
              shift_detail_id: 1,
              status: shiftInterpreterStatus,
              interpreter_id: 3,
              response_due_by: new Date('August 19, 1975 09:00'),
              interpreter: {
                id: 100,
                first_name: 'John',
                last_name: 'Doe',
                full_name: 'John Doe',
                kudo_experience: '',
                languages_a: [],
                languages_b: [],
                languages_c: [],
                public_access: '',
                booking_count: 1,
                profile_access: true,
                deleted_at: null,
                status: InterpreterStatus.ACCEPTED,
                interpretation_rate: null,
                interpreter: {
                  id: 100,
                  uuid: 'uuid',
                  first_name: 'John',
                  last_name: 'Doe',
                  full_name: 'John Doe',
                  city: '',
                  country: '',
                  state: '',
                  zipcode: '',
                  email: '',
                  phone: '',
                  age_group: '',
                  gender: 'male',
                  badges: {
                    is_kudo_pro: true,
                    is_kudo_certified: true,
                  },
                  birth_month: 1,
                  birth_day: 12,
                  is_available: true,
                  kudo_certified: true,
                  kudo_experience: null,
                  kudo_pro: true,
                  kudo_trainee: false,
                  kudo_junior: false,
                  languages_a: [],
                  languages_b: [],
                  languages_c: [],
                  native_languages: [],
                  sign_interpreter: false,
                  sign_languages: [],
                  time_zone: 'Asia/Tashkent',
                  nda_signed: true,
                  nda_document_link: '',
                  subject_matters: [],
                  secondary_subject_matters: [],
                  user_id: 10,
                  profile_photo: '',
                  active: true,
                  public_access: true,
                  signed_documents: true,
                  interpreter_documents: documents,
                },
                language_combinations: [
                  {
                    id: 0,
                    from_code: '',
                    from_language: '',
                    to_code: '',
                    to_language: '',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      language: 'German',
      interpreters_count: 2,
      id: 2,
      shift_details: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          converted_end_time: new Date('August 19, 1975 17:00'),
          converted_start_time: new Date('August 19, 1975 09:00'),
          interpreters_count: 0,
          id: 3,
          shift_interpreters: [
            {
              id: 0,
              shift_detail_id: 1,
              status: shiftInterpreterStatus,
              interpreter_id: 3,
              response_due_by: new Date('August 19, 1975 09:00'),
              interpreter: {
                id: 100,
                first_name: 'John',
                last_name: 'Doe',
                full_name: 'John Doe',
                kudo_experience: '',
                languages_a: [],
                languages_b: [],
                languages_c: [],
                public_access: '',
                booking_count: 1,
                profile_access: true,
                deleted_at: null,
                status: InterpreterStatus.ACCEPTED,
                interpretation_rate: null,
                interpreter: {
                  id: 100,
                  uuid: 'uuid',
                  first_name: 'John',
                  last_name: 'Doe',
                  full_name: 'John Doe',
                  city: '',
                  country: '',
                  state: '',
                  zipcode: '',
                  email: '',
                  phone: '',
                  age_group: '',
                  gender: 'male',
                  badges: {
                    is_kudo_pro: true,
                    is_kudo_certified: true,
                  },
                  birth_month: 1,
                  birth_day: 12,
                  is_available: true,
                  kudo_certified: true,
                  kudo_experience: null,
                  kudo_pro: true,
                  kudo_trainee: false,
                  kudo_junior: false,
                  languages_a: [],
                  languages_b: [],
                  languages_c: [],
                  native_languages: [],
                  sign_interpreter: false,
                  sign_languages: [],
                  time_zone: 'Asia/Tashkent',
                  nda_signed: true,
                  nda_document_link: '',
                  subject_matters: [],
                  secondary_subject_matters: [],
                  user_id: 10,
                  profile_photo: '',
                  active: true,
                  public_access: true,
                  signed_documents: true,
                  interpreter_documents: documents,
                },
                language_combinations: [
                  {
                    id: 0,
                    from_code: '',
                    from_language: '',
                    to_code: '',
                    to_language: '',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  shift_languages_attributes: [
    {
      language: 'Chinese',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'French',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'German',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'Italian',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'Portuguese',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
    {
      language: 'Spanish',
      interpreters_count: 0,
      shift_details_attributes: [
        {
          time_zone: 'Eastern Time (US & Canada)',
          start_time: new Date('August 19, 1975 09:00'),
          end_time: new Date('August 19, 1975 17:00'),
          interpreters_count: 0,
        },
      ],
    },
  ],
});

export const arrayHelpers = {
  form: {
    values: {
      shift_languages_attributes: initialValues.shift_languages_attributes,
    },
  },
};

export const shiftRequests = [
  {
    id: 1,
    start_date: '2020-12-03',
    end_date: '2021-01-02',
    status: 'new_shift',
  },
  {
    id: 1,
    start_date: '2020-12-03',
    end_date: '2021-01-02',
    status: 'new_shift',
  },
];

export const tabs = [
  { text: 'Pending', key: 'pending' },
  { text: 'Completed', key: 'completed' },
];

export const shiftPlannedinterpreters = [
  { id: 1, name: 'Simon Taylor' },
  { id: 2, name: 'Simon Taylor' },
  { id: 3, name: 'Simon Taylor' },
];

export const shiftDetail = {
  end_time: '2000-01-01T12:00:00.000Z',
  id: 47,
  interpreters_count: 3,
  shift_interpreters: [
    {
      id: 1,
      interpreter: {
        id: 7,
      },
      interpreter_id: 7,
      shift_detail_id: 47,
      status: 'declined',
    },
    {
      id: 2,
      interpreter: {
        id: 7,
      },
      interpreter_id: 7,
      shift_detail_id: 47,
      status: InterpreterStatus.PENDING_INVITE,
    },
    {
      id: 3,
      interpreter: {
        id: 7,
      },
      interpreter_id: 7,
      shift_detail_id: 47,
      status: InterpreterStatus.NEW_OFFER,
    },
    {
      id: 4,
      interpreter: {
        id: 7,
      },
      interpreter_id: 7,
      shift_detail_id: 47,
      status: 'accepted',
    },
  ],
};

export const paymentInterpreters = [
  {
    source_id: 1,
    source_type: 'ShiftPlan',
    shift_interpreter_id: 2,
    interpreter_name: 'Awais Aman',
    interpreter_id: 1,
  },
  {
    source_id: 2,
    source_type: 'ShiftPlan',
    shift_interpreter_id: 3,
    interpreter_name: 'Kashif Tariq',
    interpreter_id: 2,
  },
  {
    source_id: 3,
    source_type: 'ShiftPlan',
    shift_interpreter_id: 4,
    interpreter_name: 'Asad',
    interpreter_id: 3,
  },
];
