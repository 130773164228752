export enum OnCallShiftStatus {
  NEW_SHIFT = 'general.status.newShift',
  OFFERS_SENT = 'general.status.offersSent',
  OFFERS_DECLINED = 'general.status.offersDeclined',
  OFFERS_COMPLETED = 'general.status.accepted',
  COMPLETED = 'general.status.completed',
  NEW_OFFER = 'general.status.newOffer',
  PENDING_INVITE = 'general.status.pendingInvite',
  ACCEPTED = 'general.status.accepted',
  DECLINED = 'general.status.declined',
  NOT_RESPONDED = 'general.status.notResonded',
  NOT_AVAILABLE = 'general.status.notAvailable',
  OFFERS_ACCEPTED = 'general.status.offersAccepted',
  PAID = 'general.status.paid',
}
