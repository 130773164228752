type FeatureFlags =
  | 'withReplay'
  | 'withAgnosticInhouseInterpretation'
  | 'withMultiUserOrg'
  | 'withAiPayment'
  | 'withAiNewPricingPlan';

const checkIsEnabled = (name: string): boolean => process.env[name] === 'true';

export const getFeatureFlags = (): Record<FeatureFlags, boolean> => ({
  withReplay: checkIsEnabled('REACT_APP_WITH_REPLAY'),
  withMultiUserOrg: checkIsEnabled('REACT_APP_WITH_MULTI_USER_ORG'),
  withAgnosticInhouseInterpretation: checkIsEnabled('REACT_APP_WITH_AGNOSTIC_IN_HOUSE_INTERPRETATION'),
  withAiPayment: checkIsEnabled('REACT_APP_WITH_AI_PAYMENT'),
  withAiNewPricingPlan: checkIsEnabled('REACT_APP_WITH_AI_NEW_PRICING_PLAN'),
});
