import { TFunction } from 'react-i18next';
import { add, differenceInHours, isBefore } from 'date-fns';
import { capitalize, uniq } from 'lodash-es';
import moment from 'moment-timezone';

import { EMAIL_VALIDATION_REGEX } from 'domains/client/constants';
import { AGNOSTIC_LANGUAGES } from 'domains/shared/constants/agnosticLanguages';
import { DATE_WITH_SHORT_MONTH } from 'domains/shared/constants/dateTimeFormats';
import { ZOOM_LANGUAGES } from 'domains/shared/constants/zoomLanguages';
import { createDateTime } from 'domains/shared/helpers/datetime';
import { pluralizeString } from 'domains/shared/helpers/pluralizeString';
import { BookingType } from 'domains/shared/types/bookingType';
import { Combination } from 'domains/shared/types/combination';
import { InhouseMeetingInterpreter } from 'domains/shared/types/inhouseMeetingInterpreter';
import { KUDOMeeting } from 'domains/shared/types/kudoMeeting';
import { MeetingDetails } from 'domains/shared/types/meetingDetails';
import { MeetingDetailsWithInterpreters } from 'domains/shared/types/meetingDetailsWithInterpreters';
import { MeetingProvider } from 'domains/shared/types/meetingProvider';
import { MeetingStatus } from 'domains/shared/types/meetingStatus';
import { Timezone } from 'domains/shared/types/timezone';
import { ZoomInterpreter } from 'domains/shared/types/zoomInterpreter';
import { AcceptedLanguageInterpreter } from 'shared/types/acceptedLanguageInterpreter';
import { AdditionalInterpreter } from 'shared/types/additionalInterpreter';
import { BookingDates, BookingTimes } from 'shared/types/bookingDates';
import { InterpretersByType } from 'shared/types/interpretersByType';
import { LanguageCode } from 'shared/types/languageCode';
import { ZoomInterpretation } from 'shared/types/zoomInterpretation';
import { ZoomLanguage } from 'shared/types/zoomLanguage';
import { ZoomSettings } from 'shared/types/zoomSettings';

import { getTimezoneWithUTCFallback } from './commonHelpers';

export const generateZoomOAuthLoginUrl = (): string => {
  const uri: string = process.env.REACT_APP_ZOOM_REDIRECT_URL || '';
  const clientid: string = process.env.REACT_APP_ZOOM_CLIENT_ID || '';
  const url = `${process.env.REACT_APP_ZOOM_BASE_URL}?${new URLSearchParams({
    response_type: 'code',
    redirect_uri: encodeURIComponent(uri),
    client_id: clientid,
  }).toString()}`;

  return decodeURIComponent(url);
};

export const getDateTimeWithTimezone = (datetime: string | Date, timezone: string | undefined): moment.Moment =>
  moment.utc(datetime).tz(getTimezoneWithUTCFallback(timezone));

export const prepareMeetingCreationPayloadToAddCombination = (combinations: Combination[]): ZoomSettings => {
  let isValidCombination = false;
  let settings: ZoomSettings = { language_interpretation: { enable: false } };
  const interpreters: ZoomInterpreter[] = [];

  combinations.forEach((combination) => {
    combination?.interpreters?.forEach((interpreter) => {
      if (typeof interpreter == 'object' && interpreter.booked_via === BookingType.IN_HOUSE) {
        isValidCombination = true;
        interpreters.push({
          email: interpreter.email || '',
          languages: `US,${combination.target_language}`,
        });
      }
    });
  });

  if (isValidCombination) {
    settings = {
      language_interpretation: {
        enable: true,
        interpreters,
      },
    };
  }

  return settings;
};

export const extractZoomLanguagesFromValues = (
  combinations: Combination[] | undefined | null
): (string | undefined)[] | undefined =>
  combinations
    ?.filter((combination) =>
      combination.interpreters.find((interpreter) => interpreter.booked_via === BookingType.KUDO)
    )
    ?.map((combination) => combination.target_language);

export const languageCodes = (languages: ZoomLanguage[]): LanguageCode[] =>
  languages
    .filter((language) => language.zoom_code !== 'US')
    .map((language) => ({
      label: capitalize(language.name),
      value: language.zoom_code,
      code: language.code,
    }));

type ZoomLanguagesHash = Record<string, string>;

const extractKeyFromValue = (obj: ZoomLanguagesHash, value: string): string =>
  Object.keys(obj)[Object.values(obj).indexOf(value)];

type AllInterpretersByLanguage = Record<string, InterpretersByType>;

type GetInterpretersByLanguage = (params: {
  bookingInterpreters: AcceptedLanguageInterpreter[];
  zoomLanguagesHash: ZoomLanguagesHash;
  bookingLanguages: string[];
  languages: ZoomLanguage[];
}) => AllInterpretersByLanguage;

export const getInterpretersByLanguage: GetInterpretersByLanguage = ({
  bookingInterpreters,
  zoomLanguagesHash,
  bookingLanguages,
  languages,
}) => {
  const langCodes = bookingLanguages
    .map((bookingLang) => languages.find((lang) => lang.code === bookingLang)?.zoom_code)
    .filter((lang) => lang !== 'US');
  const finalData: AllInterpretersByLanguage = langCodes.reduce(
    (obj, lang) => Object.assign(obj, { [`${lang}`]: { inhouse_interpreters: [], booking_interpreters: [] } }),
    {}
  );

  bookingInterpreters.forEach((bookingInterpreter) => {
    const [combination] = bookingInterpreter.language_combinations;
    const fromLanguageSymbol = extractKeyFromValue(zoomLanguagesHash, capitalize(combination.from_language));
    const toLanguageSymbol = extractKeyFromValue(zoomLanguagesHash, capitalize(combination.to_language));
    let selectedLanguageSymbol: string | null = null;

    if (fromLanguageSymbol !== 'US') selectedLanguageSymbol = fromLanguageSymbol;
    else selectedLanguageSymbol = toLanguageSymbol;

    if (bookingInterpreter.interpreter.in_house) {
      if (selectedLanguageSymbol in finalData) {
        finalData[selectedLanguageSymbol].inhouse_interpreters.push(bookingInterpreter.interpreter.email);
      } else {
        finalData[selectedLanguageSymbol] = {
          inhouse_interpreters: [bookingInterpreter.interpreter.email],
          booking_interpreters: [],
        };
      }
    } else if (
      selectedLanguageSymbol in finalData &&
      !finalData[selectedLanguageSymbol].inhouse_interpreters.includes(bookingInterpreter.interpreter.email)
    ) {
      finalData[selectedLanguageSymbol].booking_interpreters.push(bookingInterpreter);
    } else {
      finalData[selectedLanguageSymbol] = {
        inhouse_interpreters: [],
        booking_interpreters: [bookingInterpreter],
      };
    }
  });

  return finalData;
};

const checkIsEmailFormatValid = (email: string): boolean => EMAIL_VALIDATION_REGEX.test(email);

export interface ZoomCallPayload {
  isKudoInterpreter: boolean;
  updateOnZoom: boolean;
  settings: ZoomSettings;
  errors: string[];
  inhouseInterpreters?: InhouseMeetingInterpreter[];
}

type PrepareZoomCallPayload = (params: {
  combinations: Combination[];
  meetingProvider: MeetingProvider;
  meeting: MeetingDetails;
  additionalInterpreters: AdditionalInterpreter[];
  deletedInHouseLanguages: string[];
  t: TFunction;
}) => ZoomCallPayload;

export const prepareZoomCallPayload: PrepareZoomCallPayload = ({
  combinations,
  meeting,
  additionalInterpreters,
  deletedInHouseLanguages,
  meetingProvider,
  t,
}) => {
  const meetingStartTime = new Date(meeting.start_time);
  const meetingEndTime = add(new Date(meeting.start_time), {
    minutes: meeting.duration,
  });
  const errors = [];

  if (
    isBefore(
      meetingStartTime,
      add(new Date(), {
        hours: 2,
      })
    )
  ) {
    errors.push(t('text.MeetingStartTimeMustBe2HoursAfterCurrentTime'));
  } else if (differenceInHours(new Date(meetingEndTime), new Date(meetingStartTime)) > 8) {
    errors.push(t('text.MeetingDurationMustBeWithin8Hours'));
  }

  let isKudoInterpreter = false;
  const { settings: { language_interpretation: { interpreters = [] } = {} } = {} } = meeting;
  const inhouseInterpreters: InhouseMeetingInterpreter[] = [];
  let remainingInterpreters = interpreters;

  if (deletedInHouseLanguages) {
    remainingInterpreters = interpreters.filter(
      (interpreter) => !deletedInHouseLanguages.includes(interpreter.languages.split(',')[1])
    );
  }

  additionalInterpreters = additionalInterpreters.filter((item) => !deletedInHouseLanguages.includes(item.language));

  const newlyAddedInterpreters: { languages: string; email: string }[] = [];

  additionalInterpreters.forEach((item) => {
    item.emails.forEach((email) => {
      if (checkIsEmailFormatValid(email)) {
        newlyAddedInterpreters.push({ email, languages: `US,${item.language}` });

        inhouseInterpreters.push({
          email,
          languages: [
            'ENG',
            getLanguageCode(
              meetingProvider === MeetingProvider.NON_INTEGRATED ? AGNOSTIC_LANGUAGES : ZOOM_LANGUAGES,
              item.language
            ),
          ],
        });
      } else {
        errors.push(`${email} ${t('client.inHouseEmail.invalid')}`);
      }
    });
  });

  const languageInterpreters: ZoomInterpreter[] = [];

  combinations.forEach((combination) => {
    combination.interpreters.forEach((interpreter) => {
      if (interpreter.booked_via === BookingType.KUDO) {
        isKudoInterpreter = true;
      } else if (checkIsEmailFormatValid(interpreter.email)) {
        if (meetingProvider === MeetingProvider.NON_INTEGRATED) {
          inhouseInterpreters.push({
            email: interpreter.email || '',
            languages: ['ENG', getLanguageCode(AGNOSTIC_LANGUAGES, combination.target_language)],
          });
        } else {
          inhouseInterpreters.push({
            email: interpreter.email || '',
            languages: ['ENG', getLanguageCode(ZOOM_LANGUAGES, combination.target_language)],
          });
          languageInterpreters.push({
            email: interpreter.email || '',
            languages: `US,${combination.target_language && combination.target_language.split(',')[0]}`,
          });
        }
      } else {
        errors.push(`${interpreter.email} ${t('client.inHouseEmail.invalid')}`);
      }
    });
  });
  const finalInterpreter = [...remainingInterpreters, ...languageInterpreters, ...newlyAddedInterpreters];

  return {
    isKudoInterpreter,
    updateOnZoom:
      [...languageInterpreters, ...newlyAddedInterpreters, ...deletedInHouseLanguages, ...inhouseInterpreters].length >
        0 && meetingProvider !== MeetingProvider.NON_INTEGRATED,
    settings: {
      language_interpretation: { enable: finalInterpreter.length > 0, interpreters: finalInterpreter },
    },
    inhouseInterpreters,
    errors,
  };
};

export const meetingInterpreterOptions = (): { label: string; value: string }[] => [
  { label: BookingType.KUDO, value: BookingType.KUDO },
  { label: BookingType.IN_HOUSE, value: BookingType.IN_HOUSE },
];

/**
 * returns formatted hours and minutes
 * @param  Integer mins [90]
 * @return string 'xh xm' i.e
 * input: 90, output: 1h 30m
 * input: 20, output: 20m
 * input: 120, output: 2h
 */
export const convertMinutesToHourMins = (mins: number, durationFormat = 'shortFormat'): string => {
  const hours = mins / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);
  const hDisplay =
    rHours > 0 ? `${durationFormat === 'shortFormat' ? `${rHours}h` : `${pluralizeString(rHours, 'hour')}`}` : '';
  const mDisplay = rMinutes > 0 ? `${rMinutes}${durationFormat === 'shortFormat' ? 'm' : ' min'}` : '';

  return `${hDisplay} ${mDisplay}`.trim();
};

export const convertDecimalTimeToHourMin = (decimalTime: number): string => {
  let hours = Math.floor(decimalTime);
  let minutes = Math.round((decimalTime - hours) * 60);

  if (minutes >= 60) {
    const additionalHours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    hours += additionalHours;
    minutes = remainingMinutes;
  }

  const rHours = hours > 0 ? `${pluralizeString(hours, 'hour')}` : '';
  const rMinutes = minutes > 0 ? `${minutes} min` : '';
  const rTime = hours === 0 && minutes === 0 ? '0 hour' : '';

  return `${rHours} ${rMinutes}${rTime}`.trim();
};

export const filterTargetLanguageOptions = (
  options: LanguageCode[],
  toRemove: string[] = [],
  prop: keyof LanguageCode = 'value'
): LanguageCode[] => options.filter((el: LanguageCode) => !toRemove.includes(el[prop]));

export const filterBookedInhouse = (
  existingInterpreters: ZoomInterpreter[],
  zoomLanguages: ZoomLanguage[]
): string[] => {
  const bookedInhouseLanguages: string[] = [];

  existingInterpreters?.forEach((combination) => {
    const zoomCode = combination.languages.split(',')[1];
    const zoomLanguage = zoomLanguages.find((l) => l.zoom_code === zoomCode);

    if (zoomLanguage) {
      bookedInhouseLanguages.push(zoomLanguage.code);
    } else if (zoomCode.startsWith('D-') || zoomCode.startsWith('C-')) {
      bookedInhouseLanguages.push(zoomCode);
    }
  });

  return bookedInhouseLanguages;
};
export const targetLanguagesCodes = (zoomCodes: string[], zoomLanguages: ZoomLanguage[]): string[] => {
  const targetLanguages = zoomCodes.map((code: string) => {
    const zoomLang = zoomLanguages.find((lang) => lang.zoom_code === code);

    return zoomLang ? zoomLang.code : '';
  });

  return targetLanguages.concat('ENG').filter((item) => item);
};

export const bookedViaString = (
  inHouseInterpreters: InterpretersByType['inhouse_interpreters'],
  kudoInterpreters: InterpretersByType['booking_interpreters']
): string => {
  const arr = [];

  if (!inHouseInterpreters?.length && !kudoInterpreters?.length) {
    return BookingType.KUDO;
  }

  if (inHouseInterpreters?.length) arr.push(BookingType.IN_HOUSE);

  if (kudoInterpreters?.length) arr.push(BookingType.KUDO);

  return arr.join('/');
};

export const formatKmpLanguages = (
  values: ZoomInterpretation | null,
  zoomLanguages: ZoomLanguage[],
  langs: string[] = ['ENG']
): string[] => {
  extractZoomLanguagesFromValues((values as ZoomInterpretation).combinations)?.forEach((combination) => {
    const zoomCode = (combination as string).split(',')[0];
    const { code } = zoomLanguages.find((l) => l.zoom_code === zoomCode) || {};

    if (code) {
      langs.push(code);
    }
  });

  return langs;
};

interface BookingParams {
  meeting_title: string;
  meeting_description: string;
  meeting_id?: number;
  status: string;
  client_id?: string | null;
  user_id: number;
  meeting_urls: { join_url?: string };
  starts_at: string;
  ends_at: string;
  pin: string;
  meeting_actual_start_time: string;
  meeting_actual_end_time: string;
  time_zone_code?: string;
  subject_matter_id?: string;
  languages: string[];
  should_search?: boolean;
}

interface BookingParamsWithProvider extends BookingParams {
  meeting_provider: MeetingProvider;
}
interface BookableParams {
  start_time: string;
  end_time: string;
  time_zone: string;
  meeting_title: string;
  languages: string[];
}
export const bookableParams = (meeting: MeetingDetails, languages: string[]): BookableParams => ({
  start_time: meeting.start_time,
  end_time: moment.utc(meeting.start_time).add(meeting.duration, 'minute').format('YYYY-MM-DDTHH:mm:ssZ'),
  time_zone: meeting.timezone as string,
  meeting_title: meeting.topic as string,
  languages,
});

function getIanaCode(timeZone?: string): string | undefined {
  return timeZone?.split('|')[1];
}

function getZoomCode(timeZone?: string): string | undefined {
  return timeZone?.split('|')[2];
}

interface TimezonesPayload {
  payload: Timezone[];
}

interface CreateOnUpdateParams {
  data: MeetingDetails;
  languages: string[];
  userId: number;
  timezones: TimezonesPayload;
}

export const createUpdatedZoomEntityParams = ({
  data,
  languages,
  userId,
  timezones,
}: CreateOnUpdateParams): BookingParams => {
  const startTime = getDateTimeWithTimezone(data.start_time as string, data.timezone as string);
  const endTime = moment(startTime).add(data.duration, 'minutes');
  const timeZone = getZoomTimezones(timezones?.payload)
    .filter((tz) => getZoomCode(tz.value) === data.timezone)
    .shift();
  const ianaCode = getIanaCode(timeZone?.value);
  const zoomCode = getZoomCode(timeZone?.value);
  const bookingTimeZone =
    ianaCode === 'undefined' || ianaCode === 'null' || ianaCode?.length === 0 ? zoomCode : ianaCode;

  return {
    meeting_title: data.topic as string,
    meeting_description: data.agenda as string,
    meeting_id: data.id,
    status: 'new_request',
    user_id: userId,
    client_id: null,
    meeting_urls: { join_url: data.join_url },
    starts_at: startTime.format(),
    ends_at: endTime.format(),
    pin: data.password || '',
    meeting_actual_start_time: startTime.format(),
    meeting_actual_end_time: endTime.format(),
    time_zone_code: bookingTimeZone,
    languages: uniq(languages),
    should_search: true, // should_search is temporary till KMP-1438 is merged
  };
};

export const createUpdatedZoomMeetingBookingParams = ({
  data,
  languages,
  userId,
  timezones,
}: CreateOnUpdateParams): BookingParamsWithProvider => ({
  ...createUpdatedZoomEntityParams({ data, languages, userId, timezones }),
  meeting_provider: MeetingProvider.ZOOM_MEETING,
});

export const createUpdatedZoomWebinarBookingParams = ({
  data,
  languages,
  userId,
  timezones,
}: CreateOnUpdateParams): BookingParamsWithProvider => ({
  ...createUpdatedZoomEntityParams({ data, languages, userId, timezones }),
  meeting_provider: MeetingProvider.ZOOM_WEBINAR,
});

export const getDefaultBookingValues = (): MeetingDetailsWithInterpreters => {
  const startDateTime = add(new Date(), {
    days: 1,
  });
  const endDateTime = add(new Date(), {
    days: 1,
    hours: 1,
  });

  return {
    meeting_start_date: startDateTime.toString(),
    meeting_start_time: startDateTime.toString(),
    meeting_end_date: endDateTime.toString(),
    meeting_end_time: endDateTime.toString(),
    interpretation_start_date: startDateTime.toString(),
    interpretation_start_time: startDateTime.toString(),
    interpretation_end_date: endDateTime.toString(),
    interpretation_end_time: endDateTime.toString(),
    combinations: [
      {
        interpreters: [
          {
            email: '',
            id: 0,
            kudo_certified: true,
            kudo_experience: null,
            kudo_pro: true,
          },
        ],
      },
    ],
    isSubmitting: false,
    meeting_description: '',
    meeting_details: '',
    timezone: '',
    subjectMatter: '',
    additionalInhouseInterpreters: [],
    pin: '',
  };
};

const getLanguageCode = (languages: ZoomLanguage[], targetLanguage?: string, isZoomCode = false): string => {
  const { code, zoom_code: zoomCode } = languages.find((language) => language.zoom_code === targetLanguage) || {};

  if (isZoomCode && zoomCode) {
    return zoomCode;
  }

  if (code) {
    return code;
  }

  return '';
};

export const getLanguagesForAdditionalCost = (
  languageCombinations: Combination[],
  zoomLanguages: ZoomLanguage[]
): string[] => {
  const selectedLanguages: string[] = [];

  languageCombinations.forEach(
    (languageCombination) =>
      languageCombination.interpreters.find((interpreter) => interpreter.booked_via === 'KUDO') &&
      selectedLanguages.push(getLanguageCode(zoomLanguages, languageCombination?.target_language))
  );

  return selectedLanguages;
};

export const getZoomTimezones = (timezones: TimezonesPayload['payload']): { name: string; value: string }[] =>
  Object.entries(
    Array.isArray(timezones)
      ? timezones
          .filter((tz) => tz.zoom_code !== null)
          .sort((a, b) => parseFloat(a.offset.replace(':', '.')) - parseFloat(b.offset.replace(':', '.')))
      : timezones
  ).map(([_, value]) => ({
    name: `${value.name} (UTC ${value.offset})`,
    value: `${value.name}|${value.iana_code}|${value.zoom_code}`,
  }));

export const convertToUtcForZoom = (datetime: string, timezone: string): string =>
  moment.tz(datetime, 'YYYY-MM-DDTHH:mm:ss', timezone).utc().format();

export const timeDifferenceInHours = (start: string, end: string): number =>
  moment(end).diff(moment(start), 'hours', true);

export const calculateTimeDifferenceInMinutes = (start: string, end: string): number =>
  moment(end).diff(moment(start), 'minutes', true);

export const calculateInterpretersPerLanguage = (start: string, end: string): number =>
  timeDifferenceInHours(start, end) > 1 ? 2 : 1;

const calculateMeetingDate = (time: string): string => moment(time).format(DATE_WITH_SHORT_MONTH);

type GroupedMeetings = Record<string, KUDOMeeting[]>;

export const groupMeetings = (meetings: KUDOMeeting[], tab: MeetingStatus): GroupedMeetings =>
  meetings.reduce((groupedMeetings: GroupedMeetings, meeting) => {
    const meetingDate = calculateMeetingDate(
      tab === MeetingStatus.CANCELLED ? (meeting.canceled_at as string) : meeting.start_time
    );

    if (meetingDate in groupedMeetings) {
      groupedMeetings[meetingDate].push(meeting);
    } else {
      groupedMeetings[meetingDate] = [meeting];
    }

    return groupedMeetings;
  }, {});

type IsMeetingDurationLongerThanOneHour = (params: {
  endTime: string;
  startTime: string;
  endDate?: string;
  startDate?: string;
}) => boolean;

export const isMeetingDurationLongerThanOneHour: IsMeetingDurationLongerThanOneHour = ({
  endTime,
  startTime,
  endDate,
  startDate,
}): boolean => {
  if (endDate && startDate) {
    const endDateTime = createDateTime(endDate, endTime);
    const startDateTime = createDateTime(startDate, startTime);

    return differenceInHours(new Date(endDateTime), new Date(startDateTime)) > 1;
  }

  return false;
};

export const getMeetingDateFields = (values: MeetingDetailsWithInterpreters): BookingDates => ({
  meeting_start_date: values.meeting_start_date,
  meeting_end_date: values.meeting_end_date,
  interpretation_start_date: values.interpretation_start_date,
  interpretation_end_date: values.interpretation_end_date,
});

export const getMeetingTimeFields = (values: MeetingDetailsWithInterpreters): BookingTimes => ({
  meeting_start_time: values.meeting_start_time,
  meeting_end_time: values.meeting_end_time,
  interpretation_start_time: values.interpretation_start_time,
  interpretation_end_time: values.interpretation_end_time,
});
