import { format as dateFnsFormat } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { capitalize } from 'lodash-es';

import {
  FNS_DAY_3_LETTER_MONTH_YEAR,
  FNS_FULL_DAY_3_LETTER_MONTH_YEAR,
  FNS_TIME_12H,
  TIME_24H,
} from 'domains/shared/constants/dateTimeFormats';
import { DEFAULT_TIMEZONE, TIMEZONES } from 'domains/shared/constants/Timezones';
import { Issue } from 'shared/types/issue';
import { IssuesItem } from 'shared/types/issuesItem';

type DateType = Date | string;

export const formatDate = (datetime: DateType, timezone: string | null = null): string => {
  const date = new Date(datetime);

  return timezone
    ? formatInTimeZone(date, timezone, FNS_DAY_3_LETTER_MONTH_YEAR)
    : dateFnsFormat(date, FNS_DAY_3_LETTER_MONTH_YEAR);
};
export const formatDayAndMonth = (date: DateType): string => dateFnsFormat(new Date(date), 'dd MMM');
export const formatDateAndTime = (date: DateType): string => dateFnsFormat(new Date(date), 'dd MMM yyyy HH:mm');

export const formatTime = (datetime: DateType, is24Hours = false, timezone: string | null = null): string => {
  const date = new Date(datetime);

  if (is24Hours) {
    return timezone ? formatInTimeZone(date, timezone, TIME_24H) : dateFnsFormat(date, TIME_24H);
  }

  return timezone ? formatInTimeZone(date, timezone, FNS_TIME_12H) : dateFnsFormat(date, FNS_TIME_12H);
};

export const formatShiftPlanDateTime = (date: DateType): string => dateFnsFormat(new Date(date), 'dd MMM HH:mm');

export const formatDayAndDate = (date: DateType, timezone: string | null = null): string =>
  timezone
    ? formatInTimeZone(new Date(date), timezone, FNS_FULL_DAY_3_LETTER_MONTH_YEAR)
    : dateFnsFormat(new Date(date), FNS_FULL_DAY_3_LETTER_MONTH_YEAR);

export const formatShiftInterpreterTime = (date: DateType): string => formatInTimeZone(new Date(date), 'UTC', 'h:mmb');

export const formatRangeDate = (date: DateType): string => dateFnsFormat(new Date(date), 'EEE, dd MMM yyyy');

type FilterIssueByCategory = <T extends Issue | IssuesItem>(list: T[], category: string) => T[];

export const filterIssueByCategory: FilterIssueByCategory = (list, category) => {
  const issues = list.filter((item) => item.category === category);

  return issues;
};

export const booleanToText = (isTrue: boolean): string => (isTrue ? 'Yes' : 'No');

export const formatCurrency = (num: string): string => new Intl.NumberFormat().format(parseInt(num, 10));

export const calculateActualEndTimeOfMeeting = (endDate: string, timeZone: string): number => {
  const mappedTimeZone = getIANATimezoneWithUTCFallback(timeZone);

  return utcToZonedTime(endDate, mappedTimeZone).getTime();
};

export const timezoneWithOffset = (timezone: string): string => {
  const fnsTimezone = formatInTimeZone(new Date(), getIANATimezoneWithUTCFallback(timezone), 'XXXXX');

  return `(UTC ${fnsTimezone === 'Z' ? '+00:00' : fnsTimezone})`;
};

export const getTimezoneWithUTCFallback = (timezone?: string): string => timezone || DEFAULT_TIMEZONE.iana_code;

export const getIANATimezoneWithUTCFallback = (timezone: string): string => {
  const timezoneData = TIMEZONES.find((t) => t.iana_code === timezone);

  return getTimezoneWithUTCFallback(timezoneData?.iana_code);
};

const TIME_12H_FORMAT_REGEX = /((1[0-2]|0?[1-9]):([0-5]\d) ?([AaPp][Mm]))/;
const TIME_24H_FORMAT_REGEX = /^([0-1]?\d|2[0-3]):[0-5]\d$/;

export const isValidTimeString = (time: string): boolean =>
  !!(time.match(TIME_12H_FORMAT_REGEX) || time.match(TIME_24H_FORMAT_REGEX));

export const humanizeText = (text: string): string =>
  text
    .split('_')
    .map((str) => capitalize(str))
    .join(' ');

export const generateRandomString = (length = 32): string => {
  const arr = new Uint8Array(length / 2);

  window.crypto.getRandomValues(arr);

  return Array.from(arr, (dec) => dec.toString(16).padStart(2, '0')).join('');
};

export const generateOAuthLoginUrl = (): string => {
  const nonce = generateRandomString();
  const state = generateRandomString();
  const params = {
    client_id: 'authorization_code_consumer',
    max_age: '0',
    nonce,
    prompt: '',
    redirect_uri: `${process.env.REACT_APP_AUTH_SERVER_LOGIN}/callback`,
    response_type: 'code',
    scope: 'openid+offline',
    state,
  };
  const url = `${process.env.REACT_APP_AUTH_SERVER}/oauth2/auth?${new URLSearchParams(params).toString()}`;

  return decodeURIComponent(url);
};
