import { UserRole } from '../domains/shared/constants/UserRole';

export const GET_SIGNUP = 'GET_SIGNUP';
export const GET_SIGNUP_SUCCESS = 'GET_SIGNUP_SUCCESS';
export const GET_SIGNUP_ERROR = 'GET_SIGNUP_ERROR';

export const GET_SIGNIN = 'GET_SIGNIN';
export const GET_SIGNIN_SUCCESS = 'GET_SIGNIN_SUCCESS';
export const GET_SIGNIN_ERROR = 'GET_SIGNIN_ERROR';

export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const TOGGLE_SIGN_DOCUMENT_MODAL = 'TOGGLE_SIGN_DOCUMENT_MODAL';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const VALID_ROLES = [UserRole.INTERPRETER, UserRole.REVIEWER, UserRole.ADMIN, UserRole.ACCOUNT, UserRole.CLIENT];
