import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { parseResponseErrors } from 'helpers/notificationHelper';
import { showNotification } from 'helpers/showNotification';
import { getAiLanguages } from 'services/aiService';

import { AiBookingSliceState } from '../types/aiBookingSliceState';

export const initialState: AiBookingSliceState = {
  isLoading: false,
  languages: { source_languages: [], target_languages: [] },
};

export const AiBookingSlice = createSlice({
  name: 'aibooking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLanguages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLanguages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.languages = action.payload ? action.payload : { sourceLanguages: [], targetLanguages: [] };
    });
    builder.addCase(fetchLanguages.rejected, (state) => {
      state.isLoading = false;
      state.languages = { source_languages: [], target_languages: [] };
    });
  },
});

export const fetchLanguages = createAsyncThunk('aibooking/fetchlanguages', async () => {
  try {
    const response = await getAiLanguages();

    return response.data;
  } catch (e) {
    showNotification({ message: parseResponseErrors(e) });

    return Promise.reject();
  }
});

export const { reducer: aiBookingReducer } = AiBookingSlice;
